const idInfoBody = {
    "idType": "",
    "stateId": "",
    "expDate": ""
}
const contactInfoBody = {
    "phone": "",
    "phoneType": ""
}
const residenceInfoBody = {
    "residenceMortgage": "",
    "residenceType": "",
    "residenceYearsOfResidence": "",
    "residenceMonthsOfResidence": ""
}
const employmentInfoBody = {
    "employmentEmployer": "",
    "employmentPosition": "",
    "employmentType": "",
    "employmentYears": "",
    "employmentMonths": "",
    "employmentIncome": ""
}

export { idInfoBody, contactInfoBody, residenceInfoBody, employmentInfoBody };