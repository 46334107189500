import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        background: theme.palette.background.paper,
        width: '100%'
    },
    tab: {
        flexGrow: 1
    }
}));

export default function ApplicantsTabs({ applicant, coApplicant, setActiveTab, installationPath }) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        setActiveTab(newValue);
    };

    return (
        <div className={classes.root}>
            <AppBar position="static" elevation={0}>
                <Tabs variant="fullWidth" value={value} onChange={handleChange} aria-label="simple tabs example" >
                    <Tab wrapped={true} className={classes.tab} label={installationPath ? "Installation Details" : "Applicant"} {...a11yProps(0)} onClick={() => setActiveTab(1)} />
                    {!installationPath && coApplicant &&
                        <Tab wrapped={true} className={classes.tab} label="Co-Applicant" {...a11yProps(1)} onClick={() => setActiveTab(2)} />
                    }
                    <Tab wrapped={true} className={classes.tab} label="History" {...a11yProps(2)} onClick={() => setActiveTab(3)} />
                    {installationPath && applicant.installation_status_id === 7 &&
                        <Tab wrapped={true} className={classes.tab} label="Report" {...a11yProps(3)} onClick={() => setActiveTab(4)} />
                    }

                </Tabs>
            </AppBar>
        </div>
    );
}
