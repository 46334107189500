import * as React from "react";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import PhotoAlbum from "react-photo-album";

export default function ImageGallery({ title, slides }) {
    const [index, setIndex] = React.useState(-1);
    return (
        <div style={{ margin: '16px' }}>
            <h4>{title}</h4>
            <Lightbox
                index={index}
                slides={slides}
                open={index >= 0}
                close={() => setIndex(-1)}
            />
            <PhotoAlbum index={index} layout="masonry" photos={slides} onClick={(e) => setIndex(e.index)} />
        </div>
    );
}