import React, { useCallback, useContext, useEffect, useState } from 'react';
import Axios from 'axios';
import Cookies from 'universal-cookie';
import { LoadingSkeleton } from '../assets/skeletons';
import { useMediaQuery } from 'react-responsive';
import { spanish } from '../languages/es';
import { english } from '../languages/en';
import ApplicationsList from './applicationsList';
import Header from '../header/header';
import BottomMenu from '../footer/bottomMenu';
import { NotFoundGeneral } from '../assets/notFound';
import DatePicker from '../assets/datePicker';
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { OptionsContext } from '../../App';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import HeaderTitle from '../assets/headerTitle';
import ApplicationsFilters from './applicationsFilters';
import DateRangeHandler from '../assets/dateRangeHandler';
import mysqlToJSDate from '../assets/mysqlToJSDate';
import TuneIcon from '@material-ui/icons/Tune';
import IconButton from '@material-ui/core/IconButton';
import { Button } from '@material-ui/core';
import jsToMysqlDate from '../assets/jsToMysqlDate';

const date = new Date();

const convertDate = (date) => {
    let df = date.replace(/[-]/g, '/');
    df = Date.parse(df);
    return new Date(df);
}

const Applications = (props) => {

    //Main States
    const [data, setData] = useState(false);
    const [error, setError] = useState({ code: false, message: "" });
    const [dateFrom, setDateFrom] = useState(false);
    const [dateTo, setDateTo] = useState(false);
    const [pickerDateFrom, setPickerDateFrom] = useState(false);
    const [pickerDateTo, setPickerDateTo] = useState(false);
    const [options, setOptions] = useContext(OptionsContext);
    const [agencyApproved, setAgencyApproved] = useState(options.showAgencyApproved);
    const [showDateRange, setShowDateRange] = useState(false);
    const [showApplicationsFilters, setShowApplicationsFilters] = useState(false);


    const language = english;

    //Secondary states
    const [skeleton, setSkeleton] = useState(true);

    //Config and options
    const history = useHistory();
    const cookie = new Cookies();
    const userLevel = cookie.get('level');
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' });
    const isMobile = useMediaQuery({ query: '(max-width: 480px)' });

    const handleSetFromDate = (date) => {
        setDateFrom(date);
        setPickerDateFrom(date);
    }
    const handleSetToDate = (date) => {
        setDateTo(date);
        setPickerDateTo(date);
    }

    const handleAgencyApproved = (event) => {
        setAgencyApproved(event.target.checked);
        setOptions({ ...options, 'showAgencyApproved': event.target.checked });
    }

    const DateFiltered = useCallback((from, to) => {
        setData(false);
        setSkeleton(true);
        setError({ code: false, message: "" });
        Axios.get(
            `${props.host}/applications/dateFiltered?date_from=${from}&date_to=${to}${agencyApproved ? '&show_agency_approved=true' : ''}`,
            { headers: { Authorization: `Bearer ${props.token}` } })
            .then(res => {
                setData(res.data);

            })
            .catch((error) => {
                error && setError({ ...error, code: error.response.status, message: error.response.data });
            })
    }, [agencyApproved]);

    const FetchData = useCallback(() => {
        if (!dateFrom && !dateTo && props.match === undefined) {
            const initialDay = new Date(date.getFullYear(), date.getMonth(), 1);
            const today = new Date();
            const from = initialDay.getFullYear() + "-" + (initialDay.getMonth() + 1) + '-' + initialDay.getDate();
            const to = today.getFullYear() + "-" + (today.getMonth() + 1) + '-' + today.getDate();
            setPickerDateFrom(convertDate(from));
            setPickerDateTo(convertDate(to));
            DateFiltered(from, to);
            return;
        }
        if (dateFrom && dateTo) {
            console.log(jsToMysqlDate(dateFrom, { showTime: false }));
            const from = jsToMysqlDate(dateFrom, { showTime: false });
            const to = jsToMysqlDate(dateTo, { showTime: false });
            history.push(`/applications/filter_by_date/from/${from}/to/${to}`);
        }
    }, [dateFrom, dateTo, agencyApproved])

    useEffect(() => {
        FetchData();
    }, [dateFrom, dateTo, agencyApproved]);

    useEffect(() => {
        if (props.match !== undefined) {
            const from = props.match.params.from_date;
            const to = props.match.params.to_date;
            DateFiltered(from, to);
            setPickerDateFrom(convertDate(from));
            setPickerDateTo(convertDate(to));
        }
    }, [props.match, DateFiltered]);


    useEffect(() => {
        !data && setSkeleton(true);
        data && setSkeleton(false);
    }, [data]);

    useEffect(() => {
        error.code && setSkeleton(false);
    }, [error]);


    return (
        <>

            {/* {isMobile &&
                <Header />
            } */}

            {/* {data && isMobile &&
                <MobileAppList applications={data} />
            } */}
            <HeaderTitle title="Applications" />

            <div style={{ display: 'flex', marginBottom: '10px' }}>
                <Button size='small'>
                    <DateRangeHandler text={props.match ? `From ${mysqlToJSDate(props.match.params.from_date, { showTime: false })} To ${mysqlToJSDate(props.match.params.to_date, { showTime: false })}` : 'Current Month'} showDateRange={showDateRange} setShowDateRange={setShowDateRange} />
                </Button>
                <Button size='small' onClick={() => setShowApplicationsFilters(!showApplicationsFilters)}>
                    <TuneIcon style={{ padding: '12px', color: '#6c6c6c' }} />
                    <p>Special options</p>
                </Button>

            </div>

            <ApplicationsFilters
                agencyApproved={agencyApproved}
                handleAgencyApproved={handleAgencyApproved}
                showApplicationsFilters={showApplicationsFilters}
                setShowApplicationsFilters={setShowApplicationsFilters}
            />

            {showDateRange &&
                <div style={{ display: 'flex', marginBottom: '1rem' }}>
                    <DatePicker style={{ margin: '0 10px', flexBasis: 'fit-content' }} label="Date From" setDate={(date) => handleSetFromDate(date)} date={pickerDateFrom} />
                    <DatePicker style={{ margin: '0 10px', flexBasis: 'fit-content' }} label="Date To" setDate={(date) => handleSetToDate(date)} date={pickerDateTo} />
                </div>
            }
            {/* <div style={{ margin: '1rem' }}>
                <FormControlLabel
                    control={
                        <Switch
                            checked={agencyApproved}
                            onChange={handleAgencyApproved}
                            name="checkedB"
                            color="primary"
                        />
                    }
                    label="Show Agency Approved"
                />
            </div> */}

            {/* {pickerDateFrom && pickerDateTo &&
                <div style={{ display: 'flex' }}>
                    <DatePicker style={{ margin: '0 10px', flexBasis: 'fit-content' }} label="Date From" setDate={(date) => handleSetFromDate(date)} date={pickerDateFrom} />
                    <DatePicker style={{ margin: '0 10px', flexBasis: 'fit-content' }} label="Date To" setDate={(date) => handleSetToDate(date)} date={pickerDateTo} />
                </div>
            } */}


            {!skeleton && data &&
                <ApplicationsList data={data} host={props.host} token={props.token} />
            }
            {skeleton &&
                <LoadingSkeleton />
            }
            {error.code && <NotFoundGeneral message={error.message} />}
            <BottomMenu />



        </>
    )
}

export default Applications
