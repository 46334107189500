import React, { useEffect, useState } from "react";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { useMediaQuery } from "react-responsive";

const iconStyle = {
    fontSize: '4rem'
}

const NotFoundGeneral = ({ message, height }) => {
    const isMobile = useMediaQuery({ maxWidth: 768 });


    const wrapperStyleMobile = {
        display: 'flex',
        height: height !== undefined ? `calc(100vh - ${height}px)` : 'calc(100vh - 56px)',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center'
    }

    const wrapperStyleDesktop = {
        display: 'flex',
        height: height !== undefined ? `calc(100vh - ${height}px)` : 'calc(100vh - 78px)',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '-56px'
    }


    return (
        <div style={isMobile ? wrapperStyleMobile : wrapperStyleDesktop} >
            <div style={{ textAlign: 'center', padding: '1rem' }}>
                <ErrorOutlineIcon style={iconStyle} />
                <h3>{message}</h3>
            </div>
        </div>
    )
}

export { NotFoundGeneral }