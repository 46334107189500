import React, { useState, useEffect } from "react";
import { Grid, Paper, FormControl, InputLabel, Select, MenuItem, TextField, Input, InputAdornment } from "@material-ui/core";
import { useStyles } from "../assets";
import { validateField } from "../errorHandling/fieldValidations";
import processFieldErrorMessage from "../errorHandling/processFieldErrorMessage";
import { residenceInfoBody } from "./formsBody";

const ResidenceInfo = ({ formOptions, handleForm, formError, setFormError }) => {
    //Main States
    const [residenceInformation, setResidenceInformation] = useState(residenceInfoBody);
    const [residenceType, setResidenceType] = useState('');

    //Handlers
    const handleResidenceInformation = (e) => {
        const thisField = validateField(e);
        const errorData = processFieldErrorMessage(e, thisField, formError);
        setFormError(errorData);
        setResidenceInformation({ ...residenceInformation, [e.target.name]: e.target.value });
        if (e.target.name === 'residenceType') setResidenceType(e.target.value);
    }

    //Custom Hooks
    const classes = useStyles();

    //Return data for form
    useEffect(() => {
        handleForm('residenceInfo', residenceInformation);
    }, [residenceInformation]);

    useEffect(() => {
        if (residenceType === '2') setResidenceInformation({ ...residenceInformation, residenceMortgage: '0' })
        else setResidenceInformation({ ...residenceInformation, residenceMortgage: '' })
    }, [residenceType]);

    return (
        <>
            <Paper className="pt-3 pb-3 pl-3 pr-3 mb-3">
                <form>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={4} md={3}>
                            <FormControl fullWidth={true} className={classes.formControl}>
                                <InputLabel id="demo-simple-select-label">Residence Type</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={residenceInformation.residenceType}
                                    onChange={handleResidenceInformation}
                                    name="residenceType"
                                    error={formError.residenceType.error}
                                >
                                    {
                                        formOptions.config_residence_types.map((type) => (
                                            <MenuItem key={type.residence_type_name} value={type.residence_type_id}>{type.residence_type_name}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4} md={3}>
                            <FormControl fullWidth={true} className={classes.formControl}>
                                <InputLabel htmlFor="standard-adornment-amount">Mortgage</InputLabel>
                                <Input
                                    id="standard-adornment-amount"
                                    value={residenceInformation.residenceMortgage}
                                    onChange={handleResidenceInformation}
                                    type="number"
                                    onWheel={(e) => e.target.blur()}
                                    name="residenceMortgage"
                                    error={formError.residenceMortgage.error}
                                    inputProps={{ type: 'number' }}
                                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4} md={3}><TextField onWheel={(e) => e.target.blur()} fullWidth={true} error={formError.residenceYearsOfResidence.error} helperText={formError.residenceYearsOfResidence.message} inputProps={{ type: 'number' }} value={residenceInformation.yearsOfResidence} name="residenceYearsOfResidence" onChange={handleResidenceInformation} id="residenceYears" label="Years in Residence" /></Grid>
                        <Grid item xs={12} sm={4} md={3}><TextField onWheel={(e) => e.target.blur()} fullWidth={true} error={formError.residenceMonthsOfResidence.error} helperText={formError.residenceMonthsOfResidence.message} inputProps={{ type: 'number' }} value={residenceInformation.monthsOfResidence} name="residenceMonthsOfResidence" onChange={handleResidenceInformation} id="residenceMonths" label="Months in Residence" /></Grid>
                    </Grid>
                </form>
            </Paper>
        </>
    )
}
export default ResidenceInfo;