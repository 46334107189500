import UAParser from 'ua-parser-js';

export default function DeviceDetection() {
    const parser = new UAParser(navigator.userAgent);
    const deviceModel = parser.getDevice().model;
    const deviceOs = parser.getOS().name;
    const deviceOsVersion = parser.getOS().version;
    const deviceCpu = parser.getCPU().architecture;
    const deviceScreenWidth = window.screen.width;
    const deviceScreenHeight = window.screen.height;
    const deviceTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let device;
    let deviceType = parser.getDevice().type;
    const isDesktop = deviceType === undefined || !['wearable', 'mobile'].includes(deviceType);
    if (isDesktop) {
        deviceType = 'Desktop';
    }
    device = {
        model: deviceModel,
        type: deviceType,
        os: deviceOs,
        osVersion: deviceOsVersion,
        cpu: deviceCpu,
        width: deviceScreenWidth,
        height: deviceScreenHeight,
        timeZone: deviceTimeZone
    }
    return device;
}