import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { TextField, InputAdornment, Grid, Button, List, ListItem, IconButton } from '@material-ui/core';
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import AttachMoneyOutlinedIcon from '@material-ui/icons/AttachMoneyOutlined';
import DeleteIcon from '@material-ui/icons/Delete';
const useStyles = makeStyles((theme) => ({
    insideSubTitle: {
        margin: '0 16px 0.5rem 16px'
    },
    total: {
        margin: '16px'
    },
    addProductButton: {
        width: '100%',
    },
    productInput: {
        marginRight: '10px',
        width: '100%'
    },
    priceInput: {
        marginRight: '10px',
        maxWidth: '110px'
    },
    deleteProductButton: {
        color: '#d20000'
    },
    ammountAddornment: {
        color: '#757575'
    }
}));
export default function InstallationExtras({ handleRemoveProduct, handleAddProduct, handlePriceChange, products, productsTotal, handleProductChange }) {
    const classes = useStyles();
    return (
        <>
            <p className={classes.insideSubTitle}>If you have purchased any extras in order to complete this installation, add the product and how much you payed for it, one type of product per row. If not, just click Finish</p>
            <Grid container style={{ padding: '16px' }}>
                <Grid item xs={12} md={3}>
                    <Button
                        onClick={handleAddProduct}
                        variant="contained"
                        color="secondary"
                        className={classes.addProductButton}
                        startIcon={<AddCircleOutlineRoundedIcon />}
                    >
                        Add Product
                    </Button>
                </Grid>
            </Grid>
            <List>
                {products &&
                    products.map((object, i) => (
                        <ListItem key={i}>
                            <TextField className={classes.productInput} id={`product${i}`} label="Product" value={object.name} name={i} onChange={handleProductChange} />
                            <TextField className={classes.priceInput} id={`price${i}`} label="Price"
                                name={i}
                                onChange={handlePriceChange}
                                value={object.price}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start" className={classes.ammountAddornment}>
                                            <AttachMoneyOutlinedIcon />
                                        </InputAdornment>
                                    )
                                }}
                            />
                            <IconButton aria-label="delete" className={classes.deleteProductButton} onClick={() => handleRemoveProduct(i)}>
                                <DeleteIcon />
                            </IconButton>
                        </ListItem>
                    ))
                }

                < p className={classes.total}>Total <b>${productsTotal}</b> on <b>{products ? products.length : '0'}</b> Products</p>
            </List>
        </>
    )
}