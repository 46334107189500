import { React, useState, useContext, useEffect } from "react";
import { spanish } from '../languages/es';
import { english } from '../languages/en';
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import MUIDataTable from "mui-datatables";
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Tooltip from '@material-ui/core/Tooltip';
import { useMediaQuery } from 'react-responsive';
import Axios from "axios";
import { OptionsContext } from "../../App";
import { flatMap } from "lodash";

const ApplicationsList = ({ setStatusDialog, statusDialog, data, host, token }) => {
    const [globalOptions, setGlobalOptions] = useContext(OptionsContext);
    const [search, setSearch] = useState('');
    const [columnData, setColumnData] = useState(data);
    const [results, setResults] = useState(false);
    const language = english;
    const history = useHistory();
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' });
    const isMobile = useMediaQuery({ query: '(max-width: 480px)' });
    const months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
    const headerBackground = '#245c80';
    const headerColor = 'white';

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            handleSearch(search);
            // Send Axios request here
        }, 500)

        return () => clearTimeout(delayDebounceFn)
    }, [search]);

    useEffect(() => {
        if (results) {
            let tempData = data;
            results.map((i) => {
                const app_id = `${i[0]['app_id']}`;
                const exists = tempData.filter((i) => {
                    return i['app_id'] == app_id;
                });
                if (exists.length === 0) {
                    tempData = [...tempData, i[0]];
                }
            })
            setColumnData(tempData);

        }
    }, [results]);


    const handleSearch = (query) => {
        Axios.get(
            `${host}/applications/search?query=${query}`,
            { headers: { Authorization: `Bearer ${token}` } }
        )
            .then((res) => {
                setResults(res.data);
            })
            .catch((error) => {
                if (error) {
                    console.log(error.response.data);
                }
            });
    }
    const columns = [
        {
            name: 'app_id',
            label: 'App Id',
            options: {
                filter: false,
                sort: true,
                setCellHeaderProps: () => ({ style: { background: headerBackground, color: headerColor } })
            }
        },
        {
            name: 'salesperson',
            label: 'Salesperson',
            options: {
                filter: true,
                sort: true,
                setCellHeaderProps: () => ({ style: { background: headerBackground, color: headerColor } })
            }
        },
        {
            name: 'full_name',
            label: 'Applicant',
            options: {
                filter: false,
                sort: true,
                setCellHeaderProps: () => ({ style: { background: headerBackground, color: headerColor } })
            }
        },
        {
            name: 'status_name',
            label: 'Status',
            options: {
                filter: true,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <div style={{ fontWeight: 'bold', textAlign: 'center', padding: '0.2rem', borderRadius: '20px', background: tableMeta.rowData[7], color: tableMeta.rowData[8] }}>{value}</div>
                ),
                filterType: 'multiselect',
                sort: true,
                setCellHeaderProps: () => ({ style: { background: headerBackground, color: headerColor } })
            }
        },
        {
            name: 'agency_approved',
            label: 'agency_approved',
            options: {
                filter: false,
                sort: true,
                download: false,
                display: 'excluded',
                setCellHeaderProps: () => ({ style: { background: headerBackground, color: headerColor } })
            }
        },
        {
            name: 'agency_name',
            label: 'Paperwork',
            options: {
                filter: true,
                sort: true,
                filterType: 'multiselect',
                customBodyRender: (value, tableMeta) => (value !== null ? <p style={{ background: 'rgb(65, 156, 212)', color: 'white', padding: '0.2rem', borderRadius: '19px', textAlign: 'center', fontWeight: 'bold' }}>{value}</p> : ''),
                setCellHeaderProps: () => ({ style: { background: headerBackground, color: headerColor } })
            }
        },
        {
            name: 'signed_by_user',
            label: 'User Signed',
            options: {
                filter: true,
                sort: true,
                download: false,
                customBodyRender: (value) => (value !== null ? <div style={{ fontWeight: 'bold', textAlign: 'center', padding: '0.2rem', borderRadius: '20px', background: '#7ac142', color: '#275800' }}>Yes</div> : ''),
                setCellHeaderProps: () => ({ style: { background: headerBackground, color: headerColor } })
            }
        },
        {
            name: 'status_bg',
            label: 'bg',
            options: {
                display: 'excluded',
                filter: false,
                sort: true,
                download: false,
                setCellHeaderProps: () => ({ style: { background: headerBackground, color: headerColor } })
            }
        },
        {
            name: 'status_color',
            label: 'color',
            options: {
                display: 'excluded',
                filter: false,
                sort: true,
                download: false,
                setCellHeaderProps: () => ({ style: { background: headerBackground, color: headerColor } })
            }
        },

        {
            name: 'status_id',
            label: 'Status ID',
            options: {
                display: 'excluded',
                filter: false,
                sort: true,
                download: false,
                setCellHeaderProps: () => ({ style: { background: headerBackground, color: headerColor } })
            }
        },
        {
            name: 'installation_address',
            label: 'Address',
            options: {
                display: 'excluded',
                filter: false,
                sort: true,
                download: false,
                setCellHeaderProps: () => ({ style: { background: headerBackground, color: headerColor } })
            }
        },
        {
            name: 'contact_address',
            label: 'Address',
            options: {
                customBodyRender: (value, tableMeta, updateValue) => (
                    <p>{tableMeta.rowData[9] === '1' ? value : tableMeta.rowData[10]}</p>
                ),
                filter: false,
                sort: true,
                setCellHeaderProps: () => ({ style: { background: headerBackground, color: headerColor } })
            }
        },
        {
            name: 'date_added',
            label: 'Date Added',
            options: {
                filter: false,
                sort: true,
                setCellHeaderProps: () => ({ style: { background: headerBackground, color: headerColor } })
            }
        },
        {
            name: 'installer_name',
            label: 'Installer',
            options: {
                filter: true,
                sort: true,
                setCellHeaderProps: () => ({ style: { background: headerBackground, color: headerColor } })
            }
        },
        {
            name: 'main_product',
            label: 'Product',
            options: {
                filter: true,
                sort: true,
                display: 'excluded',
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (<p>{value === null ? '' : value}</p>)
                },
                setCellHeaderProps: () => ({ style: { background: headerBackground, color: headerColor } })
            }
        },
        {
            name: 'appointment_datetime',
            label: 'Scheduled At',
            options: {
                customBodyRender: (value) => (
                    <p>{value !== null && `${months[new Date(value).getMonth()]}/${new Date(value).getDate()}/${new Date(value).getFullYear()} ${new Date(value).getHours()}:${new Date(value).getMinutes()}${new Date(value).getMinutes() === 0 ? '0' : ''}`}</p>
                ),
                filter: false,
                sort: true,
                download: false,
                setCellHeaderProps: () => ({ style: { background: headerBackground, color: headerColor } })
            }
        },
        {
            name: 'app_id',
            label: 'Actions',
            options: {
                customBodyRender: (value, tableMeta, updateValue) => (
                    <Tooltip title="View Application Details">
                        <IconButton aria-label="delete" onClick={() => history.push(`/app_details/${value}`)}>
                            <VisibilityIcon />
                        </IconButton>
                    </Tooltip>
                ),
                filter: false,
                sort: true,
                download: false,
                display: isTabletOrMobile ? 'excluded' : true,
                setCellHeaderProps: () => ({ style: { background: headerBackground, color: headerColor } })

            }
        },


    ];
    const options = {
        selectableRows: 'none',
        fixedHeader: true,
        responsive: isTabletOrMobile ? 'stacked' : 'standard',
        fixedSelectColumn: true,
        tableBodyHeight: '400px',
        onRowClick: isTabletOrMobile ? (rowData) => history.push(`/app_details/${rowData[0]}`) : '',
        downloadOptions: { filename: 'EPWT Applications.csv', filterOptions: { useDisplayedColumnsOnly: false, useDisplayedRowsOnly: true } },
        onSearchChange: (e) => setSearch(e),
        onSearchClose: () => setColumnData(data),
        onFilterChange: (changedColumn, filterList) => console.log(changedColumn, filterList)
    }
    return (
        <>
            {columnData &&
                <MUIDataTable
                    title={" "}
                    data={columnData}
                    columns={columns}
                    options={options}
                />
            }

        </>
    )
}
export default ApplicationsList;