import Axios from "axios";
const FileDownload = require('js-file-download');

//Generate isoftpull request
const runIsoftPull = (id, host, token) => {
    Axios.get(
        `${host}/scripts/isoftPull?customer_id=${id}`,
        { headers: { Authorization: `Bearer ${token}` } })
        .then(
            res => FileDownload(res.data, 'isoftpull_' + id + '.py')
        )
        .catch((error) => {
            if (error) window.alert(error.response.data);
        })
}

//Generate AFI request without Co-applicant
const afiWithoutCoapplicant = (appId, applicant, host, token) => {
    Axios.get(
        `${host}/scripts/afiWithoutCoapplicant&app_id=${appId}&applicant=${applicant}`,
        { headers: { Authorization: `Bearer ${token}` } })
        .then(
            res => FileDownload(res.data, `afi${applicant}_${appId}.py`)
        )
        .catch((error) => {
            if (error) window.alert(error.response.data);
        })
}
const afiBothApplicants = (appId, host, token) => {
    Axios.get(
        `${host}/scripts/afiWithCoapplicant&app_id=${appId}`,
        { headers: { Authorization: `Bearer ${token}` } })
        .then(
            res => FileDownload(res.data, 'afiBothApplicants_' + appId + '.py')
        )
        .catch((error) => {
            if (error) window.alert(error.response.data);
        })
}
export { afiWithoutCoapplicant, afiBothApplicants, runIsoftPull }