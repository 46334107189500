import React from 'react';
import 'date-fns';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';

const DatePicker = ({ setDate, date, label, style }) => {
    // const [selectedDate, setSelectedDate] = React.useState(date);
    // const handleDateChange = (selectedValue) => {
    //     setSelectedDate(selectedValue);
    //     setDate(selectedValue);
    // };
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container style={style}>
                <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label={label}
                    value={date}
                    onChange={(selectedDate) => setDate(selectedDate)}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    style={{ marginRight: '5px' }}
                    autoOk="true"
                />
            </Grid>
        </MuiPickersUtilsProvider>
    );
};


export default DatePicker;