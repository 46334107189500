const data = {
    firstname: '',
    lastname: '',
    address: '',
    city: '',
    state: '',
    stateAB: '',
    zip: '',
    // ssn: '',
    dob: '',
    contactAddress: {
        address: '',
        city: '',
        state: '',
        zip: ''
    }
}
const errors = {
    firstname: {
        error: false,
        message: ''
    },
    lastname: {
        error: false,
        message: ''
    },
    address: {
        error: false,
        message: ''
    },
    city: {
        error: false,
        message: ''
    },
    state: {
        error: false,
        message: ''
    },
    zip: {
        error: false,
        message: ''
    },
    // ssn: {
    //     error: false,
    //     message: ''
    // },
    dob: {
        error: false,
        message: 'mm/dd/yyyy'
    },
    phone: {
        error: false,
        message: '',
    },
    phoneType: {
        error: false,
        message: '',
    },
    email: {
        error: false,
        message: '',
    },
    expDate: {
        error: false,
        message: ''
    },
    idType: {
        error: false,
        message: ''

    },
    stateId: {
        error: false,
        message: ''

    },
    productType: {
        error: false,
        message: ''

    },
    shedNeeded: {
        error: false,
        message: ''

    },
    installationAddress: {
        error: false,
        message: ''
    },
    installationCity: {
        error: false,
        message: ''
    },
    installationState: {
        error: false,
        message: ''
    },
    installationZip: {
        error: false,
        message: ''
    },
    residenceType: {
        error: false,
        message: ''

    },
    residenceMortgage: {
        error: false,
        message: ''

    },
    residenceYearsOfResidence: {
        error: false,
        message: ''

    },
    residenceMonthsOfResidence: {
        error: false,
        message: ''

    },
    employmentType: {
        error: false,
        message: ''

    },
    employmentYears: {
        error: false,
        message: ''

    },
    employmentMonths: {
        error: false,
        message: ''

    },
    employmentEmployer: {
        error: false,
        message: ''

    },
    employmentPosition: {
        error: false,
        message: ''

    },
    employmentIncome: {
        error: false,
        message: ''

    },
    financedAmount: {
        error: false,
        message: ''

    }
}

const addressValidation = {
    number: "",
    street: "",
    city: "",
    state: "",
    zip: ""
}

const idInformation = {
    idType: "",
    stateId: "",
    expDate: ""
}

const installationAddress = {
    address: "",
    city: "",
    state: "",
    stateAB: "",
    zip: ""
}
const installationDetails = {
    productId: "",
    needsShed: ""
}

const contactInfo = {
    phone: "",
    phoneType: "",
    email: ""
}

const residence = {
    type: "",
    mortgage: "",
    yearsOfResidence: "",
    monthsOfResidence: ""
}

const employment = {
    type: "",
    years: "",
    months: "",
    employer: "",
    position: "",
    income: ""

}

const googlePlace = {
    address: '',
    city: '',
    state: '',
    stateAB: '',
    zip: ''

}

function fieldsToValidate(applicantInfo, idInformation, installationDetails, installationAddress, contactInfo, residenceInfo, employmentInfo, financedAmount) {
    return [
        ["firstname", applicantInfo.firstname, "applicantInfoRef"],
        ["lastname", applicantInfo.lastname, "applicantInfoRef"],
        // ["ssn", applicantInfo.ssn, "applicantInfoRef"],
        ["dob", applicantInfo.dob, "applicantInfoRef"],
        ["idType", idInformation.idType, "idInfoRef"],
        ["stateId", idInformation.stateId, "idInfoRef"],
        ["stateId", idInformation.stateId, "idInfoRef"],
        ["expDate", idInformation.expDate, "idInfoRef"],
        ["productType", installationDetails.productId, "installationRef"],
        ["shedNeeded", installationDetails.needsShed, "installationRef"],
        ["installationAddress", installationAddress.address, "installationAddressRef"],
        ["installationCity", installationAddress.city, "installationAddressRef"],
        ["installationState", installationAddress.state, "installationAddressRef"],
        ["installationZip", installationAddress.zip, "installationAddressRef"],
        ["phone", contactInfo.phone, "contactInformationRef"],
        ["phoneType", contactInfo.phoneType, "contactInformationRef"],
        ["residenceType", residenceInfo.type, "residenceRef"],
        ["residenceMortgage", residenceInfo.mortgage, "residenceRef"],
        ["residenceYearsOfResidence", residenceInfo.yearsOfResidence, "residenceRef"],
        ["residenceMonthsOfResidence", residenceInfo.monthsOfResidence, "residenceRef"],
        ["employmentType", employmentInfo.type, "employmentRef"],
        ["employmentYears", employmentInfo.years, "employmentRef"],
        ["employmentMonths", employmentInfo.months, "employmentRef"],
        ["employmentEmployer", employmentInfo.employer, "employmentRef"],
        ["employmentPosition", employmentInfo.position, "employmentRef"],
        ["employmentIncome", employmentInfo.income, "employmentRef"],
        ["financedAmount", financedAmount, "employmentRef"]
    ]
}

export { data, errors, addressValidation, idInformation, installationAddress, installationDetails, contactInfo, residence, employment, googlePlace, fieldsToValidate };