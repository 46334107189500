import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
const useStyles = makeStyles((theme) => ({
    insideTitle: {
        margin: '1rem 16px 1rem 16px'
    },
    insideSubTitle: {
        margin: '0 16px 0.5rem 16px'
    },
    total: {
        margin: '16px'
    },
    addProductButton: {
        width: '100%',
    },
    imageGallery: {
        margin: '16px'
    },
    imageBox: {
        border: 'solid 1px #dfdfdf',
        borderRadius: '5px',
        position: 'relative',
        margin: '16px'
    },
    image: {
        width: '100%',
        borderTopLeftRadius: '5px',
        borderTopRightRadius: '5px'
    }
}));
export default function InstallationPictures({ installationPictures, handleAddInstallationPictures, handleRemoveInstallationPicture }) {
    const classes = useStyles();
    return (
        <>
            <p className={classes.insideSubTitle}>Upload the pictures for this installation. You must include pictures of every system installed for this service showing that the job is complete and neat</p>

            <Grid container>
                <Grid item xs={12}>
                    <div className={classes.imageGallery}>
                        <input
                            accept="image/*"
                            style={{ 'display': 'none' }}
                            id="contained-button-file"
                            multiple
                            type="file"
                            onChange={(event) => {
                                handleAddInstallationPictures(event.target.files);
                            }}
                        />
                        <label htmlFor="contained-button-file">
                            <Button className="mb-3" variant="contained" color="secondary" component="span" startIcon={<AddCircleOutlineRoundedIcon />}>
                                Add Pictures
                            </Button>
                        </label>
                    </div>
                </Grid>

                {installationPictures.length > 0 &&
                    installationPictures.map((object, i) => (
                        <Grid item xs={12} sm={4} md={3} lg={2} xl={2} >
                            <div className={classes.imageBox}>
                                <img className={classes.image} alt="not found" width={"200px"} src={URL.createObjectURL(object)} />
                                <Button
                                    color="secondary"
                                    className={classes.button}
                                    startIcon={<DeleteIcon />}
                                    size="small"
                                    onClick={() => handleRemoveInstallationPicture(i)}
                                >
                                    Remove
                                </Button>

                            </div>

                        </Grid>
                    ))
                }
            </Grid>
        </>
    )
}
