import { React, useCallback, useEffect, useState } from "react";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { FormControl, InputLabel } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import Axios from "axios";
import CustomizedSnackbars from "../alerts/CustomizedSnackbars";
import DateTimePicker from "../assets/dateTimePicker";
import jsToMysqlDate from "../assets/jsToMysqlDate";


const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

export default function AssignInstallationDialog({ open, close, data, products, host, token, refreshList, app_id }) {
    const classes = useStyles();

    //Mains states
    const [installers, setInstallers] = useState(false);
    const [installationDateTime, setInstallationDateTime] = useState(new Date());
    const [installerId, setInstallerId] = useState(false);

    //Secondary states
    const [alert, setAlert] = useState({ show: false, severity: "", message: "" });
    const months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];


    const updateInstallerAndDatetime = () => {
        // console.log(new Date(jsToMysqlDate(installationDateTime)));
        Axios.put(
            `${host}/installation/updateInstallerAndDatetime`,
            { 'installation_id': data.installation_id, 'installer_id': installerId, 'appointment_datetime': jsToMysqlDate(installationDateTime), 'app_id': app_id },
            { headers: { Authorization: `Bearer ${token}` } })
            .then((res) => {
                setAlert({ ...alert, show: true, severity: 'success', message: res.data });
                setTimeout(() => {
                    close()
                    refreshList();
                }, 1000);
            })
            .catch((error) => {
                error && setAlert({ ...alert, show: true, severity: 'error', message: error.response.data });
            })
    }

    //Copy installation details
    const copyDetails = (details) => {
        details = details.replaceAll('<br>', '\n');
        navigator.clipboard.writeText(details);
    }

    //Load Installers
    useEffect(() => {
        Axios.get(`${host}/user/getInstallers`, { headers: { Authorization: `Bearer ${token}` } })
            .then(res => {
                setInstallers(res.data);
            })
            .catch((error) => {
                error && setAlert({ ...alert, show: true, severity: 'error', message: error.response.data });
            })
    }, []);

    useEffect(() => {
        data.appointment_datetime !== null && setInstallationDateTime(new Date(data.appointment_datetime));
        data.installer_id !== null && setInstallerId(data.installer_id);
    }, []);




    return (
        <div>
            {alert.show &&
                <CustomizedSnackbars
                    severity={alert.severity}
                    message={alert.message}
                    hideAlert={() => setAlert({ ...alert, show: false, severity: "", message: "" })}
                />
            }


            <Dialog fullWidth={true} open={open} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Assign new Installation</DialogTitle>
                <FormControl className={classes.formControl}>
                    <div style={{ border: 'dotted #888 1px', padding: '0.5rem' }}>
                        <p id="installation_details">
                            {`${months[installationDateTime.getMonth()]}/${installationDateTime.getDate()}/${installationDateTime.getFullYear()}`}<br />
                            Hora: {`${installationDateTime.toLocaleTimeString('en-US', { timeStyle: 'short', hour12: true })}`}<br /><br />
                            Dirección: {data.installation_address}, {data.installation_city}, {data.installation_state} {data.installation_zip}	<br /><br />
                            Sistema: {products[0].name} <br />
                            Con casita: {data.needs_shed === 1 ? 'Si' : 'No'}<br /><br />
                            Cliente: {`${data.firstname} ${data.lastname}`}<br />
                            Teléfono: {data.phone}<br /><br />
                            Vendedor: {data.salesperson}
                        </p>
                        <button onClick={() => copyDetails(document.getElementById('installation_details').innerHTML)} style={{ position: 'absolute', top: '5px', right: '5px' }}>Copy</button>
                    </div>
                </FormControl>
                <DateTimePicker setInstallationDateTime={(dateTime) => setInstallationDateTime(dateTime)} installationDatetime={installationDateTime} />
                {installers &&
                    <FormControl className={classes.formControl}>
                        <InputLabel id="status-label">Installer</InputLabel>
                        <Select
                            labelId="status-label"
                            id="status"
                            value={installerId}
                            onChange={(e) => setInstallerId(e.target.value)}
                        >
                            {installers.map((i) => (
                                <MenuItem value={i.user_id}>{i.user_name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                }

                <DialogActions>
                    <Button onClick={close} color="primary">
                        Cancel
                    </Button>
                    <Button color="primary" onClick={updateInstallerAndDatetime} >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>

        </div>
    );
}