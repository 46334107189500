import { makeStyles } from '@material-ui/core/styles';

const inputStyle = {
    style: { color: '#000000a3' }

}

const selectStyle = {
    color: '#000000a3'
}
const selectLabelStyle = {
    fontSize: '12px',
    color: '#9e9e9e'
}

const topBarStyle = {
    width: '100%',
    position: 'fixed',
    top: '0',
    zIndex: '1'
}
const editButtonStyle = {
    position: 'fixed',
    bottom: '0',
    zIndex: '99',
    right: '0',
    margin: '1rem'
}
const useStylesAppbar = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    titleSecondary: {
        margin: 0,
        fontSize: 'smaller',
        opacity: 0.5
    },
    avatarBox: {
        position: 'relative',
        display: 'flex',
        width: 70,
        margin: '1rem 2rem 1rem 12px'
    },
    avatar: {
        maxWidth: 70
    },
    scoreContainer: {
        position: 'absolute',
        left: '3rem',
        top: 0,
        borderRadius: '10px',
        padding: '3px',
        fontSize: 'x-small',
        display: 'flex'
    },
    star: {
        width: '10px !important',
        height: '10px !important'
    },
    scoreText: {
        margin: '0'
    },

    toolbar: {
        minHeight: 150,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(2),
    },
    rightFlex: {
        position: 'relative'
    },
    status: {
        margin: '5px 0',
        fontSize: 'small',
        display: 'inline-block',
        padding: '5px',
        borderRadius: '5px'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    }

}));

export { topBarStyle, useStylesAppbar, inputStyle, selectStyle, selectLabelStyle, editButtonStyle }