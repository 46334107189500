import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Divider, Paper } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    paper: {
        width: '100%',
        padding: '1rem',
        marginBottom: '2rem'
    },
    pill: {
        display: 'inline',
        padding: '5px',
        borderRadius: '5px'
    }
}));


export default function AppHistory({ marginTop, history }) {
    const classes = useStyles();
    const [filteredDates, setFilteredDates] = useState(false);

    useEffect(() => {
        let dates = [];
        history.map((i) => {
            console.log(i.date_added);
            dates = [...dates, new Date(Date.parse(i.date_added)).toDateString()];
        });
        const filtered = () => { return dates.filter((value, index) => dates.indexOf(value) === index) }
        setFilteredDates(filtered);
    }, [])

    return (

        <div style={{ marginTop, padding: '0 1rem', marginBottom: 56 }} className={classes.root}>
            <br />
            {filteredDates && filteredDates.map((i) => (
                <>
                    <h4>{i}</h4>
                    <br />
                    <Paper elevation={4} className={classes.paper}>
                        {history.map((j) => {
                            let date = new Date(Date.parse(j.date_added)).toDateString();
                            let hour = new Date(Date.parse(j.date_added)).toLocaleTimeString();
                            let user = j.user_name;
                            if (date === i) {
                                return (
                                    <>
                                        <p>{hour} {user && <span style={{ color: '#888888', fontSize: 'small', marginLeft: '5px' }}>({user})</span>}</p>
                                        <p className={classes.pill} style={{ background: j.status_bg, color: j.status_color }}>{j.status_name}</p>
                                        <p dangerouslySetInnerHTML={{ __html: j.comments }}></p>
                                        <Divider />
                                    </>
                                )
                            }
                        })}
                    </Paper>
                </>
            ))}


        </div>

    );
}
