const english = {
    applications: 'Applications',
    status: 'Status',
    applicant: 'Applicant',
    address: 'Address',
    dateAdded: 'Date Added',
    actions: 'Actions',
    more: 'More',
    moreDetails: 'More Details',
    search: 'Search',
    rows: 'Rows',
    fromTo: '{from}-{to} of {count}',
    noResults: 'No results found',
    filterBy: 'Filter by',
    rowsSelected: '{0} row(s) selected'
}
export { english }