import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import PersonIcon from '@material-ui/icons/Person';
import RoomIcon from '@material-ui/icons/Room';
import BuildIcon from '@material-ui/icons/Build';
import { Phone } from '@material-ui/icons';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import WorkIcon from '@material-ui/icons/Work';
import HomeIcon from '@material-ui/icons/Home';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { Grid, Select, TextField, InputLabel, MenuItem, Fab, InputAdornment } from '@material-ui/core';
import Axios from 'axios';
import GridItem from './appDetailsListItem';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import { inputStyle, selectStyle, selectLabelStyle, editButtonStyle, maskedInputDisabledStyle } from './styles';
import { LoadingFullScreen } from '../application/assets';
import CustomizedSnackbars from '../alerts/CustomizedSnackbars';
import ReactInputMask from 'react-input-mask';
import usaStates from '../data/usaStates';


const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '100%',
        backgroundColor: theme.palette.background.paper,
        padding: '1rem'
    },
    avatar: {
        alignSelf: 'flex-start',
        marginTop: 8
    }
}));

export default function AppDetailsList({ marginTop, applicant, host, token, products, appId, FetchData, activeTab, installationPath }) {
    const classes = useStyles();
    const [disabledInput, setDisabledInput] = useState(true);
    const [applicantData, setApplicantData] = useState(applicant);
    const [product, setProduct] = useState(products !== null ? products[0].product_id : false);
    const [options, setOptions] = useState(false);
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState(false);

    //Handlers
    const handleInput = (e) => {
        setApplicantData({ ...applicantData, [e.target.name]: e.target.value });
    }

    const handleProducts = (e) => {
        setProduct(e.target.value);
    }

    //Save Changes
    const saveChanges = () => {
        setLoading(true)
        Axios.put(
            `${host}/application/updateAppDetails`,
            { appId, applicantData, product },
            { headers: { Authorization: `Bearer ${token}` } })
            .then(
                res => setAlert({ message: res.data, severity: 'success' }), setLoading(false), setDisabledInput(true), FetchData(activeTab)
            )
            .catch((error) => {
                if (error) {
                    setAlert({ message: error.response.data, severity: 'error' });
                    setLoading(false);
                }
            })
    }

    useEffect(() => {
        Axios.get(
            `${host}/application/readApplicationOptions`,
            { headers: { Authorization: `Bearer ${token}` } })
            .then(
                res => setOptions(res.data)
            )
            .catch((error) => {
                if (error) window.alert(error.response.data);
            })
    }, []);

    return (
        <List className={classes.root} style={{ marginTop: marginTop }}>
            {loading &&
                <LoadingFullScreen />
            }

            {alert &&
                <CustomizedSnackbars message={alert.message} severity={alert.severity} hideAlert={() => setAlert(false)} />
            }

            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    {/* <img className='mb-2' style={{ width: '100%' }} src={`${host}/images/identification/${applicantData.file_name}`} alt="Customer Image" /> */}
                    <GridItem avatar={<PersonIcon />}>
                        <p className="mb-2">{installationPath ? 'Customer name' : 'Applicant Information'}</p>
                        <TextField name="firstname" onChange={handleInput} inputProps={inputStyle} fullWidth={true} className='mb-2' disabled={disabledInput} label="Firstname" value={applicantData.firstname} /><br />
                        <TextField name="lastname" onChange={handleInput} inputProps={inputStyle} fullWidth={true} className='mb-2' disabled={disabledInput} label="Lastname" value={applicantData.lastname} /><br />

                        {/* <>
                            <ReactInputMask
                                mask="999/99/9999"
                                value={applicantData.ssn}
                                maskChar="_"
                                onChange={handleInput}
                                disabled={disabledInput}

                            >
                                {() => <TextField inputProps={{ type: 'tel', disabled: disabledInput, className: disabledInput ? 'masked-input-disabled' : '' }} fullWidth={true} name="ssn" className={disabledInput ? 'mb-2 masked-input-wrapper' : 'mb-2'} label="SSN" />}
                            </ReactInputMask><br />
                        </> */}
                        {!installationPath &&
                            <>
                                <ReactInputMask
                                    mask="99/99/9999"
                                    value={applicantData.dob}
                                    maskChar="_"
                                    onChange={handleInput}
                                    disabled={disabledInput}
                                >
                                    {() => <TextField name="dob" inputProps={{ type: 'tel', disabled: disabledInput, className: disabledInput ? 'masked-input-disabled' : '' }} fullWidth={true} className={disabledInput ? 'mb-2 masked-input-wrapper' : 'mb-2'} label="DOB" />}
                                </ReactInputMask><br />
                            </>
                        }



                    </GridItem>
                    {applicantData.installation_address &&
                        <GridItem avatar={<RoomIcon />}>
                            <p className="mb-2">Installation Address</p>
                            <TextField name="installation_address" onChange={handleInput} inputProps={inputStyle} fullWidth={true} className='mb-2' disabled={disabledInput} label="Address" value={applicantData.installation_address} /><br />
                            <TextField name="installation_city" onChange={handleInput} inputProps={inputStyle} fullWidth={true} className='mb-2' disabled={disabledInput} label="City" value={applicantData.installation_city} /><br />
                            <TextField name="installation_state" onChange={handleInput} inputProps={inputStyle} fullWidth={true} className='mb-2' disabled={disabledInput} label="State" value={applicantData.installation_state} /><br />
                            <TextField name="installation_zip" onChange={handleInput} inputProps={inputStyle} fullWidth={true} className='mb-2' disabled={disabledInput} label="Zip" value={applicantData.installation_zip} /><br />
                        </GridItem>
                    }
                    {applicantData.contact_address && applicantData.installation_address === null &&
                        <GridItem avatar={<RoomIcon />}>
                            <p className="mb-2">Contact Address</p>
                            <TextField name="contact_address" onChange={handleInput} inputProps={inputStyle} fullWidth={true} className='mb-2' disabled={disabledInput} label="Address" value={applicantData.contact_address} /><br />
                            <TextField name="contact_city" onChange={handleInput} inputProps={inputStyle} fullWidth={true} className='mb-2' disabled={disabledInput} label="City" value={applicantData.contact_city} /><br />
                            <InputLabel style={selectLabelStyle} id="contact_state-label">State</InputLabel>
                            <Select
                                fullWidth={true}
                                className='mb-2'
                                disabled={disabledInput}
                                labelId="contact_state-label"
                                id="contact_state"
                                style={selectStyle}
                                value={applicantData.contact_state}
                                name="contact_state" onChange={handleInput}
                            >
                                {usaStates.map((i) => (
                                    <MenuItem value={i.name}>{i.name}</MenuItem>
                                ))}
                            </Select>
                            <TextField name="contact_zip" onChange={handleInput} inputProps={inputStyle} fullWidth={true} className='mb-2' disabled={disabledInput} label="Zip" value={applicantData.contact_zip} /><br />
                        </GridItem>
                    }
                </Grid>
                {applicantData.phone &&
                    <Grid item xs={12} md={4}>
                        {products &&
                            <GridItem avatar={<BuildIcon />}>
                                <p className="mb-2">Installation details</p>
                                <InputLabel style={selectLabelStyle} id="product-label">Product</InputLabel>
                                <Select
                                    fullWidth={true}
                                    className='mb-2'
                                    disabled={disabledInput}
                                    labelId="product-label"
                                    id="product"
                                    value={product}
                                    style={selectStyle}
                                    onChange={handleProducts}
                                >
                                    {options && options.products.map((i) => (
                                        <MenuItem key={i} value={i.product_id}>{i.name}</MenuItem>
                                    ))}

                                </Select><br />
                                <InputLabel style={selectLabelStyle} id="needs_shed-label">Needs Shed</InputLabel>
                                <Select
                                    fullWidth={true}
                                    disabled={disabledInput}
                                    className='mb-2'
                                    labelId="needs_shed-label"
                                    id="needs_shed"
                                    style={selectStyle}
                                    value={applicantData.needs_shed}
                                    name="needs_shed"
                                    onChange={handleInput}
                                >
                                    <MenuItem value={1}>Yes</MenuItem>
                                    <MenuItem value={0}>No</MenuItem>
                                </Select>
                            </GridItem>
                        }
                        <GridItem avatar={<Phone />}>
                            <p className="mb-2">Phone</p>
                            <InputLabel style={selectLabelStyle} id="phone_type">Type</InputLabel>
                            <Select
                                fullWidth={true}
                                className='mb-2'
                                disabled={disabledInput}
                                style={selectStyle}
                                labelId="phone_type-label"
                                id="phone_type"
                                value={applicantData.phone_type_id}
                                name="phone_type_id"
                                onChange={handleInput}
                            >
                                {options && options.config_phone_types.map((i) => (
                                    <MenuItem value={i.phone_type_id}>{i.phone_type_name}</MenuItem>
                                ))}

                            </Select><br />


                            <ReactInputMask
                                mask="(999)999-9999"
                                value={applicantData.phone}
                                maskChar="_"
                                onChange={handleInput}
                                disabled={disabledInput}
                            >
                                {() => <TextField name="phone" onChange={handleInput} inputProps={{ type: 'tel', disabled: disabledInput, className: disabledInput ? 'masked-input-disabled' : '' }} fullWidth={true} className={disabledInput ? 'mb-2 masked-input-wrapper' : 'mb-2'} label="Phone" />}
                            </ReactInputMask><br />



                        </GridItem>
                        {applicantData.id_type_name && !installationPath &&
                            <GridItem avatar={<FingerprintIcon />}>
                                <p className="mb-2">Driver's License or Legal ID</p>
                                <Select
                                    fullWidth={true}
                                    className='mb-2'
                                    disabled={disabledInput}
                                    labelId="id_type-label"
                                    id="id_type"
                                    style={selectStyle}
                                    value={applicantData.id_type_id}
                                    name="id_type_id"
                                    onChange={handleInput}
                                >
                                    {options && options.config_id_types.map((i) => (
                                        <MenuItem value={i.id_type_id}>{i.id_type_name}</MenuItem>
                                    ))}
                                </Select>
                                <Select
                                    fullWidth={true}
                                    className='mb-2'
                                    disabled={disabledInput}
                                    labelId="id_type-label"
                                    id="id_state"
                                    style={selectStyle}
                                    value={applicantData.id_state}
                                    name="id_state"
                                    onChange={handleInput}
                                >
                                    {usaStates.map((i) => (
                                        <MenuItem value={i.abbreviation}>{i.name}</MenuItem>
                                    ))}
                                </Select>


                                <ReactInputMask
                                    mask="99/99/9999"
                                    value={applicantData.id_exp_date}
                                    maskChar="_"
                                    onChange={handleInput}
                                    disabled={disabledInput}
                                >
                                    {() => <TextField name="id_exp_date" inputProps={{ type: 'tel', disabled: disabledInput, className: disabledInput ? 'masked-input-disabled' : '' }} fullWidth={true} className={disabledInput ? 'mb-2 masked-input-wrapper' : 'mb-2'} label="ID Expiration Date" />}
                                </ReactInputMask><br />

                            </GridItem>
                        }
                        {applicantData.employer && !installationPath &&
                            <GridItem avatar={<WorkIcon />}>
                                <p className="mb-2">Employment</p>
                                <Select
                                    fullWidth={true}
                                    className='mb-2'
                                    disabled={disabledInput}
                                    style={selectStyle}
                                    labelId="phone_type-label"
                                    id="phone_type"
                                    value={applicantData.employment_type_id}
                                    name="employment_type_id"
                                    onChange={handleInput}
                                >
                                    {options && options.config_employment_types.map((i) => (
                                        <MenuItem value={i.employment_type_id}>{i.employment_type_name}</MenuItem>
                                    ))}

                                </Select>
                                <TextField name="employer" onChange={handleInput} inputProps={inputStyle} fullWidth={true} className='mb-2' disabled={disabledInput} label="Employer" value={applicantData.employer} /><br />
                                <TextField name="position" onChange={handleInput} inputProps={inputStyle} fullWidth={true} className='mb-2' disabled={disabledInput} label="Position" value={applicantData.position} /><br />
                                <TextField name="years_at_work" onChange={handleInput} inputProps={inputStyle} fullWidth={true} className='mb-2' disabled={disabledInput} label="Years of Income" value={applicantData.years_at_work} /><br />
                                <TextField name="months_at_work" onChange={handleInput} inputProps={inputStyle} fullWidth={true} className='mb-2' disabled={disabledInput} label="Months of Income" value={applicantData.months_at_work} /><br />
                                <TextField
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <AttachMoneyIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                    name="income" onChange={handleInput} inputProps={inputStyle} fullWidth={true} className='mb-2' disabled={disabledInput} label="Monthly Income" value={applicantData.income} /><br />
                            </GridItem>
                        }
                    </Grid>

                }
                {applicantData.residence_type_name && !installationPath &&

                    <Grid item xs={12} md={4}>
                        <GridItem avatar={<HomeIcon />}>
                            <p className="mb-2">Residence</p>
                            <Select
                                fullWidth={true}
                                className='mb-2'
                                disabled={disabledInput}
                                labelId="residence_type-label"
                                id="residence_type"
                                style={selectStyle}
                                value={applicantData.residence_type_id}
                                name="residence_type_id" onChange={handleInput}
                            >
                                {options && options.config_residence_types.map((i) => (
                                    <MenuItem value={i.residence_type_id}>{i.residence_type_name}</MenuItem>
                                ))}

                            </Select><br />
                            <TextField InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <AttachMoneyIcon />
                                    </InputAdornment>
                                ),
                            }}
                                name="mortgage" onChange={handleInput} inputProps={inputStyle} fullWidth={true} className='mb-2' disabled={disabledInput} label="Mortgage" value={`${applicantData.mortgage}`} /><br />
                            <TextField name="years_at_residence" onChange={handleInput} inputProps={inputStyle} fullWidth={true} className='mb-2' disabled={disabledInput} label="Years at address" value={applicantData.years_at_residence} /><br />
                            <TextField name="months_at_residence" onChange={handleInput} inputProps={inputStyle} fullWidth={true} className='mb-2' disabled={disabledInput} label="Months at address" value={applicantData.months_at_residence} /><br />
                        </GridItem>
                        <GridItem avatar={<AttachMoneyIcon />}>
                            <p className="mb-2">Amount To be financed</p>
                            <TextField
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <AttachMoneyIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                name="amount_financed" onChange={handleInput} inputProps={inputStyle} fullWidth={true} className='mb-2' disabled={disabledInput} value={applicantData.amount_financed ? applicantData.amount_financed : ""} />
                        </GridItem>

                    </Grid>
                }
            </Grid>
            {disabledInput && !installationPath &&
                <Fab onClick={() => setDisabledInput(false)} style={editButtonStyle} size='small' color="primary" aria-label="edit">
                    <EditIcon />
                </Fab>
            }
            {!disabledInput &&
                <Fab style={editButtonStyle} size='small' color="primary" aria-label="save" onClick={saveChanges}>
                    <SaveIcon />
                </Fab>
            }
        </List >
    );
}
