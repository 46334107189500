import React, { useState, useRef, useEffect, useCallback, useContext } from "react";
import { Button, FormControl, InputLabel, MenuItem, Paper, TextField, Select, List, ListItem, ListItemText, Divider, FormHelperText, Snackbar, Grid } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import ReactSignatureCanvas from "react-signature-canvas";
import ReactInputMask from "react-input-mask";
import { Alert } from "@material-ui/lab";
import Axios from "axios";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CustomizedSnackbars from "../alerts/CustomizedSnackbars";
import HorizontalStepper from "../stepper/stepper";
import { useHistory } from "react-router-dom";
import { LoadingFullScreen } from "./assets";
import { validateField } from "./errorHandling/fieldValidations";
import { isNull } from "lodash";
import CreateAppBar from "./appBar";
import BottomMenu from "../footer/bottomMenu";

const errorFormBody = {
    firstname: {
        error: false,
        message: ''
    },
    lastname: {
        error: false,
        message: ''
    },
    address: {
        error: false,
        message: ''
    },
    city: {
        error: false,
        message: ''
    },
    state: {
        error: false,
        message: ''
    },
    zip: {
        error: false,
        message: ''
    },
    ssn: {
        error: false,
        message: ''
    },
    dob: {
        error: false,
        message: 'mm/dd/yyyy'
    },
    relationship: {
        error: false,
        message: 'You must select an option'
    }
}
const addressValidationBody = {
    number: "",
    street: "",
    city: "",
    state: "",
    zip: ""
}
const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
}));


const AddCoap = (props) => {
    const [dataForm, setDataForm] = useState(
        {
            firstname: '',
            lastname: '',
            address: '',
            city: '',
            state: '',
            stateAB: '',
            zip: '',
            ssn: '',
            dob: '',
            relationship: ''
        }
    );

    const [appInfo, setAppInfo] = useState(false);

    const [error, setError] = useState(false);

    //Refs
    const uploadLicense = useRef(null);
    const applicantInfo = useRef(null);

    const [confirmation, setConfirmation] = useState({ show: false, app_id: null });
    const [loading, setLoading] = useState(false);

    const [formError, setFormError] = useState(errorFormBody)

    const [licenseImage, setLicenseImage] = useState(null);
    const [fileName, setFileName] = useState(null);

    const [signature, setSignature] = useState(false);

    const [addressValidation, setAddressValidation] = useState({
        number: "",
        street: "",
        city: "",
        state: "",
        zip: ""
    });
    const [open, setOpen] = useState(false);
    const [alert, setAlert] = useState({
        show: false,
        severity: '',
        message: ''
    });

    const hideAlert = () => {
        let alertData = { ...alert };
        alertData.show = false;
        setAlert(alertData);
    }

    const [googlePlace, setGooglePlace] = useState({
        address: '',
        city: '',
        state: '',
        stateAB: '',
        zip: ''

    });

    const history = useHistory();

    const classes = useStyles();


    //Signature
    const saveSignature = () => {
        if (signCanvas.current.getTrimmedCanvas().toDataURL('image/png') !== 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAAtJREFUGFdjYAACAAAFAAGq1chRAAAAAElFTkSuQmCC') {
            setSignature(signCanvas.current.getTrimmedCanvas().toDataURL('image/png'));
        }

    }

    const clearSignature = () => {
        setSignature(false);
        signCanvas.current.clear();
    }

    const signCanvas = useRef({});

    //Form handling and validation
    const handleForm = (e) => {
        const thisField = validateField(e);
        setErrorMessage(e, thisField);

        let data = { ...dataForm };
        data[e.target.name] = e.target.value;
        setDataForm(data);
    }

    //scrollIntoView
    const scrollToError = (ref, msg) => {
        const reference = ref;
        const toEval = reference + '.current.scrollIntoView({ behavior: "smooth" })';
        let message;
        if (msg === undefined) {
            message = "There are errors in the form"
        } else {
            message = msg
        }
        eval(toEval);
        setAlert({
            severity: "error",
            show: true,
            message: message
        });
    }

    //Final validation
    const validateForm = () => {
        let formIsValid = true;
        const fieldsToValidate = [
            ["firstname", dataForm.firstname, "applicantInfo"],
            ["lastname", dataForm.lastname, "applicantInfo"],
            ["ssn", dataForm.ssn, "applicantInfo"],
            ["dob", dataForm.dob, "applicantInfo"],
            ["address", dataForm.address, "applicantInfo"],
            ["city", dataForm.city, "applicantInfo"],
            ["state", dataForm.state, "applicantInfo"],
            ["zip", dataForm.zip, "applicantInfo"],
            ["relationship", dataForm.relationship, "applicantInfo"],

        ];

        //Global Validate function
        const validation = (name, value, ref) => {
            const fieldToValidate = { target: { name: name, value: value } };
            const thisField = validateField(fieldToValidate);
            setErrorMessage(fieldToValidate, thisField);
            if (!thisField.ok) {
                scrollToError(ref, thisField.message);
                return false;
            } else {
                return true;
            }
        }

        for (let i = 0; i < fieldsToValidate.length; i++) {
            const validate = validation(fieldsToValidate[i][0], fieldsToValidate[i][1], fieldsToValidate[i][2]);
            if (!validate) {
                formIsValid = false;
                break;
            }
        }

        formIsValid && verifyAddress();
    }

    const validateAddress = (number) => {
        let errorData = errorFormBody;

        if (number === undefined) {
            errorData.address.error = true;
            errorData.address.message = "You need to provide the number of the house for this address to be valid";
            setFormError(errorData);
            return false;
        }
        if (number.includes("-") || number.includes("1/2")) {
            errorData.address.error = false;
            errorData.address.message = "";
            setFormError(errorData);
            return true;
        }
        if (isNaN(number)) {
            errorData.address.error = true;
            errorData.address.message = "You need to provide the number of the house for this address to be valid";
            setFormError(errorData);
            return false;
        }
        errorData.address.error = false;
        errorData.address.message = "";
        setFormError(errorData);
        return true;
    }

    //Set and clean error messages
    const setErrorMessage = (e, thisField) => {
        let errorData = { ...formError };
        if (thisField.ok) {
            errorData[e.target.name].error = false;
            errorData[e.target.name].message = thisField.message;
        } else {
            errorData[e.target.name].error = true;
            errorData[e.target.name].message = thisField.message;
        }
        setFormError(errorData);
    }


    //Address autocomplete
    const autoCompleteRef = useRef();
    const inputRef = useRef();
    const options = {
        componentRestrictions: { country: ["us"] },
        fields: ["address_components"],
        types: ["address"]
    }


    useEffect(() => {
        autoCompleteRef.current = new window.parent.google.maps.places.Autocomplete(
            inputRef.current,
            options
        );
        autoCompleteRef.current.addListener("place_changed", async function () {
            const place = await autoCompleteRef.current.getPlace();
            let data = { ...googlePlace };
            let number;
            let street;
            let city;
            let state;
            let stateAB;
            let zip;

            place.address_components.map((i) => {
                if (i.types && i.types[0] === 'street_number') {
                    number = i.long_name;
                }
                if (i.types && i.types[0] === 'route') {
                    street = i.long_name;
                }
                if (i.types && i.types[0] === 'locality') {
                    city = i.long_name;
                }
                if (i.types && i.types[0] === 'administrative_area_level_1') {
                    state = i.long_name;
                    stateAB = i.short_name;
                }
                if (i.types && i.types[0] === 'postal_code') {
                    zip = i.long_name;
                }
            })

            if (validateAddress(number)) {
                data.address = number + ' ' + street;
                data.city = city;
                data.state = state;
                data.stateAB = stateAB;
                data.zip = zip;
                setGooglePlace(data);
            };
        });
    }, []);

    useEffect(() => {
        const gPlace = { ...googlePlace }
        let data = { ...dataForm }
        data.address = gPlace.address;
        data.city = gPlace.city;
        data.state = gPlace.state;
        data.stateAB = gPlace.stateAB;
        data.zip = gPlace.zip;
        setDataForm(data);
    }, [googlePlace])

    const verifyAddress = () => {
        Axios.post('https://addressvalidation.googleapis.com/v1:validateAddress?key=AIzaSyAJvdDkwhK5W8TAXQdiPCLg3ih9SlBjcOU', {
            "address": {
                "regionCode": "US",
                "addressLines": [dataForm.address, dataForm.city + ', ' + dataForm.stateAB + ', ' + dataForm.zip]
            },
            "enableUspsCass": true
        }).then((res) => {
            if (res.data.result.verdict.hasUnconfirmedComponents || res.data.result.verdict.hasReplacedComponents) {
                let adv = { ...addressValidationBody }
                if (res.data.result.address.addressComponents) {
                    res.data.result.address.addressComponents.map((i) => {
                        if (i.confirmationLevel !== 'CONFIRMED' || i.spellCorrected || i.replaced) {
                            const change = () => {
                                return new Promise(resolve => {
                                    if (i.componentType === 'street_number') {
                                        adv.number = "Number of the house";
                                    }
                                    if (i.componentType === 'route') {
                                        adv.street = "Street";
                                    }
                                    if (i.componentType === 'locality') {
                                        adv.city = "City";
                                    }
                                    if (i.componentType === 'administrative_area_level_1') {
                                        adv.state = "State";
                                    }
                                    if (i.componentType === 'postal_code') {
                                        adv.zip = "Zip Code";
                                    }
                                    setAddressValidation(adv);
                                    resolve(true);
                                })
                            }
                            async function proceed() {
                                const result = await change();
                                setOpen(true);
                            }
                            proceed();

                        }
                    });
                }

            } else {
                sendForm();
                // validateLicense();
            }

        });
    }

    const validateLicense = () => {
        if (licenseImage === null) {
            let alertData = { ...alert };
            alertData.show = true;
            alertData.severity = 'error';
            alertData.message = 'You need to upload a photo of the license or ID to continue';
            setAlert(alertData);
            uploadLicense.current.scrollIntoView();
        }
        else {
            sendForm();
        }
    }

    const sendForm = () => {
        setLoading(true);
        // const imageData = new FormData();
        // imageData.append("image", licenseImage, fileName.name);

        // const uploadImage = () => {
        //     return new Promise((resolve) => {
        //         Axios.post(
        //             `${props.host}/customer/uploadIdImage`,
        //             imageData,
        //             { headers: { Authorization: `Bearer ${props.token}` } }
        //         )
        //             .then((res) => {
        //                 resolve({ imageName: res.data });
        //             })
        //             .catch((error) => {
        //                 if (error) {
        //                     resolve(false);
        //                     let alertData = { ...alert };
        //                     alertData.show = true;
        //                     alertData.severity = 'error';
        //                     alertData.message = error.response.data;
        //                     setAlert(alertData);
        //                     setLoading(false);
        //                 }
        //             });
        //     });
        // }
        async function uploadImageResult() {
            delete dataForm.ssn;
            // const result = await uploadImage();
            // if (result) {
            Axios.post(
                `${props.host}/application/addCoap`,
                {
                    customer: dataForm,
                    // signature,
                    app_id: props.match.params.app_id
                    // licenseImage: licenseImage,
                    // imageName: fileName.name
                },
                { headers: { Authorization: `Bearer ${props.token}` } }
            ).then((res) => {
                setConfirmation({ ...confirmation, app_id: props.match.params.app_id, show: true });
                setLoading(false);
            }).catch((error) => {
                if (error) {
                    let alertData = { ...alert };
                    alertData.show = true;
                    alertData.severity = 'error';
                    alertData.message = error.response.data;
                    setAlert(alertData);
                    setLoading(false);
                }
            });
            setOpen(false);
            // }
        }
        uploadImageResult();
    }

    //Randomize Image name
    useEffect(() => {
        if (!isNull(licenseImage)) {
            let rand = function () {
                return Math.random().toString(36).substr(2); // remove `0.`
            };

            let token = function () {
                return rand() + rand() + "-" + rand() + rand(); // to make it longer
            };

            const fname = token() + '.' + licenseImage.name.split('.').pop();
            setFileName({ ...licenseImage, name: fname });
        }

    }, [licenseImage]);

    const validateCoapExists = () => {
        Axios.get(
            `${props.host}/application/validateCoapExists?app_id=${props.match.params.app_id}`,
            { headers: { Authorization: `Bearer ${props.token}` } }
        )
            .then()
            .catch((error) => {
                if (error) {
                    let alertData = { ...alert };
                    alertData.show = true;
                    alertData.severity = 'error';
                    alertData.message = error.response.data;
                    setAlert(alertData);
                    setError(true);
                }
            });

    }

    useEffect(() => {
        Axios.get(
            `${props.host}/application/validateAppPermission?app_id=${props.match.params.app_id}`,
            { headers: { Authorization: `Bearer ${props.token}` } }
        )
            .then(validateCoapExists)
            .catch((error) => {
                if (error) {
                    let alertData = { ...alert };
                    alertData.show = true;
                    alertData.severity = 'error';
                    alertData.message = error.response.data;
                    setAlert(alertData);
                    setError(true);
                }
            });
    }, []);

    useEffect(() => {
        Axios.get(
            `${props.host}/application/read?app_id=${props.match.params.app_id}`,
            { headers: { Authorization: `Bearer ${props.token}` } }
        )
            .then((res) => {
                setAppInfo(res.data.applicant);
                setDataForm({ ...dataForm, address: res.data.applicant.contact_address, city: res.data.applicant.contact_city, state: res.data.applicant.contact_state, zip: String(res.data.applicant.contact_zip) });

            })
            .catch((error) => {
                if (error) {
                    let alertData = { ...alert };
                    alertData.show = true;
                    alertData.severity = 'error';
                    alertData.message = error.response.data;
                    setAlert(alertData);
                    setError(true);
                }
            });
    }, []);


    const licenseAndSignatureEnabled = false;

    return (
        <>
            <CreateAppBar title='Add Co-Applicant' back={() => history.push(`/app_details/${props.match.params.app_id}`)} />
            {!error &&
                <div style={{ margin: '1rem' }}>
                    {loading &&
                        <LoadingFullScreen type="bars" color="white" />
                    }
                    <p className="mt-3">Provide the required information, click Continue and let Credit Department know that you have completed this step.</p>

                    <HorizontalStepper activeStep={0} steps={['Request credit Inquiry', 'Send Application Details', 'Request Paperwork']} />

                    <h4 ref={applicantInfo}>Co-Applicant Information</h4>
                    <Paper className="pt-3 pb-3 pl-3 pr-3 mb-3">
                        <form >
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={4} md={3}><TextField inputProps={{ style: { textTransform: 'capitalize' } }} fullWidth={true} error={formError.firstname.error} helperText={formError.firstname.message} name="firstname" onChange={handleForm} id="epwt_firstname" label="First Name" /></Grid>
                                <Grid item xs={12} sm={4} md={3}><TextField inputProps={{ style: { textTransform: 'capitalize' } }} fullWidth={true} error={formError.lastname.error} helperText={formError.lastname.message} name="lastname" onChange={handleForm} id="epwt_lastname" label="Last Name" /></Grid>
                                <Grid item xs={12} sm={4} md={3}>
                                    <ReactInputMask
                                        mask="999/99/9999"
                                        value={dataForm.ssn}
                                        maskChar="_"
                                        onChange={handleForm}
                                    >
                                        {() => <TextField fullWidth={true} error={formError.ssn.error} helperText={formError.ssn.message} inputProps={{ type: 'tel' }} name="ssn" id="epwt_ssn" label="Social Security Number" />}
                                    </ReactInputMask>
                                </Grid>
                                <Grid item xs={12} sm={4} md={3}>
                                    <ReactInputMask
                                        mask="99/99/9999"
                                        value={dataForm.dob}
                                        disabled={false}
                                        maskChar="-"
                                        onChange={handleForm}
                                    >
                                        {() => <TextField fullWidth={true} error={formError.dob.error} helperText={formError.dob.message} inputProps={{ type: 'tel' }} value={dataForm.dob} name="dob" onChange={handleForm} id="epwt_dob" label="Date of Birth" />}
                                    </ReactInputMask>
                                </Grid>
                                <Grid item xs={12} sm={4} md={3}>
                                    <FormControl error={formError.relationship.error} fullWidth={true} >
                                        <InputLabel id="demo-simple-select-label">Relationship with applicant</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            value={dataForm.relationship}
                                            onChange={handleForm}
                                            name="relationship"
                                            id="demo-simple-select"
                                            autoWidth={true}
                                        >
                                            <MenuItem value="1">Spouse</MenuItem>
                                            <MenuItem value="2">Non-Spouse</MenuItem>
                                        </Select>
                                        <FormHelperText>{formError.relationship.message}</FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={4} md={3}>
                                    <TextField disabled fullWidth={true} error={formError.address.error} helperText={formError.address.message} inputRef={inputRef} name="address" onChange={handleForm} value={dataForm.address} id="standard-basic" label="License or ID Address" />
                                </Grid>
                                <Grid item xs={12} sm={4} md={3}>
                                    <TextField disabled fullWidth={true} error={formError.city.error} helperText={formError.city.message} name="city" value={dataForm.city} onChange={handleForm} id="standard-basic" label="City" />
                                </Grid>
                                <Grid item xs={12} sm={4} md={3}>
                                    <FormControl error={formError.state.error} fullWidth={true} >
                                        <InputLabel id="demo-simple-select-label">State</InputLabel>
                                        <Select
                                            disabled
                                            labelId="demo-simple-select-label"
                                            value={dataForm.state}
                                            onChange={handleForm}
                                            name="state"
                                            id="demo-simple-select"
                                            autoWidth={true}
                                        >
                                            <MenuItem value="Alabama">Alabama</MenuItem>
                                            <MenuItem value="Alaska">Alaska</MenuItem>
                                            <MenuItem value="Arizona">Arizona</MenuItem>
                                            <MenuItem value="Arkansas">Arkansas</MenuItem>
                                            <MenuItem value="California">California</MenuItem>
                                            <MenuItem value="Colorado">Colorado</MenuItem>
                                            <MenuItem value="Connecticut">Connecticut</MenuItem>
                                            <MenuItem value="Delaware">Delaware</MenuItem>
                                            <MenuItem value="District of Columbia">District of Columbia</MenuItem>
                                            <MenuItem value="Florida">Florida</MenuItem>
                                            <MenuItem value="Georgia">Georgia</MenuItem>
                                            <MenuItem value="Guam">Guam</MenuItem>
                                            <MenuItem value="Hawaii">Hawaii</MenuItem>
                                            <MenuItem value="Idaho">Idaho</MenuItem>
                                            <MenuItem value="Illinois">Illinois</MenuItem>
                                            <MenuItem value="Indiana">Indiana</MenuItem>
                                            <MenuItem value="Iowa">Iowa</MenuItem>
                                            <MenuItem value="Kansas">Kansas</MenuItem>
                                            <MenuItem value="Kentucky">Kentucky</MenuItem>
                                            <MenuItem value="Louisiana">Louisiana</MenuItem>
                                            <MenuItem value="Maine">Maine</MenuItem>
                                            <MenuItem value="Maryland">Maryland</MenuItem>
                                            <MenuItem value="Massachusetts">Massachusetts</MenuItem>
                                            <MenuItem value="Michigan">Michigan</MenuItem>
                                            <MenuItem value="Minnesota">Minnesota</MenuItem>
                                            <MenuItem value="Mississippi">Mississippi</MenuItem>
                                            <MenuItem value="Missouri">Missouri</MenuItem>
                                            <MenuItem value="Montana">Montana</MenuItem>
                                            <MenuItem value="Nebraska">Nebraska</MenuItem>
                                            <MenuItem value="Nevada">Nevada</MenuItem>
                                            <MenuItem value="New Hampshire">New Hampshire</MenuItem>
                                            <MenuItem value="New Jersey">New Jersey</MenuItem>
                                            <MenuItem value="New Mexico">New Mexico</MenuItem>
                                            <MenuItem value="New York">New York</MenuItem>
                                            <MenuItem value="North Carolina">North Carolina</MenuItem>
                                            <MenuItem value="North Dakota">North Dakota</MenuItem>
                                            <MenuItem value="Ohio">Ohio</MenuItem>
                                            <MenuItem value="Oklahoma">Oklahoma</MenuItem>
                                            <MenuItem value="Oregon">Oregon</MenuItem>
                                            <MenuItem value="Pennsylvania">Pennsylvania</MenuItem>
                                            <MenuItem value="Puerto Rico">Puerto Rico</MenuItem>
                                            <MenuItem value="Rhode Island">Rhode Island</MenuItem>
                                            <MenuItem value="South Carolina">South Carolina</MenuItem>
                                            <MenuItem value="South Dakota">South Dakota</MenuItem>
                                            <MenuItem value="Tennessee">Tennessee</MenuItem>
                                            <MenuItem value="Texas">Texas</MenuItem>
                                            <MenuItem value="Utah">Utah</MenuItem>
                                            <MenuItem value="Vermont">Vermont</MenuItem>
                                            <MenuItem value="Virginia">Virginia</MenuItem>
                                            <MenuItem value="U.S. Virgin Islands">U.S. Virgin Islands</MenuItem>
                                            <MenuItem value="Washington">Washington</MenuItem>
                                            <MenuItem value="West Virginia">West Virginia</MenuItem>
                                            <MenuItem value="Wisconsin">Wisconsin</MenuItem>
                                            <MenuItem value="Wyoming">Wyoming</MenuItem>
                                        </Select>
                                        <FormHelperText>{formError.state.message}</FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={4} md={3}>
                                    <TextField disabled fullWidth={true} error={formError.zip.error} helperText={formError.zip.message} inputProps={{ type: 'number' }} value={dataForm.zip} name="zip" onChange={handleForm} id="standard-basic" label="Zip Code" />
                                </Grid>

                            </Grid>
                        </form>
                    </Paper>
                    <Button onClick={() => validateForm()} className="mt-3 mb-3" variant="contained" color="primary">Continue</Button>
                    {licenseAndSignatureEnabled &&
                        <>
                            <h4 ref={uploadLicense}>Upload License or ID</h4>
                            {!licenseImage &&
                                <>
                                    <Alert className="mb-3" severity="warning">You need to upload a photo of the license or id to ID to continue. It must be an image, PDF or documents are not allowed.</Alert>
                                </>
                            }

                            {licenseImage &&
                                <>
                                    <img className="mb-3" alt="not found" width={"200px"} src={URL.createObjectURL(licenseImage)} /><br />
                                </>

                            }
                            <input
                                accept="image/*"
                                style={{ 'display': 'none' }}
                                id="contained-button-file"
                                multiple
                                type="file"
                                onChange={(event) => {
                                    setLicenseImage(event.target.files[0]);
                                }}
                            />
                            <label htmlFor="contained-button-file">
                                <Button className="mb-3" variant="contained" color="primary" component="span">
                                    Upload
                                </Button>
                            </label>
                            <h4 >Signature</h4>
                            <div className="mb-3">
                                <small style={{ fontSize: '10px' }}>By signing bellow you certify that any information in the application is true and complete. You authorize us to confirm this information and
                                    give out information about you or your account to credit report agencies and others who are allowed to receive it. You authorize and instruct us
                                    to request credit information about you from any credit report agency third party.<br />
                                    Al firmar a continuación, certifica que cualquier información en la solicitud es verdadera y completa. Usted nos autoriza a confirmar esta información y proporcionar información sobre usted o su cuenta a las agencias de informes crediticios y otras personas autorizadas a recibirla. Usted nos autoriza y nos indica que solicitemos información crediticia sobre usted a cualquier agencia de informes crediticios de terceros
                                </small>
                            </div>

                            <ReactSignatureCanvas
                                onEnd={saveSignature}
                                penColor='black'
                                ref={signCanvas}
                                canvasProps={{ width: window.screen.width < 768 ? window.screen.width - 32 : 500, height: 300, className: 'signCanvas mb-3' }}
                            />
                            <br />
                            <Button onClick={clearSignature} className="mb-3" variant="outlined" color="primary">Clear Signature</Button><br />

                            <br />

                            {!signature &&
                                <>
                                    <Alert severity="warning">The request must be signed by Co-Applicant before continue</Alert>
                                    <Button className="mt-3 mb-3" disabled>Continue</Button>
                                </>
                            }
                            {signature &&
                                <Button onClick={() => validateForm()} className="mt-3 mb-3" variant="contained" color="primary">Continue</Button>
                            }
                        </>
                    }
                    <Dialog
                        open={confirmation.show}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="epwt_app_confirmation"
                    >
                        <DialogContent>
                            <DialogContentText id="epwt_app_confirmation" >
                                The Soft Pull Query has been sent to the Applications department.<br /><br />
                                Please contact them to let them know you have completed this step.<br />
                                For future reference the App assigned will be <b>{confirmation.app_id}</b>.<br /><br />
                                You will now be redirected to the Application so you can fill it up.<br /><br />
                                Please click Continue
                            </DialogContentText>
                            <DialogActions>
                                <Button onClick={() => history.push(`/application/${confirmation.app_id}/fill`)} color="primary">
                                    Continue
                                </Button>
                            </DialogActions>
                        </DialogContent>
                    </Dialog>

                    <Dialog
                        open={open}
                        onClose={() => setOpen(false)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"This address is uknown"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                This address appears to be invalid, please check the following fields:
                                {
                                    <ul className="mt-3 mb-3">
                                        <li className="danger">{addressValidation.number}</li>
                                        <li className="danger">{addressValidation.street}</li>
                                        <li className="danger">{addressValidation.city}</li>
                                        <li className="danger">{addressValidation.state}</li>
                                        <li className="danger">{addressValidation.zip}</li>
                                    </ul>
                                }
                                Or click continue if you are shure that the address is correct
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setOpen(false)} color="primary">
                                Close
                            </Button>
                            <Button onClick={() => sendForm()} color="primary" autoFocus>
                                Continue
                            </Button>
                        </DialogActions>
                    </Dialog>
                    {
                        alert.show &&
                        <CustomizedSnackbars severity={alert.severity} message={alert.message} hideAlert={hideAlert} />
                    }



                </div >
            }
            {error &&
                <p>{alert.message}</p>
            }
            <BottomMenu showOnDesktop={true} />
        </>

    )
}

export default AddCoap;