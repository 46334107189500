import React, { useEffect, useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Axios from 'axios';
import Cookies from 'universal-cookie';
import DeviceDetection from '../libs/deviceDetection';
import { FormControl } from '@material-ui/core';
import ReactInputMask from "react-input-mask";
import LoadingBlock from '../assets/loadingBlock';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const cookies = new Cookies();

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function Login(props) {
    //Mains states
    const [username, setUserName] = useState(null);
    const [password, setPassword] = useState(null);
    const [passwordRepeat, setPasswordRepeat] = useState(null);
    const [email, setEmail] = useState(null);
    const [device, setDevice] = useState(null);
    const [unauthorizedDevice, setUnauthorizedDevice] = useState(false);
    const [firstTimeLogin, setFirstTimeLogin] = useState(false);
    const [firstTimeLoginError, setFirstTimeLoginError] = useState(false);
    const [firstTimeLoginErrorMessage, setFirstTimeLoginErrorMessage] = useState(null);
    const history = useHistory();


    //Secondary states
    const [loginIncorrect, setLoginIncorrect] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [code, setCode] = useState(null);
    const [codeError, setCodeError] = useState(false);
    const [codeErrorText, setCodeErrorText] = useState(null);
    const [loading, setLoading] = useState(false);

    const classes = useStyles();

    const handleUsername = (e) => {
        setUserName(e.target.value)
    }
    const handlePassword = (e) => {
        setPassword(e.target.value)
    }
    const loginFromForm = (e) => {
        e.preventDefault();
        login();
    }

    const login = () => {
        setLoading(true);
        Axios.post(
            `${props.host}/login`,
            { user: username, password: password, device: device })
            .then((res) => {
                if (res.status === 201) {
                    setFirstTimeLogin(true);
                    setLoading(false);
                }
                if (res.status === 202) {
                    setUnauthorizedDevice(true);
                    setEmail(res.data.user_email);
                    setLoading(false);
                }
                if (res.status === 200) {
                    console.log(res.data);
                    cookies.set('token', res.data.token, { path: '/' });
                    cookies.set('name', res.data.user_name, { path: '/' });
                    cookies.set('level', res.data.user_level_id, { path: '/' });
                    cookies.set('email', res.data.user_email, { path: '/' });
                    history.push('/')
                }
            })
            .catch((error) => {
                if (error.response) {
                    setErrorMessage(error.response.data);
                    setLoginIncorrect(true);
                    setLoading(false);
                }
            })
    }

    const sendVerificationCode = () => {
        setLoading(true);
        setCodeError(false);
        Axios.post(`${props.host}/login/newDevice`, { email: email, code: code, device: device })
            .then(() => {
                login();
            })
            .catch((error) => {
                {
                    if (error) {
                        setCodeError(true);
                        setCodeErrorText(error.response.data);
                        setLoading(false);
                    }
                }
            });
    }

    const firstTimeLoginSend = () => {
        setLoading(true);
        Axios.post(`${props.host}/login/firstTime`, { user: username, password: password, password_repeat: passwordRepeat, device: device })
            .then(() => {
                setFirstTimeLogin(false);
                login();
            }
            )
            .catch((error) => {
                if (error) {
                    setFirstTimeLoginError(true);
                    setFirstTimeLoginErrorMessage(error.response.data);
                    setLoading(false);
                }
            });
    }

    useEffect(() => {
        setDevice(DeviceDetection());
    }, [])


    loginIncorrect && cookies.remove('token')

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography className='mb-3' style={{ textAlign: 'center' }} component="h1" variant="h5">
                    El paso Water Tech APP
                </Typography>
                {loading &&
                    <LoadingBlock />
                }
                {!unauthorizedDevice && !loading && !firstTimeLogin &&
                    <form className={classes.form} noValidate onSubmit={loginFromForm}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="User"
                            name="username"
                            autoFocus
                            onChange={handleUsername}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            onChange={handlePassword}
                        />
                        {loginIncorrect && <p className="mt-3 mb-0" style={{ color: 'red' }}>{errorMessage}</p>}
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            type='sumbit'
                        >
                            Sign In
                        </Button>

                    </form>
                }
                {unauthorizedDevice && !loading &&
                    <div style={{ textAlign: "center" }}>
                        <p>It seems like you're trying to access from a new device, please enter the 6 digits code we have sent to <b>{email}</b></p>
                        <FormControl>
                            <ReactInputMask
                                mask="999999"
                                value={code}
                                maskChar="-"
                                onChange={(e) => setCode(e.target.value)}
                            >
                                {() => <TextField error={codeError} helperText={codeErrorText} inputProps={{ type: 'tel' }} name="code" id="standard-basic" label="Verification Code" />}
                            </ReactInputMask>
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                onClick={sendVerificationCode}
                            >
                                Confirm
                            </Button>
                        </FormControl>
                    </div>
                }
                {firstTimeLogin && !loading &&
                    <>
                        <p style={{ textAlign: 'center' }}>It seems like this is the first time you will be accessing the system, please set your password</p>
                        <form className={classes.form} noValidate onSubmit={firstTimeLoginSend}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="password_repeat"
                                label="Repeat Password"
                                type="password"
                                id="password_repeat"
                                onChange={(e) => setPasswordRepeat(e.target.value)}
                            />
                            {firstTimeLoginError && <p className="mt-3 mb-0" style={{ color: 'red' }}>{firstTimeLoginErrorMessage}</p>}
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                type='sumbit'
                            >
                                Confirm
                            </Button>

                        </form>
                    </>
                }

            </div>

        </Container>
    );
}