const mysqlToJSDate = (dateToconvert, options) => {
    const months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
    let convertedDate;
    const showTime = options !== undefined && options.showTime === false ? false : true;

    if (showTime === false) {
        convertedDate = `${months[new Date(dateToconvert).getMonth()]}/${new Date(dateToconvert).getDate()}/${new Date(dateToconvert).getFullYear()}`;
    } else {
        convertedDate = `${months[new Date(dateToconvert).getMonth()]}/${new Date(dateToconvert).getDate()}/${new Date(dateToconvert).getFullYear()} ${new Date(dateToconvert).getHours()}:${new Date(dateToconvert).getMinutes()}${new Date(dateToconvert).getMinutes() === 0 ? '0' : ''}`;
    }
    return convertedDate;
}

export default mysqlToJSDate;