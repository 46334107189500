import React from "react";
import ReactLoading from "react-loading";
import { makeStyles } from "@material-ui/core";

const questionStyle = {
    background: "#265c80",
    color: "white",
    padding: "0.5rem",
    borderRadius: "5px"
}

const BlueTitleBox = (props) => {
    return (
        <p style={questionStyle}>{props.text}</p>
    );
}
const LoadingFullScreen = (props) => {
    return (
        <div style={{ position: 'fixed', width: '100%', height: '100%', background: 'rgb(0 0 0 / 25%)', top: 0, left: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: '1' }}>
            <ReactLoading type={props.type} color={props.color} />
        </div>

    );

}
const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
}));

const stepActiveToggle = {
    active: {
        opacity: '1',
        pointerEvents: 'all'
    },
    inactive: {
        opacity: '0.5',
        pointerEvents: 'none'
    }
}
export { BlueTitleBox, LoadingFullScreen, useStyles, stepActiveToggle };