import { React, useState, useCallback, useEffect } from "react";
import { topBarStyle } from "./styles";
import Axios from "axios";
import { NotFoundGeneral } from "../assets/notFound";
import MenuAppBar from "./appBar";
import AppDetailsList from "../applications/appDetailsList";
import ApplicantsTabs from "../applications/applicantsTabs";
import AppHistory from "./appHistory";
import ChangeStatusDialog from "./changeStatusDialog";
import { afiWithoutCoapplicant, afiBothApplicants } from "./financeAgenciesScripts";
import { DialogNoButtons } from '../assets/dialogNoButtons';
import { Button } from '@material-ui/core';
import AppBarSkeleton from "../assets/appBarSkeleton";
import AppDetailsListSkeleton from "../assets/appDetailsListSkeleton";
import AssignInstallationDialog from "./assignInstallationDialog";
import CustomAlert from '../assets/customAlert';
import InstallationReport from "../installations/installationReport";

const AppDetails = (props) => {

    //App id
    const appId = props.match.params.app_id;

    //Main states
    const [applicant, setApplicant] = useState(false);
    const [coapplicant, setCoapplicant] = useState(false);
    const [products, setProducts] = useState(false);
    const [afiDialog, setAfiDialog] = useState(false);
    const [installationPath, setInstallationPath] = useState(props.match.path === '/installation_details/:app_id' ? true : false);

    const [activeTab, setActiveTab] = useState(1);
    const [currentApplicant, setCurrentApplicant] = useState(false);
    const [appHistory, setAppHistory] = useState(false);
    const [statusDialog, setStatusDialog] = useState({ show: false, value: null });
    const [assignInstallationDialog, setAssignInstallationDialog] = useState({ show: false, value: null });

    //Secondary
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [customAlert, setCustomAlert] = useState(false);

    //Load App Data
    const FetchData = useCallback((activeTab) => {
        Axios.get(`${props.host}/application/read?app_id=${appId}`, { headers: { Authorization: `Bearer ${props.token}` } })
            .then((res) => {
                setApplicant(res.data.applicant);
                setCoapplicant(res.data.coapplicant);
                setProducts(res.data.products);
                activeTab === undefined && setCurrentApplicant(res.data.applicant);
                activeTab === 1 && setCurrentApplicant(res.data.applicant);
                activeTab === 2 && setCurrentApplicant(res.data.coapplicant);
            }).finally(res => {
                installationPath && getInstallationHistory();
                !installationPath && getHistory();
            })
            .catch((error) => {
                error && setError(error.response.data);
                setLoading(false);
            });
    }, []);

    const getHistory = () => {
        Axios.get(`${props.host}/application/getHistory?app_id=${appId}`, { headers: { Authorization: `Bearer ${props.token}` } })
            .then((res) => {
                setAppHistory(res.data);
            })
            .finally(res => setLoading(false))
            .catch((error) => {
                error && setError(error.response.data);
                setLoading(false);
            });
    }

    const getInstallationHistory = () => {
        Axios.get(`${props.host}/application/getInstallationHistory?app_id=${appId}`, { headers: { Authorization: `Bearer ${props.token}` } })
            .then((res) => {
                setAppHistory(res.data);
            })
            .finally(res => setLoading(false))
            .catch((error) => {
                error && setError(error.response.data);
                setLoading(false);
            });
    }

    useEffect(() => {
        FetchData();
    }, []);


    //Handlers
    const handleStatusDialog = () => {
        setStatusDialog({ show: true, value: applicant.status_id });
    }
    const handleAssignInstallationDialog = () => {
        applicant.status_id === 1 && setCustomAlert({ title: 'Error', severity: 'error', message1: "Can't assign installation for this App, the App is not filled yet" });
        applicant.status_id > 1 && setAssignInstallationDialog({ show: true, value: applicant.status_id });
    }

    //Set current Applicant
    useEffect(() => {
        activeTab == 1 && setCurrentApplicant(applicant);
        activeTab == 2 && setCurrentApplicant(coapplicant);
    }, [activeTab])

    const closeAlert = useCallback(() => {
        setCustomAlert(false);
    }, []);

    useEffect(() => {
        props.match.path === '/installation_details/:app_id' && setInstallationPath(true);
        props.match.path === '/installation_details/:app_id' && applicant.installation_status_id === null && setError('There are no installations available for this application')
    }, [props.match, applicant]);


    return (
        <>

            {loading &&
                <>
                    <AppBarSkeleton />
                    <AppDetailsListSkeleton />
                </>
            }

            {
                customAlert &&
                <CustomAlert
                    title={customAlert.title}
                    severity={customAlert.severity}
                    message1={customAlert.message1}
                    closeAlert={closeAlert}
                />
            }

            {afiDialog &&
                <DialogNoButtons title="Multiple Applicants found">
                    <p>This application has 2 applicants, choose one of the following options to generate the AFI script</p>
                    <Button onClick={() => afiWithoutCoapplicant(appId, 'Applicant', props.host, props.token)} variant='outlined' color='primary'>{`${applicant.firstname} ${applicant.lastname}`}</Button><br /><br />
                    <Button onClick={() => afiWithoutCoapplicant(appId, 'Coapplicant', props.host, props.token)} variant='outlined' color='primary'>{`${coapplicant.firstname} ${coapplicant.lastname}`}</Button><br /><br />
                    <Button onClick={() => afiBothApplicants(appId, props.host, props.token)} variant='outlined' color='primary'>Both</Button><br /><br />
                    <Button onClick={() => setAfiDialog(false)} variant='outlined' color='secondary'>Cancel</Button>
                </DialogNoButtons>
            }

            {statusDialog.show &&
                <ChangeStatusDialog
                    open={true}
                    statusId={applicant.status_id}
                    close={() => setStatusDialog({ ...statusDialog, show: false, value: null })}
                    host={props.host}
                    token={props.token}
                    app_id={appId}
                    refreshList={() => FetchData()}
                    ignoreUpdates={applicant.ignore_agency_updates}
                />
            }

            {assignInstallationDialog.show &&
                <AssignInstallationDialog
                    open={true}
                    statusId={applicant.status_id}
                    close={() => setAssignInstallationDialog({ ...assignInstallationDialog, show: false, value: null })}
                    data={applicant}
                    products={products}
                    host={props.host}
                    token={props.token}
                    app_id={appId}
                    refreshList={() => FetchData()} />
            }

            {!loading && applicant &&
                <div style={topBarStyle}>
                    <MenuAppBar
                        appId={appId}
                        applicant={currentApplicant}
                        coApplicant={coapplicant}
                        setStatusDialog={handleStatusDialog}
                        setAssignInstallationDialog={handleAssignInstallationDialog}
                        host={props.host}
                        token={props.token}
                        FetchData={FetchData}
                        activeTab={activeTab}
                        setAfiDialog={setAfiDialog}
                        afiWithoutCoapplicant={afiWithoutCoapplicant}
                        installationPath={installationPath}
                        refreshList={() => FetchData()}
                    />
                    <ApplicantsTabs applicant={applicant} coApplicant={coapplicant} setActiveTab={setActiveTab} installationPath={installationPath} />
                </div>
            }
            <div style={{ height: 150 + 48 }}></div>

            {!loading && error &&
                <NotFoundGeneral message={error} height={198} />
            }

            {activeTab === 1 && !loading && !error && applicant &&
                <AppDetailsList appId={appId} applicant={applicant} products={products} host={props.host} token={props.token} loading={loading} FetchData={FetchData} activeTab={activeTab} installationPath={installationPath} />
            }
            {activeTab === 2 && !loading && !error && applicant &&
                <AppDetailsList appId={appId} applicant={coapplicant} products={products} host={props.host} token={props.token} loading={loading} FetchData={FetchData} activeTab={activeTab} installationPath={installationPath} />
            }
            {activeTab === 3 && !loading && !error && applicant &&
                <AppHistory history={appHistory} />
            }
            {activeTab === 4 && !loading && !error && applicant &&
                <InstallationReport />
            }

        </>
    )
}
export default AppDetails;