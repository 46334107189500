const processFieldErrorMessage = (e, thisField, formError) => {
    let errorData = { ...formError };
    if (thisField.ok) {
        errorData[e.target.name].error = false;
        errorData[e.target.name].message = thisField.message;
    } else {
        errorData[e.target.name].error = true;
        errorData[e.target.name].message = thisField.message;
    }
    return errorData;
}
export default processFieldErrorMessage;