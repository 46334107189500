import React from "react";
const divStyle = {
    padding: '0.5rem'
}
const h1Style = {
    margin: '0 0 10px 0'
}
const pStyle = {
    margin: '0 0 10px 0'
}
const HeaderTitle = ({ title, secondary }) => {
    return (
        <div style={divStyle} className="header-title">
            <h1 style={h1Style}> {title}</h1 >
            {secondary &&
                <p style={pStyle}>{secondary}</p>
            }

        </div>

    )
}
export default HeaderTitle;