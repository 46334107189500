import React from "react";
import Fab from '@material-ui/core/Fab';

const FloatingButton = ({ icon, onClickAction }) => {
    const style = {
        position: 'fixed',
        bottom: '0',
        right: '0',
        margin: '1rem',
        zIndex: '5'
    }
    return (
        <div style={style}>
            <Fab onClick={onClickAction} className="mb-1" size='medium' color="primary" aria-label="add">
                {icon}
            </Fab>
        </div >

    )
}

export default FloatingButton;

