import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button } from '@material-ui/core';
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles((theme) => ({
    deleteProductButton: {
        color: '#d20000'
    },

    insideTitle: {
        margin: '1rem 16px 1rem 16px'
    },
    insideSubTitle: {
        margin: '0 16px 0.5rem 16px'
    },
    addProductButton: {
        width: '100%',
    },
    imageGallery: {
        margin: '16px'
    },
    imageBox: {
        border: 'solid 1px #dfdfdf',
        borderRadius: '5px',
        position: 'relative',
        margin: '16px'
    },
    image: {
        width: '100%',
        borderTopLeftRadius: '5px',
        borderTopRightRadius: '5px'
    }
}));
export default function InstallationVouchers({ products, vouchersPictures, handleAddVoucherPictures, handleRemoveVoucherPicture }) {
    const classes = useStyles();

    return (
        <>
            {products.length > 0 &&
                <>
                    <p className={classes.insideSubTitle}>Upload the picture of every voucher for every extras you bought</p>
                    <Grid container>
                        <Grid item xs={12}>
                            <div className={classes.imageGallery}>
                                <input
                                    accept="image/*"
                                    style={{ 'display': 'none' }}
                                    id="upload-voucher"
                                    multiple
                                    type="file"
                                    onChange={(event) => {
                                        handleAddVoucherPictures(event.target.files);
                                    }}
                                />
                                <label htmlFor="upload-voucher">
                                    <Button className="mb-3" variant="contained" color="secondary" component="span" startIcon={<AddCircleOutlineRoundedIcon />}>
                                        Add Voucher
                                    </Button>
                                </label>
                            </div>
                        </Grid>

                        {vouchersPictures.length > 0 &&
                            vouchersPictures.map((object, i) => (
                                <Grid item xs={12} sm={4} md={3} lg={2} xl={2} >
                                    <div className={classes.imageBox}>
                                        <img className={classes.image} alt="not found" width={"200px"} src={URL.createObjectURL(object)} />
                                        <Button
                                            color="secondary"
                                            className={classes.button}
                                            startIcon={<DeleteIcon />}
                                            size="small"
                                            onClick={() => handleRemoveVoucherPicture(i)}
                                        >
                                            Remove
                                        </Button>

                                    </div>

                                </Grid>
                            ))
                        }


                    </Grid>
                </>
            }
        </>
    )
}