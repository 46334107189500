import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);


const useStyles = makeStyles({
    table: {
        minWidth: 700
    },
});

export default function CustomTable({ title, extras }) {
    const classes = useStyles();

    return (
        <div style={{ margin: '16px' }}>
            {title && <h4>Extras</h4>}
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            {extras.map((object, i) => {
                                const capitalized = Object.keys(object)[i].charAt(0).toUpperCase() + Object.keys(object)[i].slice(1);
                                return (
                                    <StyledTableCell>{capitalized}</StyledTableCell>
                                )
                            }
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <StyledTableRow key="s">
                            <StyledTableCell component="th" scope="row">
                                ljkhljkh
                            </StyledTableCell>
                        </StyledTableRow>

                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}
