const jsToMysqlDate = (dateToConvert, options) => {
    const date = new Date(dateToConvert);
    const months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
    const day = date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;
    const hours = date.getHours() > 9 ? date.getHours() : `0${date.getHours()}`;
    const minutes = date.getMinutes() > 9 ? date.getMinutes() : `0${date.getMinutes()}`;
    let convertedDate;

    const showTime = options !== undefined && options.showTime === false ? false : true;

    if (showTime === false) {
        convertedDate = `${date.getFullYear()}-${months[date.getMonth()]}-${day}`;
    } else {
        convertedDate = `${date.getFullYear()}-${months[date.getMonth()]}-${day} ${hours}:${minutes}:00`;
    }

    return convertedDate;
}
export default jsToMysqlDate;