import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';


import { useStylesAppbar } from '../applications/styles';
import { Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';


const AppBarSkeleton = () => {
    const classes = useStylesAppbar();

    return (

        <>
            <div style={{ height: 198 }}></div>
            <div className={classes.root} style={{ position: 'fixed', zIndex: 99, top: 0, width: '100%' }}>
                <AppBar className={classes.toolbar} elevation={0} position='relative'>
                    <Toolbar>

                        <div style={{ position: 'relative' }} className={classes.avatarBox}>
                            <Skeleton variant='circle' width={70} height={70} />

                            <div className={classes.scoreContainer}>
                                <Skeleton className={classes.star} variant='text' height={20} width={30} />
                            </div>

                        </div>

                        <div style={{ flexGrow: 1 }} className={classes.rightFlex}>
                            <Skeleton variant='text' height={30} width={130} className={classes.title} />

                            <Skeleton className={classes.titleSecondary} variant='text' height={30} width={100} />
                            <Skeleton className={classes.status} variant='text' height={30} width={50} />
                        </div>

                        {(
                            <div className={classes.rightFlex}>
                                <IconButton
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    style={{ top: -4, position: 'relative' }}
                                    color="inherit"
                                    edge="end"
                                >
                                    <MoreVertIcon />
                                </IconButton>
                                <Menu
                                    id="menu-appbar"

                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                >

                                    <MenuItem>Set Score</MenuItem>
                                    <MenuItem >Run iSoft</MenuItem>


                                </Menu>
                            </div>
                        )}
                    </Toolbar>
                </AppBar>
                <Grid container style={{ height: 48, alignItems: 'center', justifyContent: 'center', textAlign: 'center', background: '#265c80' }}>
                    <Grid item xs={4}><Skeleton style={{ display: 'block', margin: '0 auto' }} variant='text' height={30} width="50%" /></Grid>
                    <Grid item xs={4}><Skeleton style={{ display: 'block', margin: '0 auto' }} variant='text' height={30} width="50%" /></Grid>
                    <Grid item xs={4}><Skeleton style={{ display: 'block', margin: '0 auto' }} variant='text' height={30} width="50%" /></Grid>
                </Grid>


            </div >
        </>
    );
}
export default AppBarSkeleton;
