import React, { useEffect, useRef, useState } from "react";
import HorizontalStepper from "../stepper/stepper";
import CoapInfo from "./coap/coapInfo";
import Axios from "axios";
import IdInfo from "./coap/idInfo";
import ContactInfo from "./coap/contactInfo";
import ResidenceInfo from "./coap/residenceInfo";
import EmploymentInfo from "./coap/employmentInfo";
import { errors } from "./formsBody";
import { Button } from "@material-ui/core";
import { validateForm } from "./coap/validateForm";
import CustomizedSnackbars from "../alerts/CustomizedSnackbars";
import Confirmation from "./coap/confirmation";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { LoadingFullScreen } from "./assets";
import { AppFillSkeleton } from "../assets/skeletons";
import { NotFoundGeneral } from "../assets/notFound";
import CreateAppBar from "./appBar";
import BottomMenu from "../footer/bottomMenu";

const CoAppFill = (props) => {
    //Main states
    const [coApplicant, setCoapplicant] = useState(false);
    const [coapInfo, setCoapInfo] = useState(null);
    const [idInfo, setidInfo] = useState(null);
    const [contactInfo, setContactInfo] = useState(null);
    const [residenceInfo, setResidenceInfo] = useState(null);
    const [employmentInfo, setEmploymentInfo] = useState(null);

    //Secondary States
    const [loading, setLoading] = useState(true);
    const [loadingError, setLoadingError] = useState(false);
    const [formError, setFormError] = useState(errors);
    const [alert, setAlert] = useState(false);
    const [confirmation, setConfirmation] = useState(false);
    const [modalLoading, setModalLoading] = useState(false);

    //History
    const history = useHistory();

    //Refs
    const applicantInfoRef = useRef();
    const idInfoRef = useRef();
    const contactInformationRef = useRef();
    const residenceRef = useRef();
    const employmentRef = useRef();

    //Handlers
    const handleForm = (name, data) => {
        name === 'coapInfo' && setCoapInfo(data);
        name === 'idInfo' && setidInfo(data);
        name === 'contactInfo' && setContactInfo(data);
        name === 'residenceInfo' && setResidenceInfo(data);
        name === 'employmentInfo' && setEmploymentInfo(data);
    }

    //Validate Form and send
    const validateFormPriorToSending = () => {
        const validatedForm = validateForm(coapInfo, idInfo, contactInfo, residenceInfo, employmentInfo);
        !validatedForm.valid && setFormError({ ...formError, [validatedForm.target]: { error: true, message: validatedForm.errorMessage } });
        !validatedForm.valid && scrollToError(validatedForm.reference, validatedForm.errorMessage);
        validatedForm.valid && sendForm();
    }
    const sendForm = () => {
        setModalLoading(true);
        Axios.post(
            `${props.host}/application/fillCoap`,
            { coapInfo, idInfo, contactInfo, residenceInfo, employmentInfo },
            { headers: { Authorization: `Bearer ${props.token}` } }
        )
            .then(res => setConfirmation(true), setModalLoading(false))
            .catch(error => error && setAlert({ message: error.response.data, severity: 'error' }), setModalLoading(false));
    }

    //Scroll to error
    const scrollToError = (reference, message) => {
        const scroll = reference + '.current.scrollIntoView({ behavior: "smooth" })';
        if (message === undefined) message = 'There are errors in the form';
        else message = message;
        eval(scroll);
        setAlert({ message: message, severity: 'error' });
    }

    //Onload
    useEffect(() => {
        //Validate that we don't have coap information in the application already
        Axios.get(
            `${props.host}/application/validateCoapIsFilled?app_id=${props.match.params.app_id}`,
            { headers: { Authorization: `Bearer ${props.token}` } }
        )
            .then(loadCoapData())
            .catch(error => error && setLoadingError(error.response.data));
    }, []);

    const loadCoapData = () => {
        //Loading Co-applicant information and options
        Axios.get(
            `${props.host}/application/view?app_id=${props.match.params.app_id}&coapplicant`,
            { headers: { Authorization: `Bearer ${props.token}` } }
        )
            .then(res => setCoapplicant(res.data))
            .catch(error => error && setLoadingError(error.response.data));
    }

    //Hide Loader when finished
    useEffect(() => {
        coApplicant && setLoading(false);
        loadingError && setLoading(false);
    }, [coApplicant, loadingError]);

    return (
        <>
            <CreateAppBar title="Fill Co-Applicant" back={() => history.goBack()} />
            {loading &&
                <AppFillSkeleton />
            }
            {loadingError &&
                <NotFoundGeneral message={loadingError} />
            }
            {modalLoading &&
                <LoadingFullScreen />
            }
            {coApplicant && !loadingError &&
                <div style={{ margin: '1rem' }}>
                    <p className="mt-3">Fill up the required information and click Continue to submit it to the Credit Department for verification</p>

                    <HorizontalStepper activeStep={1} steps={['Request credit Inquiry', 'Send Application Details', 'Request Paperwork']} />

                    <h4 ref={applicantInfoRef} className="mt-5">Applicant Information</h4>
                    <CoapInfo data={coApplicant} handleForm={handleForm} formError={formError} setFormError={setFormError} />

                    <h4 ref={idInfoRef} className="mt-5">ID Information</h4>
                    <IdInfo formOptions={coApplicant.options} handleForm={handleForm} formError={formError} setFormError={setFormError} />

                    <h4 ref={contactInformationRef} className="mt-5" >Contact Info</h4>
                    <ContactInfo formOptions={coApplicant.options} handleForm={handleForm} formError={formError} setFormError={setFormError} />

                    <h4 ref={residenceRef} className="mt-5">Residence</h4>
                    <ResidenceInfo formOptions={coApplicant.options} handleForm={handleForm} formError={formError} setFormError={setFormError} />

                    <h4 ref={employmentRef} className="mt-5">Employment</h4>
                    <EmploymentInfo formOptions={coApplicant.options} handleForm={handleForm} formError={formError} setFormError={setFormError} />

                    <Button onClick={validateFormPriorToSending} className="mt-3 mb-3" variant="contained" color="primary">Continue</Button>
                </div>
            }
            {alert &&
                <CustomizedSnackbars severity={alert.severity} message={alert.message} hideAlert={() => setAlert(false)} />
            }
            {confirmation &&
                <Confirmation show={true} onClickContinue={() => history.push('/app_details/' + props.match.params.app_id)}>
                    <h2 style={{ color: 'green' }}>Application Sent</h2>
                    The Application was sent to the Applications department. They will now submit it to our finance agencies.<br /><br />
                    Please contact Apps department to let them know you have completed this step.<br /><br />
                    For future reference the App assigned will be <b>{props.match.params.app_id}</b>.<br /><br />
                    {/* You will be redirected to the paperwork request step, or you can request it later if you don't need it right now.<br /><br />
                    Please click Continue */}
                </Confirmation>}

            <BottomMenu showOnDesktop={true} />
        </>
    )
}
export default CoAppFill;