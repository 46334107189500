import { validateField } from "../errorHandling/fieldValidations";

const fieldsToValidate = (applicantInfo, idInformation, contactInfo, residenceInfo, employmentInfo) => {
    return [
        ["firstname", applicantInfo.firstname, "applicantInfoRef"],
        ["lastname", applicantInfo.lastname, "applicantInfoRef"],
        // ["ssn", applicantInfo.ssn, "applicantInfoRef"],
        ["dob", applicantInfo.dob, "applicantInfoRef"],
        ["idType", idInformation.idType, "idInfoRef"],
        ["stateId", idInformation.stateId, "idInfoRef"],
        ["stateId", idInformation.stateId, "idInfoRef"],
        ["expDate", idInformation.expDate, "idInfoRef"],
        ["phone", contactInfo.phone, "contactInformationRef"],
        ["phoneType", contactInfo.phoneType, "contactInformationRef"],
        ["residenceType", residenceInfo.residenceType, "residenceRef"],
        ["residenceMortgage", residenceInfo.residenceMortgage, "residenceRef"],
        ["residenceYearsOfResidence", residenceInfo.residenceYearsOfResidence, "residenceRef"],
        ["residenceMonthsOfResidence", residenceInfo.residenceMonthsOfResidence, "residenceRef"],
        ["employmentType", employmentInfo.employmentType, "employmentRef"],
        ["employmentEmployer", employmentInfo.employmentEmployer, "employmentRef"],
        ["employmentPosition", employmentInfo.employmentPosition, "employmentRef"],
        ["employmentYears", employmentInfo.employmentYears, "employmentRef"],
        ["employmentMonths", employmentInfo.employmentMonths, "employmentRef"],
        ["employmentIncome", employmentInfo.employmentIncome, "employmentRef"]
    ]
}

const validateForm = (coapInfo, idInfo, contactInfo, residenceInfo, employmentInfo) => {
    const fields = fieldsToValidate(coapInfo, idInfo, contactInfo, residenceInfo, employmentInfo);
    let formIsValid = true;
    let target;
    let errorMessage;
    let reference;

    //Validation function running once per field
    const validation = (name, value, ref) => {
        const fieldToValidate = { target: { name: name, value: value } };
        const thisField = validateField(fieldToValidate);
        if (!thisField.ok) {
            target = fieldToValidate.target.name;
            errorMessage = thisField.message;
            reference = ref;
            return false;
        } else {
            return true;
        }
    }
    //Loop trough all of the fields and stop when error is found
    for (let i = 0; i < fields.length; i++) {
        const validate = validation(fields[i][0], fields[i][1], fields[i][2]);
        if (!validate) {
            formIsValid = false;
            break;
        }
    }
    return { valid: formIsValid, target, errorMessage, reference }
}


export { fieldsToValidate, validateForm };