import React, { useState, useEffect } from "react";
import { Grid, Paper, FormControl, InputLabel, Select, MenuItem, TextField } from "@material-ui/core";
import ReactInputMask from "react-input-mask";
import { useStyles } from "../assets";
import { validateField } from "../errorHandling/fieldValidations";
import processFieldErrorMessage from "../errorHandling/processFieldErrorMessage";
import { contactInfoBody } from "./formsBody";

const ContactInfo = ({ formOptions, handleForm, formError, setFormError }) => {
    //Main States
    const [contactInformation, setContactInformation] = useState(contactInfoBody);

    //Handlers
    const handleContactInformation = (e) => {
        const thisField = validateField(e);
        const errorData = processFieldErrorMessage(e, thisField, formError);
        setFormError(errorData);
        setContactInformation({ ...contactInformation, [e.target.name]: e.target.value });
    }

    //Custom Hooks
    const classes = useStyles();

    //Return data for form
    useEffect(() => {
        handleForm('contactInfo', contactInformation);
    }, [contactInformation]);

    return (
        <>
            <Paper className="pt-3 pb-3 pl-3 pr-3 mb-3">
                <form>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={4} md={3}>
                            <ReactInputMask
                                mask="(999)999-9999"
                                maskChar="-"
                                onChange={handleContactInformation}
                            >
                                {() => <TextField fullWidth={true} error={formError.phone.error} helperText={formError.phone.message} inputProps={{ type: 'tel' }} name="phone" id="phone" label="Phone Number" />}
                            </ReactInputMask>
                        </Grid>
                        <Grid item xs={12} sm={4} md={3}>
                            <FormControl className={classes.formControl} fullWidth={true}>
                                <InputLabel id="demo-simple-select-label">Phone Type</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={contactInformation.phoneType}
                                    onChange={handleContactInformation}
                                    name="phoneType"
                                    error={formError.phoneType.error}
                                >
                                    {
                                        formOptions.config_phone_types.map((type) => (
                                            <MenuItem key={type.phone_type_name} value={type.phone_type_id}>{type.phone_type_name}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    {/* <TextField fullWidth={true} style={{ display: 'none' }} error={formError.email.error} helperText={formError.email.message} inputProps={{ type: 'email' }} value={contactInformation.email} name="email" onChange={handleContactInformation} id="email" label="Email" /> */}
                </form>
            </Paper>
        </>
    )
}
export default ContactInfo;