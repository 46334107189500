import React from "react";
import { Dialog, DialogContent, DialogContentText, DialogActions, Button } from "@material-ui/core";

const Confirmation = (props) => {
    return (
        <Dialog
            open={props.show}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {props.children}
                </DialogContentText>
                <DialogActions>
                    <Button onClick={props.onClickContinue} color="primary">
                        Continue
                    </Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    )
}

export default Confirmation;