import React, { useState, createContext, useEffect } from 'react';
import './App.css';
import Header from './components/header/header';
import Applications from './components/applications/applications';
import AppCreate from './components/application/appCreate';
import AppFill from './components/application/appFill';
import AddCoap from './components/application/addCoap';
import Installations from './components/installations/installations';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import Login from './components/login/login';
import Cookies from 'universal-cookie';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import ScrollToTop from './components/assets/scrollToTop';
import CoAppFill from './components/application/coapFill';
import { spanish } from './components/languages/es';
import { english } from './components/languages/en';
import AppDetails from './components/applications/appDetails';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { TransitionGroup, CSSTransition, SwitchTransition } from "react-transition-group";
import MyInfo from './components/myInfo/myInfo';

const theme = createTheme({
  palette: {
    primary: {
      main: '#245c80'
    },
    secondary: {
      main: '#419cd4'
    },
  },
  overrides: {
    // Style sheet name ⚛️
    MuiButton: {
      // Name of the rule
      containedPrimary: {
        // Some CSS
        backgroundColor: '#245c80',
        '&:hover': {
          backgroundColor: '#0b3752'
        }
      },
    },
  },
});

export const OptionsContext = createContext();
export const LanguageContext = createContext();

function App() {
  const cookies = new Cookies();
  const token = cookies.get('token');
  const userLevelId = cookies.get('level');
  const host = "https://erp.biowater.mx/epwt";
  const [storeForInvoice, setStorerForInvoice] = useState(null);
  const [options, setOptions] = useState({
    'showAgencyApproved': false,
    'ignorePendingInstallations': false
  });
  const [language, setLanguage] = useState(english);
  const location = useLocation();

  const handleStoreOrderForInvoice = (storeUrl) => {
    setStorerForInvoice(storeUrl);
  }

  return (
    <OptionsContext.Provider value={[options, setOptions]} language={[language, setLanguage]} >
      <ThemeProvider theme={theme}>
        <div className="container" style={{ maxWidth: '100%', padding: '0' }}>
          <div className="row">
            <div className="col-12" style={{ padding: '0' }}>
              <ScrollToTop />

              {/* <SwitchTransition>
                <CSSTransition classNames='fade' timeout={1000} key={location.pathname}> */}
              <Switch>
                {!token &&
                  <Route path="/" >
                    <Login host={host} />
                  </Route>

                }
                {token &&
                  <>
                    {/* <Header /> */}
                    <Route path="/" exact>
                      {userLevelId === '2' &&
                        <Installations token={token} host={host} />
                      }
                      {(userLevelId === '1' || userLevelId > 10) &&
                        < Applications token={token} host={host} />
                      }

                    </Route>
                    <Route path="/applications" exact>
                      <Applications token={token} host={host} />
                    </Route>
                    <Route path="/installations" exact>
                      <Installations token={token} host={host} />
                    </Route>
                    <Route path="/installations/filter_by_date/from/:from_date/to/:to_date" render={(props) => <Installations {...props} host={host} token={token} />} exact>
                    </Route>
                    <Route path="/applications/filter_by_date/from/:from_date/to/:to_date" render={(props) => <Applications {...props} host={host} token={token} />}>
                    </Route>
                    <Route path="/inquiry/new">
                      <AppCreate token={token} host={host} />
                    </Route>
                    <Route path="/inquiry/:app_id/add_coap" render={(props) => <AddCoap {...props} host={host} token={token} />}>
                    </Route>

                    <Route path="/application/:app_id/fill" render={(props) => <AppFill {...props} host={host} token={token} />}>
                    </Route>

                    <Route path="/application/:app_id/fill_coap" render={(props) => <CoAppFill {...props} host={host} token={token} />}>
                    </Route>

                    <Route path="/app_details/:app_id" render={(props) => <AppDetails {...props} host={host} token={token} />}>
                    </Route>

                    <Route path="/installation_details/:app_id" render={(props) => <AppDetails {...props} host={host} token={token} />}>
                    </Route>

                    <Route path="/my_info">
                      <MyInfo token={token} host={host} />
                    </Route>




                    {/* <Route path="/" exact>
                        <Ordenes token={token} host={host} setStoreForInvoice={handleStoreOrderForInvoice} />
                      </Route>
                      <Route path="/distribuidores" >
                        <Distribuidores token={token} host={host} />
                      </Route>
                      <Route path="/productos/catalogo/producto/:product_id" render={(props) => <Producto {...props} token={token} host={host} />} />
                      <Route path="/ordenes" exact>
                        <Ordenes token={token} host={host} setStoreForInvoice={handleStoreOrderForInvoice} />
                      </Route>
                     
                      <Route path="/productos/inventario" render={(props) => <Inventario {...props} token={token} host={host} />} />
                      <Route path="/orden/:order_id" render={(props) => <Orden {...props} host={host} />}>
                      </Route>
                      <Route path="/productos/lista_precios" render={(props) => <ListaPrecios {...props} token={token} host={host} />} exact />
                      <Route path="/productos/catalogo" exact>
                        <Productos token={token} host={host} />
                      </Route>
                      <Route path="/ml_store_order" component={MercadolibreStoreOrder} />
                      <Route path="/reportes" render={(props) => <Reportes {...props} host={host} token={token} />} />
                      <Route path="/facturacion/clientes">
                        <ClientesFacturacion host={host} token={token} />
                      </Route>
                      <Route path="/facturacion/facturas" exact>
                        <Facturas token={token} host={host} />
                      </Route>
                      <Route path="/facturacion/facturar/:order_id" render={(props) => <Facturar {...props} host={host} token={token} storeUrl={storeForInvoice} />}>
                      </Route> */}
                  </>
                }
              </Switch>
              {/* </CSSTransition>
              </SwitchTransition> */}

            </div>
          </div>
        </div>

      </ThemeProvider>
    </OptionsContext.Provider>



  );
}

export default App;
