import React from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

export default function SwitchWithLabel({ checked, label, handleSwitch }) {

    return (
        <FormGroup row>
            <FormControlLabel
                control={<Switch checked={checked} onChange={handleSwitch} name="checkedA" />}
                label={label}
            />
        </FormGroup>
    );
}