import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { Grid } from '@material-ui/core';
import GridItem from '../applications/appDetailsListItem';
import { Skeleton } from '@material-ui/lab';


const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '100%',
        marginBottom: 56,
        backgroundColor: theme.palette.background.paper,
        padding: '1rem'
    },
    avatar: {
        alignSelf: 'flex-start',
        marginTop: 8
    }
}));

export default function AppDetailsListSkeleton() {
    const classes = useStyles();

    return (
        <List className={classes.root} >
            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>

                    <Skeleton className='mb-2' variant='rect' width='100%' height={180} />

                    <GridItem avatar={<Skeleton variant='circle' width={40} height={40} />}>
                        <Skeleton variant='text' width="100%" />
                        <Skeleton variant='text' width="100%" height={50} />
                        <Skeleton variant='text' width="100%" height={50} />
                        <Skeleton variant='text' width="100%" height={50} />
                        <Skeleton variant='text' width="100%" height={50} />
                    </GridItem>

                    <GridItem avatar={<Skeleton variant='circle' width={40} height={40} />}>
                        <Skeleton variant='text' width="100%" />
                        <Skeleton variant='text' width="100%" height={50} />
                        <Skeleton variant='text' width="100%" height={50} />
                        <Skeleton variant='text' width="100%" height={50} />
                        <Skeleton variant='text' width="100%" height={50} />
                    </GridItem>
                </Grid>


                <Grid item xs={12} md={4}>
                    <GridItem avatar={<Skeleton variant='circle' width={40} height={40} />}>
                        <Skeleton variant='text' width="100%" />
                        <Skeleton variant='text' width="100%" height={50} />
                        <Skeleton variant='text' width="100%" height={50} />
                    </GridItem>
                    <GridItem avatar={<Skeleton variant='circle' width={40} height={40} />}>
                        <Skeleton variant='text' width="100%" />
                        <Skeleton variant='text' width="100%" height={50} />
                        <Skeleton variant='text' width="100%" height={50} />
                    </GridItem>
                    <GridItem avatar={<Skeleton variant='circle' width={40} height={40} />}>
                        <Skeleton variant='text' width="100%" />
                        <Skeleton variant='text' width="100%" height={50} />
                        <Skeleton variant='text' width="100%" height={50} />
                        <Skeleton variant='text' width="100%" height={50} />
                        <Skeleton variant='text' width="100%" height={50} />
                        <Skeleton variant='text' width="100%" height={50} />
                        <Skeleton variant='text' width="100%" height={50} />
                    </GridItem>
                </Grid>

                <Grid item xs={12} md={4}>
                    <GridItem avatar={<Skeleton variant='circle' width={40} height={40} />}>
                        <Skeleton variant='text' width="100%" />
                        <Skeleton variant='text' width="100%" height={50} />
                        <Skeleton variant='text' width="100%" height={50} />
                        <Skeleton variant='text' width="100%" height={50} />
                        <Skeleton variant='text' width="100%" height={50} />
                    </GridItem>
                    <GridItem avatar={<Skeleton variant='circle' width={40} height={40} />}>
                        <Skeleton variant='text' width="100%" />
                        <Skeleton variant='text' width="100%" height={50} />
                    </GridItem>
                </Grid>
            </Grid>
        </List >
    );
}
