import { React, useCallback, useEffect, useRef, useState } from "react";
import { Grid, Paper, TextField, FormControl, InputLabel, Select, MenuItem, FormHelperText } from "@material-ui/core";
import { useStyles } from "../assets";
import ReactInputMask from "react-input-mask";
import { validateField } from "../errorHandling/fieldValidations";
import processFieldErrorMessage from "../errorHandling/processFieldErrorMessage";
import usaStates from "../../data/usaStates";

const CoapInfo = ({ handleForm, formError, setFormError, data }) => {
    //Main states
    const [applicantInfo, setApplicantInfo] = useState(data);

    //Custom Hooks
    const classes = useStyles();

    //handlers
    const handleApplicantInfo = (e) => {
        const thisField = validateField(e);
        const errorData = processFieldErrorMessage(e, thisField, formError);
        setFormError(errorData);
        setApplicantInfo({ ...applicantInfo, [e.target.name]: e.target.value });
    }
    //Return data for form
    useEffect(() => {
        const data = {
            app_id: applicantInfo.app_id,
            customer_id: applicantInfo.customer_id,
            firstname: applicantInfo.firstname,
            lastname: applicantInfo.lastname,
            // ssn: applicantInfo.ssn,
            dob: applicantInfo.dob
        }
        handleForm('coapInfo', data);
    }, [applicantInfo]);


    return (
        <>
            {applicantInfo &&
                <>
                    <Paper className="pt-3 pb-3 pl-3 pr-3 mb-3">
                        <form>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={4} md={3}><TextField fullWidth={true} error={formError.firstname.error} helperText={formError.firstname.message} name="firstname" value={applicantInfo.firstname} onChange={handleApplicantInfo} id="firstname" label="First Name" /></Grid>
                                <Grid item xs={12} sm={4} md={3}><TextField fullWidth={true} error={formError.lastname.error} helperText={formError.lastname.message} name="lastname" value={applicantInfo.lastname} onChange={handleApplicantInfo} id="lastname" label="Last Name" /></Grid>
                                {/* <Grid item xs={12} sm={4} md={3}>
                                    <ReactInputMask
                                        mask="999/99/9999"
                                        value={applicantInfo.ssn}
                                        maskChar="_"
                                        onChange={handleApplicantInfo}
                                    >
                                        {() => <TextField fullWidth={true} error={formError.ssn.error} helperText={formError.ssn.message} inputProps={{ type: 'tel' }} name="ssn" id="ssn" label="Social Security Number" />}
                                    </ReactInputMask>
                                </Grid> */}
                                <Grid item xs={12} sm={4} md={3}>
                                    <ReactInputMask
                                        mask="99/99/9999"
                                        value={applicantInfo.dob}
                                        disabled={false}
                                        maskChar="-"
                                        onChange={handleApplicantInfo}
                                    >
                                        {() => <TextField fullWidth={true} error={formError.dob.error} helperText={formError.dob.message} inputProps={{ type: 'tel' }} value={applicantInfo.dob} name="dob" onChange={handleApplicantInfo} id="dob" label="Date of Birth" />}

                                    </ReactInputMask>
                                </Grid>
                                <Grid item xs={12} sm={4} md={3}>
                                    <TextField fullWidth={true} disabled name="address" onChange={handleApplicantInfo} value={applicantInfo.contactAddress.address} id="address" label="License or ID Address" />
                                </Grid>
                                <Grid item xs={12} sm={4} md={3}>
                                    <TextField fullWidth={true} disabled name="city" value={applicantInfo.contactAddress.city} onChange={handleApplicantInfo} id="city" label="City" />
                                </Grid>
                                <Grid item xs={12} sm={4} md={3}>
                                    <FormControl disabled fullWidth={true}>
                                        <InputLabel id="state-label">State</InputLabel>
                                        <Select
                                            labelId="state"
                                            value={applicantInfo.contactAddress.state}
                                            onChange={handleApplicantInfo}
                                            name="state"
                                            id="state"
                                        >
                                            {usaStates.map((i) => (
                                                <MenuItem key={i.abbreviation} value={i.name}>{i.name}</MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText>{formError.state.message}</FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={4} md={3}>
                                    <TextField fullWidth={true} disabled inputProps={{ type: 'number' }} value={applicantInfo.contactAddress.zip} name="zip" onChange={handleApplicantInfo} id="zip" label="Zip Code" />
                                </Grid>
                            </Grid>
                        </form>
                    </Paper>
                </>
            }
        </>
    )
}
export default CoapInfo;