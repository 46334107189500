import React from 'react'
import ImageGallery from '../assets/imageGallery'
import CustomTable from '../assets/customTable'
import { Divider, Paper } from '@material-ui/core';
import FloatingButton from '../assets/floatingButton';
import EditIcon from '@material-ui/icons/Edit';

const slides = [
  { src: "https://www.revivalanimal.com/_next/image?url=https%3A%2F%2Fwp.revivalanimal.org%2Fapp%2Fuploads%2F2022%2F08%2FBowed-Legs-in-Puppies.webp&w=3840&q=75", width: 800, height: 600 },
  { src: "https://hips.hearstapps.com/hmg-prod/images/dulux-dog-welcomes-puppies-pinpep-duluxpuppies-021-jpg-64e761f15d2ba.jpg", width: 800, height: 600 },
  { src: "https://media.licdn.com/dms/image/D4E12AQEp2QpKSuaX1w/article-cover_image-shrink_720_1280/0/1699004902187?e=2147483647&v=beta&t=SYnMTttsEkNkMu28NmFxSZdbVadT8hG4NGKhqN5C_xs", width: 800, height: 600 },
];

const extras = [
  { name: '1 costal de arena', price: '15.50' },
  { name: '30 libras de tornillos', price: '5.90' }
]

let sum = 0;
for (let i = 0; i < extras.length; i++) {
  sum += parseFloat(extras[i].price);
}

export default function InstallationReport() {
  return (
    <>
      <ImageGallery title="Installation Pictures" slides={slides} />
      <div style={{ margin: '16px' }}>
        <h4>Extras</h4>
        <Paper>
          {extras.map((i) => (
            <>
              <div style={{ display: 'flex', padding: '0 16px' }}>
                <p style={{ width: '80%' }}>{i.name}</p><p style={{ width: '20%', textAlign: 'right' }}>${i.price}</p>
              </div>
              <Divider />
            </>

          ))}
        </Paper>
        <p style={{ textAlign: 'right', marginRight: '16px' }}><b>Total: </b>${sum.toFixed(2)}</p>
      </div>

      <ImageGallery title="Vouchers" slides={slides} />

      <FloatingButton icon={<EditIcon />} onClickAction={() => console.log('something')} />
    </>



  )
}
