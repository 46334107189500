import React, { useState } from 'react';
import DateRangeIcon from '@material-ui/icons/DateRange';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import IconButton from '@material-ui/core/IconButton';

const dateIconStyle = {
    padding: '12px',
    color: '#6c6c6c'
}
const divStyle = {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
}

export default function DateRangeHandler({ text, showDateRange, setShowDateRange }) {

    return (
        <>
            <div style={divStyle} onClick={() => setShowDateRange(!showDateRange)}>
                <DateRangeIcon style={dateIconStyle} />
                <div>{text}</div>
                {
                    !showDateRange &&
                    <KeyboardArrowDownIcon style={dateIconStyle} />

                }

                {
                    showDateRange &&
                    <IconButton aria-label="modify-date-range-hide" onClick={() => setShowDateRange(!showDateRange)}>
                        <KeyboardArrowUpIcon />
                    </IconButton>
                }

            </div >
        </>


    )
}
