const spanish = {
    applications: 'Applicaciones',
    status: 'Estado',
    applicant: 'Aplicante',
    address: 'Dirección',
    dateAdded: 'Fecha',
    actions: 'Acciones',
    more: 'Más',
    moreDetails: 'Más detalles',
    search: 'Buscar',
    rows: 'Filas',
    fromTo: '{from}-{to} de {count}',
    noResults: 'No hay resultados que mostrar',
    filterBy: 'Filtrar',
    rowsSelected: '{0} filas(s) seleccionadas'
}
export { spanish }