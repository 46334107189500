import React, { useState, useEffect } from "react";
import { Grid, Paper, FormControl, InputLabel, Select, MenuItem, TextField, Input, InputAdornment } from "@material-ui/core";
import { useStyles } from "../assets";
import { validateField } from "../errorHandling/fieldValidations";
import processFieldErrorMessage from "../errorHandling/processFieldErrorMessage";
import { employmentInfoBody } from "./formsBody";

const EmploymentInfo = ({ formOptions, handleForm, formError, setFormError }) => {
    //Main States
    const [employmentInformation, setEmploymentInformation] = useState(employmentInfoBody);
    const [employmentType, setEmploymentType] = useState('');

    //Handlers
    const handleEmploymentInformation = (e) => {
        const thisField = validateField(e);
        const errorData = processFieldErrorMessage(e, thisField, formError);
        setFormError(errorData);
        setEmploymentInformation({ ...employmentInformation, [e.target.name]: e.target.value });
        if (e.target.name === 'employmentType') setEmploymentType(e.target.value);
    }

    //Custom Hooks
    const classes = useStyles();

    //Return data for form
    useEffect(() => {
        handleForm('employmentInfo', employmentInformation);
    }, [employmentInformation]);

    useEffect(() => {
        if (employmentType === '2') setEmploymentInformation({ ...employmentInformation, employmentEmployer: 'Retired', employmentPosition: 'Retired' })
        else setEmploymentInformation({ ...employmentInformation, employmentEmployer: '', employmentPosition: '' })
    }, [employmentType])

    return (
        <>
            <Paper className="pt-3 pb-3 pl-3 pr-3 mb-3">
                <form>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={4} md={3}>
                            <FormControl fullWidth={true} className={classes.formControl}>
                                <InputLabel id="demo-simple-select-label">Employment Status</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={employmentInformation.employmentType}
                                    onChange={handleEmploymentInformation}
                                    name="employmentType"
                                    error={formError.employmentType.error}
                                >
                                    {
                                        formOptions.config_employment_types.map((type) => (
                                            <MenuItem key={type.employment_type_name} value={type.employment_type_id}>{type.employment_type_name}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4} md={3}><TextField fullWidth={true} error={formError.employmentEmployer.error} helperText={formError.employmentEmployer.message} inputProps={{ type: 'text' }} value={employmentInformation.employmentEmployer} name="employmentEmployer" onChange={handleEmploymentInformation} id="employmentEmployer" label="Employer Name" /></Grid>
                        <Grid item xs={12} sm={4} md={3}><TextField fullWidth={true} error={formError.employmentPosition.error} helperText={formError.employmentPosition.message} inputProps={{ type: 'text' }} value={employmentInformation.employmentPosition} name="employmentPosition" onChange={handleEmploymentInformation} id="employmentPosition" label="Position" /></Grid>
                        <Grid item xs={12} sm={4} md={3}><TextField onWheel={(e) => e.target.blur()} fullWidth={true} error={formError.employmentYears.error} helperText={formError.employmentYears.message} inputProps={{ type: 'number' }} value={employmentInformation.employmentYears} name="employmentYears" onChange={handleEmploymentInformation} id="employmentYears" label="Years in Employment" /></Grid>
                        <Grid item xs={12} sm={4} md={3}><TextField onWheel={(e) => e.target.blur()} fullWidth={true} error={formError.employmentMonths.error} helperText={formError.employmentMonths.message} inputProps={{ type: 'number' }} value={employmentInformation.employmentMonths} name="employmentMonths" onChange={handleEmploymentInformation} id="employmentMonths" label="Months in Employment" /></Grid>
                        <Grid item xs={12} sm={4} md={3}>
                            <FormControl fullWidth={true} className={classes.formControl}>
                                <InputLabel htmlFor="standard-adornment-amount">Gross Monthly Income</InputLabel>
                                <Input
                                    id="standard-adornment-amount"
                                    value={employmentInformation.employmentIncome}
                                    onChange={handleEmploymentInformation}
                                    type="number"
                                    onWheel={(e) => e.target.blur()}
                                    error={formError.employmentIncome.error}
                                    name="employmentIncome"
                                    inputProps={{ type: 'number' }}
                                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>






                </form>
            </Paper>
        </>
    )
}
export default EmploymentInfo;