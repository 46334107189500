import React, { useEffect, useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import UserAvatar from '../../img/avatar.svg';
import StarIcon from '@material-ui/icons/Star';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useStylesAppbar } from './styles';
import CustomizedSnackbars from '../alerts/CustomizedSnackbars';
import { isInteger } from "lodash";
import Axios from 'axios';
import PlayCircleOutlineRoundedIcon from '@material-ui/icons/PlayCircleOutlineRounded';
import PauseCircleOutlineRoundedIcon from '@material-ui/icons/PauseCircleOutlineRounded';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import RotateLeftRoundedIcon from '@material-ui/icons/RotateLeftRounded';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { FormControl, InputLabel } from "@material-ui/core";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FinishInstallationDialog from '../installations/finishInstallationDialog';

const MenuAppBar = ({ appId, applicant, coApplicant, setStatusDialog, host, token, FetchData, activeTab, setAfiDialog, afiWithoutCoapplicant, setAssignInstallationDialog, installationPath, refreshList }) => {
    const [auth, setAuth] = React.useState(true);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [score, setScore] = useState(false);
    const [alert, setAlert] = useState(false);
    const [installationHasFinished, setInstallationHasFinished] = useState(false);
    const [reason, setReason] = useState("");
    const [reasonDialog, setReasonDialog] = useState(false);
    const [reasonError, setReasonError] = useState(false);
    const [finishInstallationDialogOpen, setFinishInstallationDialogOpen] = useState(false);

    const open = Boolean(anchorEl);
    const classes = useStylesAppbar();
    const history = useHistory();

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const scoreContainerBg = () => {
        let background;
        let color;
        let style;
        if (applicant.score >= 600 && applicant.score < 700) {
            background = '#ff9c00';
            color = '#4e0000';
        }
        else if (applicant.score <= 600) {
            background = '#ff1100';
            color = '#060000';
        }
        else if (applicant.score >= 700) {
            background = '#77e421';
            color = '#335915';
        }
        return style = {
            background: background,
            color: color,
        }

    }

    const handleAfiScript = (appId, applicant, host, token) => {
        handleClose();
        if (coApplicant !== null && coApplicant.employer !== null) {
            setAfiDialog(true);
            return;
        }
        afiWithoutCoapplicant(appId, applicant, host, token);
    }
    const handleStatusChange = () => {
        handleClose();
        setStatusDialog();
    }

    const handleAssignInstallation = () => {
        handleClose();
        setAssignInstallationDialog();
    }

    const handleScoreChange = () => {
        handleClose();
        let sc = window.prompt("Enter provided score");
        sc = parseInt(sc);
        if (isNaN(sc)) {
            window.alert("Entered value is invalid");
            return;
        }
        sc !== "" && setScore(sc);
    }

    const handleSwitchApplicants = () => {
        handleClose();
        Axios.put(
            `${host}/application/switchApplicants`,
            { app_id: appId },
            { headers: { Authorization: `Bearer ${token}` } })
            .then((res) => {
                setAlert({ severity: "success", message: res.data });
                setTimeout(() => {
                    FetchData(activeTab);
                }, 2000);
            }).catch((error) => {
                if (error) {
                    setAlert({ severity: "error", message: error.response.data });
                }
            });
    }

    const updateStatus = (statusId) => {
        if (statusId === 20 && reason === "") {
            setReasonError(true);
            return;
        }
        Axios.post(
            `${host}/application/updateStatus`,
            { app_id: appId, status_id: statusId, message: reason },
            { headers: { Authorization: `Bearer ${token}` } })
            .then((res) => {
                setAlert({ ...alert, show: true, severity: 'success', message: res.data });
                setTimeout(() => {
                    setReasonDialog(false);
                    setReason("");
                    refreshList();
                }, 1000);
            })
            .catch((error) => {
                error && setAlert({ ...alert, show: true, severity: 'error', message: error.response.data });
            })
    }

    useEffect(() => {
        if (isInteger(score)) {
            Axios.post(
                `${host}/customer/setScore`,
                { score: score, customer_id: parseInt(applicant.customer_id) },
                { headers: { Authorization: `Bearer ${token}` } })
                .then((res) => {
                    setAlert({ severity: "success", message: res.data });
                    setTimeout(() => {
                        FetchData(activeTab);
                    }, 2000);
                }).catch((error) => {
                    if (error) {
                        setAlert({ severity: "error", message: error.response.data });
                    }
                });
        }
    }, [score]);

    useEffect(() => {
        if (installationPath) {
            if (applicant.installation_status_id === 4 || applicant.installation_status_id === 8 || applicant.installation_status_id === 9) {
                setInstallationHasFinished(true);
            }
        }
    }, [])

    return (

        <div className={classes.root}>
            {alert &&
                <CustomizedSnackbars message={alert.message} severity={alert.severity} hideAlert={() => setAlert(false)} />
            }
            <AppBar className={classes.toolbar} elevation={0} position='relative'>
                <Toolbar>

                    <div style={{ position: 'relative' }} className={classes.avatarBox}>
                        <img src={UserAvatar} className={classes.avatar} />
                        {!installationPath &&
                            <div className={classes.scoreContainer} style={scoreContainerBg()}>
                                <StarIcon className={classes.star} /><p className={classes.scoreText} >{applicant.score}</p>
                            </div>
                        }


                    </div>

                    <div style={{ flexGrow: 1 }} className={classes.rightFlex}>
                        <Typography variant="h6" className={classes.title}>
                            {`${applicant.firstname} ${applicant.lastname}`}
                        </Typography>
                        <p className={classes.titleSecondary}>App Number: {appId}</p>
                        <p className={classes.status} style={{ background: installationPath ? applicant.installation_status_bg : applicant.status_bg, color: installationPath ? applicant.installation_status_color : applicant.status_color }}>{installationPath ? applicant.installation_status_name : applicant.status_name}</p>
                    </div>

                    {auth && (
                        <div className={classes.rightFlex}>
                            <IconButton
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleMenu}
                                color="inherit"
                                edge="end"
                            >
                                <MoreVertIcon />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={open}
                                onClose={handleClose}

                            >
                                {!installationPath &&
                                    <>
                                        <MenuItem onClick={handleScoreChange}>Set Score</MenuItem>
                                        {coApplicant === null &&
                                            <MenuItem onClick={() => history.push(`/inquiry/${appId}/add_coap`)}>Add Co-Applicant</MenuItem>
                                        }
                                        <MenuItem onClick={() => history.push(`/application/${appId}/${coApplicant !== null && applicant.status_id >= 2 ? 'fill_coap' : 'fill'}`)}>Fill Application</MenuItem>
                                        {coApplicant !== null &&
                                            <MenuItem onClick={handleSwitchApplicants}>Switch Applicants</MenuItem>
                                        }
                                        <MenuItem onClick={handleAssignInstallation}>Assign Installation</MenuItem>
                                        <MenuItem onClick={handleStatusChange}>Change Status</MenuItem>
                                    </>
                                }

                                {installationPath &&
                                    <>
                                        <FinishInstallationDialog
                                            finishInstallationDialogOpen={finishInstallationDialogOpen}
                                            handleClose={() => setFinishInstallationDialogOpen(false)}
                                            appId={appId}
                                            token={token}
                                            host={host}
                                            refreshList={refreshList}
                                            updateStatus={updateStatus}
                                        />
                                        <Dialog fullWidth={true} open={reasonDialog} aria-labelledby="reason-dialog-title">
                                            <DialogTitle id="reason-dialog-title">Pause installation reason</DialogTitle>
                                            <FormControl className={classes.formControl}>
                                                <TextField
                                                    id="outlined-multiline-flexible"
                                                    label="Reason"
                                                    multiline
                                                    maxRows={4}
                                                    style={{ marginTop: '1rem' }}
                                                    //value={value}
                                                    onChange={(e) => setReason(e.target.value)}
                                                    variant="outlined"
                                                />
                                                {reasonError &&
                                                    <DialogContentText id="alert-dialog-description" style={{ color: 'red' }}>
                                                        You need to specify a reason to pause this installation
                                                    </DialogContentText>
                                                }

                                            </FormControl>
                                            <DialogActions>
                                                <Button onClick={() => setReasonDialog(false)} color="primary">
                                                    Cancel
                                                </Button>
                                                <Button color="primary" onClick={() => updateStatus(20)}>
                                                    Save
                                                </Button>
                                            </DialogActions>
                                        </Dialog>

                                        {!installationHasFinished && applicant.installation_status_id !== null &&
                                            <>
                                                {applicant.installation_status_id === 7 &&
                                                    <MenuItem onClick={() => updateStatus(19)}><PlayCircleOutlineRoundedIcon style={{ marginRight: '5px' }} /> Start Installation</MenuItem>
                                                }
                                                {applicant.installation_status_id === 19 &&
                                                    <>
                                                        <MenuItem onClick={() => { setReasonError(false); setReasonDialog(true) }}><PauseCircleOutlineRoundedIcon style={{ marginRight: '5px' }} /> Pause Installation</MenuItem>
                                                        <MenuItem onClick={() => setFinishInstallationDialogOpen(true)}><CheckCircleRoundedIcon style={{ marginRight: '5px' }} /> Finish Installation</MenuItem>
                                                    </>
                                                }
                                                {applicant.installation_status_id === 20 &&
                                                    <MenuItem onClick={() => updateStatus(19)}><RotateLeftRoundedIcon style={{ marginRight: '5px' }} /> Resume Installation</MenuItem>
                                                }
                                            </>
                                        }

                                        {applicant.installation_status_id === null &&
                                            <MenuItem>No actions available</MenuItem>
                                        }

                                        {installationHasFinished &&
                                            <MenuItem>No actions available, this installation has finished</MenuItem>
                                        }

                                    </>
                                }


                            </Menu>
                        </div>
                    )}
                </Toolbar>
            </AppBar>
        </div >


    );
}
export default MenuAppBar;
