import React from 'react';
import { AppBar, Toolbar, Typography, IconButton } from '@material-ui/core'
import { ArrowBack } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core';
import { useMediaQuery } from 'react-responsive';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        position: 'fixed',
        top: 0,
        width: '100%',
        zIndex: 2
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
}));

export default function CreateAppBar({ title, back }) {
    const classes = useStyles();
    const isMobile = useMediaQuery({ maxWidth: 768 });
    return (
        <>
            {isMobile &&
                <>
                    <div className={classes.root}>
                        <AppBar position="static" elevation={0}>
                            <Toolbar>
                                <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={back}>
                                    <ArrowBack />
                                </IconButton>
                                <Typography variant="h6" className={classes.title}>
                                    {title}
                                </Typography>
                            </Toolbar>
                        </AppBar>
                    </div>
                    <div style={{ height: 56 }}></div>
                </>
            }
            {!isMobile &&
                <>
                    <h3 style={{ margin: '1rem' }} className="mb-4">{title}</h3>
                </>
            }

        </>
    )
}
