import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { Paper } from '@material-ui/core';
import AssignmentTurnedInRoundedIcon from '@material-ui/icons/AssignmentTurnedInRounded';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import InstallationPictures from './installationPictures';
import InstallationExtras from './installationExtras';
import InstallationVouchers from './installationVouchers';
import CustomAlert from '../assets/customAlert';
import Axios from 'axios';

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    productInput: {
        marginRight: '10px',
        width: '100%'
    },
    priceInput: {
        marginRight: '10px',
        maxWidth: '110px'
    },
    deleteProductButton: {
        color: '#d20000'
    },
    ammountAddornment: {
        color: '#757575'
    },
    insideTitle: {
        margin: '1rem 16px 1rem 16px'
    },
    insideSubTitle: {
        margin: '0 16px 0.5rem 16px'
    },
    total: {
        margin: '16px'
    },
    addProductButton: {
        width: '100%',
    },
    imageGallery: {
        margin: '16px'
    },
    imageBox: {
        border: 'solid 1px #dfdfdf',
        borderRadius: '5px',
        position: 'relative',
        margin: '16px'
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


export default function FinishInstallationDialog({ finishInstallationDialogOpen, handleClose, appId, host, token, refreshList, updateStatus }) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [products, setProducts] = useState(false);
    const [productsTotal, setProductsTotal] = useState(0);
    const [installationPictures, setInstallationPictures] = useState(false);
    const [vouchersPictures, setVouchersPictures] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [steps, setSteps] = useState(['Installation Pictures', 'Extras']);
    const [nextDisabled, setNextDisabled] = useState(false);
    const [alert, setAlert] = useState(false);


    const handleAddProduct = () => {
        !products && setProducts([{ name: '', price: 0 }]);
        products && setProducts([...products, { name: '', price: 0 }]);
    }

    const handleProductChange = (e) => {
        let data = [...products];
        let product = data[e.target.name];
        let price = data[e.target.name].price;
        product = { name: e.target.value, price: price };
        data[e.target.name] = product;
        setProducts(data);
    }
    const handlePriceChange = (e) => {
        let data = [...products];
        let product = data[e.target.name];
        let name = data[e.target.name].name;
        product = { name: name, price: e.target.value };
        data[e.target.name] = product;
        setProducts(data);
    }

    const handleRemoveProduct = (index) => {
        let data = [...products];
        data.splice(index, 1);
        setProducts(data);
    }

    const handleAddInstallationPictures = (files) => {
        let filesToPush = installationPictures ? [...installationPictures] : [];
        for (let i = 0; i < files.length; i++) {
            filesToPush.push(files[i]);
        }
        setInstallationPictures(filesToPush);
    }

    const handleRemoveInstallationPicture = (index) => {
        let data = [...installationPictures];
        data.splice(index, 1);
        setInstallationPictures(data);
    }

    const handleAddVoucherPictures = (files) => {
        let filesToPush = vouchersPictures ? [...vouchersPictures] : [];
        for (let i = 0; i < files.length; i++) {
            filesToPush.push(files[i]);
        }
        setVouchersPictures(filesToPush);
    }

    const handleRemoveVoucherPicture = (index) => {
        let data = [...vouchersPictures];
        data.splice(index, 1);
        setVouchersPictures(data);
    }

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const sendForm = () => {
        let pictures = [];
        let vouchers = [];
        let extras = products;

        installationPictures.map((object, i) => {
            const name = `installation_${appId}_${i}.${object.name.split('.').pop()}`;
            pictures.push(name);
        });
        if (vouchersPictures.length > 0) {
            vouchersPictures.map((object, i) => {
                const name = `voucher_${appId}_${i}.${object.name.split('.').pop()}`;
                vouchers.push(name);
            });
        }

        let data = { 'pictures': pictures };

        if (vouchersPictures.length > 0) {
            data = { ...data, 'vouchers': vouchers };
        }
        if (extras.length > 0) {
            data = { ...data, 'extras': extras }
        }

        Axios.post(
            `${host}/installation/insertReport`,
            { ...data, app_id: appId },
            { headers: { Authorization: `Bearer ${token}` } }
        )
            .then((res) => {
                refreshList();
                handleClose();
                updateStatus(8);
            })
            .catch((error) => {
                if (error) {
                    let alertData = { ...alert };
                    alertData.severity = 'error';
                    alertData.message = error.response.data;
                    alertData.title = 'error';
                    setAlert(alertData);
                }
            });

    }

    useEffect(() => {
        if (products.length > 0) {
            let sum = 0;
            for (let i = 0; i < products.length; i++) {
                sum += parseFloat(products[i].price);
            }
            setProductsTotal(sum.toFixed(2));
            setSteps(['Installation Pictures', 'Extras', 'Vouchers']);
        } else {
            setProductsTotal(0);
            setVouchersPictures(false);
            setSteps(['Installation Pictures', 'Extras']);

        }
    }, [products]);

    useEffect(() => {
        !installationPictures && setNextDisabled(true);
        installationPictures.length === 0 && setNextDisabled(true);
        installationPictures.length > 0 && setNextDisabled(false);
    }, [installationPictures]);

    useEffect(() => {
        if (activeStep === 1) {
            setNextDisabled(false);
        }
        if (activeStep === 2) {
            products.length > 0 && !vouchersPictures && setNextDisabled(true);
            products.length > 0 && vouchersPictures.length === 0 && setNextDisabled(true);
            products.length > 0 && vouchersPictures.length > 0 && setNextDisabled(false);
        }
        activeStep === steps.length && sendForm();
    }, [activeStep, vouchersPictures, products])

    return (

        <>
            {alert && <CustomAlert severity={alert.severity} message1={alert.message} title={alert.title} closeAlert={() => setAlert(false)} />}


            <div>
                <Dialog fullScreen open={finishInstallationDialogOpen} onClose={handleClose} TransitionComponent={Transition}>
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" className={classes.title}>
                                Resume
                            </Typography>
                        </Toolbar>
                    </AppBar>

                    <div>
                        <Stepper activeStep={activeStep} orientation="vertical">
                            {steps.map((label, index) => (
                                <Step key={label}>
                                    <StepLabel onClick={sendForm}>{label}</StepLabel>
                                    <StepContent>
                                        <Typography>
                                            {activeStep === 0 &&
                                                <InstallationPictures
                                                    installationPictures={installationPictures}
                                                    handleAddInstallationPictures={handleAddInstallationPictures}
                                                    handleRemoveInstallationPicture={handleRemoveInstallationPicture}
                                                />
                                            }
                                            {activeStep === 1 &&
                                                <InstallationExtras
                                                    handleRemoveProduct={handleRemoveProduct}
                                                    handleAddProduct={handleAddProduct}
                                                    handlePriceChange={handlePriceChange}
                                                    products={products}
                                                    productsTotal={productsTotal}
                                                    handleProductChange={handleProductChange}
                                                />
                                            }
                                            {activeStep === 2 &&
                                                <InstallationVouchers
                                                    products={products}
                                                    vouchersPictures={vouchersPictures}
                                                    handleAddVoucherPictures={handleAddVoucherPictures}
                                                    handleRemoveVoucherPicture={handleRemoveVoucherPicture}
                                                />
                                            }

                                        </Typography>
                                        <div className={classes.actionsContainer}>
                                            <div>
                                                <Button
                                                    disabled={activeStep === 0}
                                                    onClick={handleBack}
                                                    className={classes.button}
                                                >
                                                    Back
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={handleNext}
                                                    className={classes.button}
                                                    disabled={nextDisabled}
                                                >
                                                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                                </Button>
                                            </div>
                                        </div>
                                    </StepContent>
                                </Step>
                            ))}
                        </Stepper>
                    </div>



                    {/* <List>
                    {products &&

                        products.map((object, i) => (
                            <ListItem key={i}>
                                <TextField className={classes.productInput} id={`product${i}`} label="Product" value={object.name} name={i} onChange={handleProductChange} />
                                <TextField className={classes.priceInput} id={`price${i}`} label="Price"
                                    name={i}
                                    onChange={handlePriceChange}
                                    value={object.price}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start" className={classes.ammountAddornment}>
                                                <AttachMoneyOutlinedIcon />
                                            </InputAdornment>
                                        )
                                    }}
                                />
                                <IconButton aria-label="delete" className={classes.deleteProductButton} onClick={() => handleRemoveProduct(i)}>
                                    <DeleteIcon />
                                </IconButton>
                            </ListItem>
                        ))
                    }

                    < p className={classes.total}>Total <b>${productsTotal}</b> on <b>{products ? products.length : '0'}</b> Products</p>
                    <Divider />
                </List> */}
                    {/* {products.length > 0 &&
                    <>
                        <h3 className={classes.insideTitle}>Vouchers Pictures</h3>
                        <p className={classes.insideSubTitle}>Upload the picture of every voucher for every extras you bought</p>
                        <Grid container>
                            <Grid item xs={12}>
                                <div className={classes.imageGallery}>
                                    <input
                                        accept="image/*"
                                        style={{ 'display': 'none' }}
                                        id="upload-voucher"
                                        multiple
                                        type="file"
                                        onChange={(event) => {
                                            !vouchersPictures && setVouchersPictures([event.target.files[0]]);
                                            vouchersPictures && setVouchersPictures([...vouchersPictures, event.target.files[0]]);
                                        }}
                                    />
                                    <label htmlFor="upload-voucher">
                                        <Button className="mb-3" variant="contained" color="secondary" component="span" startIcon={<AddCircleOutlineRoundedIcon />}>
                                            Add Voucher
                                        </Button>
                                    </label>
                                </div>
                            </Grid>

                            {vouchersPictures.length > 0 &&
                                vouchersPictures.map((object, i) => (
                                    <Grid item xs={12} sm={4} md={3} lg={2} xl={2} >
                                        <div className={classes.imageBox}>
                                            <img className="mb-3" alt="not found" width={"200px"} src={URL.createObjectURL(object)} style={{ width: '100%' }} />
                                            <Button
                                                color="secondary"
                                                className={classes.button}
                                                startIcon={<DeleteIcon />}
                                                size="small"
                                                onClick={() => handleRemoveVoucherPicture(i)}
                                            >
                                                Remove
                                            </Button>

                                        </div>

                                    </Grid>
                                ))
                            }


                        </Grid>
                    </>
                } */}

                    {/* <Grid container>
                    <Grid item xs={12} md={3} style={{ padding: '16px' }}>
                        <Button
                            style={{ width: '100%' }}
                            color="primary"
                            className={classes.button}
                            startIcon={<AssignmentTurnedInRoundedIcon />}
                            variant='contained'
                        >
                            Save
                        </Button>
                    </Grid>

                </Grid> */}

                </Dialog>
            </div>
        </>
    );
}
