import { React, useCallback, useEffect, useState } from "react";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { FormControl, InputLabel } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import Axios from "axios";
import CustomizedSnackbars from "../alerts/CustomizedSnackbars";
import SwitchWithLabel from "../assets/switchWithLabel";


const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

export default function ChangeStatusDialog(props) {
    const classes = useStyles();

    //Mains states
    const [statusList, setStatusList] = useState(false);
    const [statusId, setStatusId] = useState(props.statusId);
    const [statusName, setStatusName] = useState(null);
    const [reasonNeeded, setReasonNeeded] = useState(false);
    const [reason, setReason] = useState("");
    const [approvalMethod, setApprovalMethod] = useState("");
    const [ignoreUpdates, setIgnoreUpdates] = useState(false);


    //Secondary states
    const [alert, setAlert] = useState({ show: false, severity: "", message: "" });

    const handleStatus = (e) => {
        setStatusName(e.currentTarget.getAttribute("data-attr"));
        setStatusId(e.target.value);
    }

    const handleReason = (e) => {
        setReason(e.target.value);
    }

    const handleIgnoreUpdates = () => {
        setIgnoreUpdates(!ignoreUpdates);
        updateIgnoreUpdates(!ignoreUpdates)
    }

    const updateIgnoreUpdates = (ignore) => {
        Axios.put(
            `${props.host}/application/updateIgnoreUpdates`,
            { 'app_id': parseInt(props.app_id), 'ignore_updates': ignore ? 'YES' : 'NO' },
            { headers: { Authorization: `Bearer ${props.token}` } })
            .then((res) => {
                setTimeout(() => {
                    props.refreshList();
                }, 1000);
            })
            .catch((error) => {
                error && setAlert({ ...alert, show: true, severity: 'error', message: error.response.data });
            })
    }

    const updateStatus = () => {
        Axios.post(
            `${props.host}/application/updateStatus`,
            { app_id: props.app_id, status_id: statusId, message: reason, approval_method: approvalMethod },
            { headers: { Authorization: `Bearer ${props.token}` } })
            .then((res) => {
                setAlert({ ...alert, show: true, severity: 'success', message: res.data });
                setTimeout(() => {
                    props.close();
                    props.refreshList();
                }, 1000);
            })
            .catch((error) => {
                error && setAlert({ ...alert, show: true, severity: 'error', message: error.response.data });
            })
    }

    //Load app Status
    const LoadStatus = useCallback(() => {
        Axios.get(`${props.host}/config/readStatus`, { headers: { Authorization: `Bearer ${props.token}` } })
            .then(res => {
                setStatusList(res.data);
            })
            .catch((error) => {
                error && setAlert({ ...alert, show: true, severity: 'error', message: error.response.data });
            })
    }, []);

    useEffect(() => {
        LoadStatus();
    }, []);

    useEffect(() => {
        props.ignoreUpdates === null && setIgnoreUpdates(false);
        props.ignoreUpdates === 'YES' && setIgnoreUpdates(true);
    }, [props]);



    useEffect(() => {
        if (statusName === 'Cancelled' || statusName === 'Pulled Out' || statusName === 'Problem') {
            setReasonNeeded(true);
        } else {
            setReasonNeeded(false);
        }
    }, [statusName])



    return (
        <div>
            {alert.show &&
                <CustomizedSnackbars
                    severity={alert.severity}
                    message={alert.message}
                    hideAlert={() => setAlert({ ...alert, show: false, severity: "", message: "" })}
                />
            }

            {statusList &&
                <Dialog fullWidth={true} open={props.open} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Change App Status</DialogTitle>

                    <FormControl className={classes.formControl}>
                        <InputLabel id="status-label">Status</InputLabel>
                        <Select
                            labelId="status-label"
                            id="status"
                            value={statusId}
                            onChange={handleStatus}
                        >
                            {statusList.map((i) => (
                                <MenuItem data-attr={i.status_name} value={i.status_id}>{i.status_name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {statusId === '6' &&
                        <FormControl className={classes.formControl}>
                            <InputLabel id="agency">Approval Method</InputLabel>
                            <Select
                                labelId="agency"
                                id="agency_id"
                                value={approvalMethod}
                                onChange={(e) => setApprovalMethod(e.target.value)}
                            >
                                <MenuItem value="4">Cash</MenuItem>
                                <MenuItem value="5">Good Leap</MenuItem>
                                <MenuItem value="6">Credit Card</MenuItem>
                                <MenuItem value="7">Check</MenuItem>
                            </Select>
                        </FormControl>
                    }
                    <FormControl className={classes.formControl}>
                        {reasonNeeded &&
                            <TextField
                                id="outlined-multiline-flexible"
                                label="Reason"
                                multiline
                                maxRows={4}
                                style={{ marginTop: '1rem' }}
                                //value={value}
                                onChange={handleReason}
                                variant="outlined"
                            />
                        }
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <SwitchWithLabel label="Ignore future updates for this app" checked={ignoreUpdates} handleSwitch={handleIgnoreUpdates} />
                    </FormControl>
                    <DialogActions>
                        <Button onClick={props.close} color="primary">
                            Cancel
                        </Button>
                        <Button color="primary" onClick={() => updateStatus()}>
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
            }
        </div>
    );
}