import { React, useState } from "react";
import Skeleton from '@material-ui/lab/Skeleton';
import { Grid } from "@material-ui/core";


const LoadingSkeleton = () => {
    return (
        <>
            <Skeleton animation="wave" variant="rect" height={60} />
            <br />
            <Skeleton animation="wave" variant="rect" height={60} />
            <br />
            <Skeleton animation="wave" variant="rect" height={60} />
            <br />
            <Skeleton animation="wave" variant="rect" height={60} />
            <br />
            <Skeleton animation="wave" variant="rect" height={60} />
            <br />
            <Skeleton animation="wave" variant="rect" height={60} />
            <br />
            <Skeleton animation="wave" variant="rect" height={60} />
            <br />
            <Skeleton animation="wave" variant="rect" height={60} />
            <br />
            <Skeleton animation="wave" variant="rect" height={60} />
        </>
    );
}

const LoadingAppDetailsSkeleton = () => {
    return (
        <div style={{ padding: '20px' }}>
            <Grid container >
                <Grid item xs={2}><Skeleton variant="circle" width={70} height={70} /></Grid>
                <Grid item xs={10} style={{ padding: '0 1rem' }}><Skeleton style={{ marginTop: '1rem' }} animation="wave" variant="rect" height={30} /></Grid>
            </Grid >

            <br />
            <Skeleton animation="wave" variant="rect" height={30} />
            <br />
            <Skeleton animation="wave" variant="rect" height={30} />
            <br />
            <Skeleton animation="wave" variant="rect" height={30} />
            <br />
            <Skeleton animation="wave" variant="rect" height={30} />
            <br />
            <Skeleton animation="wave" variant="rect" height={30} />
            <br />
            <Skeleton animation="wave" variant="rect" height={30} />
            <br />
            <Skeleton animation="wave" variant="rect" height={30} />
            <br />
            <Skeleton animation="wave" variant="rect" height={30} />
            <br />
            <Skeleton animation="wave" variant="rect" height={30} />
            <br />
            <Skeleton animation="wave" variant="rect" height={30} />
            <br />
            <Skeleton animation="wave" variant="rect" height={30} />
        </div>
    )
}

const AppFillSkeleton = () => {

    return (
        <div style={{ padding: '1rem' }}>
            <Grid container>
                <Grid item xs={3}>
                    <Skeleton animation="wave" variant="text" height={30} />
                </Grid>
                <Grid item xs={12}>
                    <Skeleton animation="wave" variant="text" height={30} />
                    <br />
                    <Skeleton animation="wave" variant="text" height={30} />
                </Grid>
                <Grid item xs={12}>
                    <Skeleton animation="wave" variant="rect" height={50} />
                </Grid>
                <br /><br /><br /><br /><br /><br />
                <Grid item xs={3}>
                    <Skeleton animation="wave" variant="text" height={30} />
                </Grid>
                <Grid item xs={12}>
                    <Skeleton animation="wave" variant="rect" height={400} />
                </Grid>
            </Grid>

        </div>
    )
}

export { LoadingSkeleton, LoadingAppDetailsSkeleton, AppFillSkeleton };