import React, { useState, useEffect } from "react";
import usaStates from "../../data/usaStates";
import { Grid, Paper, FormControl, InputLabel, Select, MenuItem, TextField, Divider } from "@material-ui/core";
import ReactInputMask from "react-input-mask";
import { errors } from "../formsBody";
import { useStyles, stepActiveToggle } from "../assets";
import { validateField } from "../errorHandling/fieldValidations";
import processFieldErrorMessage from "../errorHandling/processFieldErrorMessage";
import { idInfoBody } from "./formsBody";

const IdInfo = ({ formOptions, handleForm, formError, setFormError }) => {
    //Main States
    const [idInformation, setIdInformation] = useState(idInfoBody);

    //Handlers
    const handleIdInformation = (e) => {
        const thisField = validateField(e);
        const errorData = processFieldErrorMessage(e, thisField, formError);
        setFormError(errorData);
        setIdInformation({ ...idInformation, [e.target.name]: e.target.value });
    }

    //Custom Hooks
    const classes = useStyles();

    //Return data for form
    useEffect(() => {
        handleForm('idInfo', idInformation);
    }, [idInformation]);

    return (
        <>
            <Paper className="pt-3 pb-3 pl-3 pr-3 mb-3">
                <form>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={4} md={3}>
                            <FormControl fullWidth={true} className={classes.formControl}>
                                <InputLabel id="demo-simple-select-label">Primary ID type</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={idInformation.idType}
                                    onChange={handleIdInformation}
                                    name="idType"
                                    error={formError.idType.error}
                                >
                                    {
                                        formOptions.config_id_types.map((type) => (
                                            <MenuItem key={type.id_type_name} value={type.id_type_id}>{type.id_type_name}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4} md={3}>
                            <FormControl fullWidth={true} className={classes.formControl}>
                                <InputLabel id="demo-simple-select-label">Issuing State</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={idInformation.stateId}
                                    error={formError.stateId.error}
                                    onChange={handleIdInformation}
                                    name="stateId"
                                >
                                    <MenuItem value="TX">Texas</MenuItem>
                                    <MenuItem value="NM">New Mexico</MenuItem>
                                    <Divider light />
                                    {usaStates.map((i) => (
                                        <MenuItem key={i.abbreviation} value={i.abbreviation}>{i.name}</MenuItem>
                                    ))}


                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4} md={3}>
                            <ReactInputMask
                                mask="99/99/9999"
                                value={idInformation.expDate}
                                disabled={false}
                                maskChar="-"
                                onChange={handleIdInformation}
                            >
                                {() => <TextField fullWidth={true} error={formError.expDate.error} helperText={formError.expDate.message} inputProps={{ type: 'tel' }} value={idInformation.expDate} name="expDate" onChange={handleIdInformation} id="standard-basic" label="Expiration Date" />}

                            </ReactInputMask>
                        </Grid>
                    </Grid>
                </form>
            </Paper>
        </>
    )
}
export default IdInfo