import React, { useState, useRef, useEffect, useCallback, useContext } from "react";
import { Grid, Button, FormControl, InputLabel, MenuItem, Paper, TextField, Select, List, ListItem, ListItemText, Divider, FormHelperText, Snackbar, FormControlLabel, InputAdornment, Input } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import ReactInputMask from "react-input-mask";
import Axios from "axios";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CustomizedSnackbars from "../alerts/CustomizedSnackbars";
import HorizontalStepper from "../stepper/stepper";
import { useHistory } from "react-router-dom";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { BlueTitleBox, LoadingFullScreen } from "./assets";
import { addressValidation, data, errors, idInformation, installationAddress, installationDetails, contactInfo, residence, employment, googlePlace, fieldsToValidate } from "./formsBody";
import { validateField } from "./errorHandling/fieldValidations";
import { DialogNoButtons } from "../assets/dialogNoButtons";
import { NotFoundGeneral } from "../assets/notFound";
import { AppFillSkeleton, LoadingSkeleton } from "../assets/skeletons";
import CreateAppBar from "./appBar";
import BottomMenu from "../footer/bottomMenu";
import usaStates from "../data/usaStates";

const applicantInfoBody = data;
const errorFormBody = errors;
const addressValidationBody = addressValidation;
const idInformationBody = idInformation;
const installationAddressBody = installationAddress;
const installationDetailsBody = installationDetails;
const contactInfoBody = contactInfo;
const residenceBody = residence;
const employmentBody = employment;
const googlePlaceBody = googlePlace;
const fieldsToValidateBody = fieldsToValidate;

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
}));


const AppFill = (props) => {

    //Configuration
    const history = useHistory();
    const classes = useStyles();

    //Main states
    const [applicant, setApplicant] = useState(null);
    const [coApplicant, setCoApplicant] = useState(null);
    const [mainApplicantId, setMainApplicantId] = useState(null);
    const [applicantInfo, setApplicantInfo] = useState(applicantInfoBody);
    const [idInformation, setIdInformation] = useState(idInformationBody);
    const [installationAddress, setInstallationAddress] = useState(installationAddressBody);
    const [installationDetails, setInstallationDetails] = useState(installationDetailsBody);
    const [sameInstallationAddress, setSameInstallationAddress] = useState(false);
    const [contactInfo, setContactInfo] = useState(contactInfoBody);
    const [residenceInfo, setResidenceInfo] = useState(residenceBody);
    const [employmentInfo, setEmploymentInfo] = useState(employmentBody);
    const [financedAmount, setFinancedAmount] = useState(null);
    const formOptions = applicantInfo.options;

    //Secondary states
    const [loadError, setLoadError] = useState(false);
    const [formError, setFormError] = useState(errorFormBody);
    const [googlePlace, setGooglePlace] = useState(googlePlaceBody);
    const [addressValidation, setAddressValidation] = useState(addressValidationBody);
    const [onLoadLoading, setOnloadLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [confirmation, setConfirmation] = useState({ show: false, message: "" });
    const [fillCoapplicantConfirmation, setFillCoapplicantconfirmation] = useState(false);

    //Refs
    const applicantInfoRef = useRef(null);
    const idInfoRef = useRef(null);
    const installationRef = useRef(null);
    const installationAddressRef = useRef(null);
    const contactInformationRef = useRef(null);
    const residenceRef = useRef(null);
    const employmentRef = useRef(null);

    //Wrong address dialog and alerts
    const [openWrongAddressAlert, setOpenWrongAddressAlert] = useState(false);
    const [alert, setAlert] = useState({ show: false, severity: '', message: '' });
    const hideAlert = () => { setAlert({ ...alert, show: false }); }

    //Set Main Applicant
    const updateApplicants = (applicantId, coApplicantId) => {
        return new Promise((resolve) => {
            Axios.put(
                `${props.host}/application/updateApplicants`,
                { applicant: applicantId, coapplicant: coApplicantId, app_id: props.match.params.app_id },
                { headers: { Authorization: `Bearer ${props.token}` } })
                .then(res => resolve(true))
                .catch((error) => {
                    if (error) {
                        let alertData = { ...alert };
                        alertData.show = true;
                        alertData.severity = 'error';
                        alertData.message = error.response.data;
                        setAlert(alertData);
                    }
                });
        })

    }
    const setMainApplicant = async (applicantId, coApplicantId) => {
        await updateApplicants(applicantId, coApplicantId);
        loadAppData();
        setMainApplicantId(applicantId);
    }

    //Applicant info
    const handleApplicantInfo = (e) => {
        const thisField = validateField(e);
        setErrorMessage(e, thisField);

        let data = { ...applicantInfo };
        data[e.target.name] = e.target.value;
        setApplicantInfo(data);
    }

    //ID information
    const handleIdInformation = (e) => {
        const thisField = validateField(e);
        setErrorMessage(e, thisField);

        let data = { ...idInformation };
        data[e.target.name] = e.target.value;
        setIdInformation(data);

    }

    //Installation
    const handleInstallationAddress = (e) => {
        const thisField = validateField(e);
        setErrorMessage(e, thisField);
        let data = { ...installationAddress };
        let name = e.target.name.substring(12).toLowerCase();

        if (name === 'state') {
            data.state = e.target.value;
            data.stateAB = e.currentTarget.dataset.attr;
            setInstallationAddress(data);
            return;
        }

        data[name] = e.target.value;
        setInstallationAddress(data);
    }


    //Contact
    const handleContactInfo = (e) => {
        const thisField = validateField(e);
        setErrorMessage(e, thisField);

        let data = { ...contactInfo };
        data[e.target.name] = e.target.value;
        setContactInfo(data);
    }

    //Residence
    const handleResidenceInfo = (e) => {
        const thisField = validateField(e);
        setErrorMessage(e, thisField);

        let data = { ...residenceInfo };
        let name = e.target.name.substring(9);
        let secondName = name.charAt(0).toLowerCase() + name.slice(1);

        data[secondName] = e.target.value;
        setResidenceInfo(data);
    }

    //Employment
    const handleEmploymentInfo = (e) => {
        const thisField = validateField(e);
        setErrorMessage(e, thisField);

        let data = { ...employmentInfo };
        let name = e.target.name.substring(10);
        let secondName = name.charAt(0).toLowerCase() + name.slice(1);

        data[secondName] = e.target.value;
        setEmploymentInfo(data);
    }

    //Finance
    const handleFinanceAmount = (e) => {
        setFinancedAmount(e.target.value);
        const thisField = validateField(e);
        setErrorMessage(e, thisField);
    }

    //Set and clean error messages
    const setErrorMessage = (e, thisField) => {
        let errorData = { ...formError };
        if (thisField.ok) {
            errorData[e.target.name].error = false;
            errorData[e.target.name].message = thisField.message;
        } else {
            errorData[e.target.name].error = true;
            errorData[e.target.name].message = thisField.message;
        }
        setFormError(errorData);
    }


    //scrollIntoView
    const scrollToError = (ref, msg) => {
        const reference = ref;
        const toEval = reference + '.current.scrollIntoView({ behavior: "smooth" })';
        let message;
        if (msg === undefined) {
            message = "There are errors in the form"
        } else {
            message = msg
        }
        eval(toEval);
        setAlert({
            severity: "error",
            show: true,
            message: message
        });
    }

    //Validate form prior to sending
    const validateForm = () => {

        let formIsValid = true;

        const fieldsToValidate = fieldsToValidateBody(
            applicantInfo,
            idInformation,
            installationDetails,
            installationAddress,
            contactInfo,
            residenceInfo,
            employmentInfo,
            financedAmount);

        //Global Validate function
        const validation = (name, value, ref) => {
            const fieldToValidate = { target: { name: name, value: value } };
            const thisField = validateField(fieldToValidate);
            setErrorMessage(fieldToValidate, thisField);
            if (!thisField.ok) {
                scrollToError(ref, thisField.message);
                return false;
            } else {
                return true;
            }
        }

        for (let i = 0; i < fieldsToValidate.length; i++) {
            const validate = validation(fieldsToValidate[i][0], fieldsToValidate[i][1], fieldsToValidate[i][2]);
            if (!validate) {
                formIsValid = false;
                break;
            }
        }

        formIsValid && verifyAddress();


    }

    //Address validation
    const validateAddress = (number) => {
        let errorData = errorFormBody;

        if (number === undefined) {
            errorData.address.error = true;
            errorData.address.message = "You need to provide the number of the house for this address to be valid";
            setFormError(errorData);
            return false;
        }
        if (number.includes("-") || number.includes("1/2")) {
            errorData.address.error = false;
            errorData.address.message = "";
            setFormError(errorData);
            return true;
        }
        if (isNaN(number)) {
            errorData.address.error = true;
            errorData.address.message = "You need to provide the number of the house for this address to be valid";
            setFormError(errorData);
            return false;
        }
        errorData.address.error = false;
        errorData.address.message = "";
        setFormError(errorData);
        return true;
    }


    //Address autocomplete
    const autoCompleteRef = useRef();
    const installAddressForm = useRef();
    const options = {
        componentRestrictions: { country: ["us"] },
        fields: ["address_components"],
        types: ["address"]
    }
    const enableGmapsAutocomplete = () => {
        autoCompleteRef.current = new window.parent.google.maps.places.Autocomplete(
            installAddressForm.current,
            options
        );
        autoCompleteRef.current.addListener("place_changed", async function () {
            const place = await autoCompleteRef.current.getPlace();
            let data = { ...googlePlace };
            let number;
            let street;
            let city;
            let state;
            let stateAB;
            let zip;


            place.address_components.map((i) => {
                if (i.types && i.types[0] === 'street_number') {
                    number = i.long_name;
                }
                if (i.types && i.types[0] === 'route') {
                    street = i.long_name;
                }
                if (i.types && i.types[0] === 'locality') {
                    city = i.long_name;
                }
                if (i.types && i.types[0] === 'administrative_area_level_1') {
                    state = i.long_name;
                    stateAB = i.short_name;
                }
                if (i.types && i.types[0] === 'postal_code') {
                    zip = i.long_name;
                }
            })

            if (validateAddress(number)) {
                data.address = number + ' ' + street;
                data.city = city;
                data.state = state;
                data.stateAB = stateAB;
                data.zip = zip;
                setGooglePlace(data);
            };
        });
    }


    //Refresh installation address by google
    useEffect(() => {
        const gPlace = { ...googlePlace }
        let data = { ...installationAddress }
        let errorData = { ...formError };
        data.address = gPlace.address;
        data.city = gPlace.city;
        data.state = gPlace.state;
        data.stateAB = gPlace.stateAB;
        data.zip = gPlace.zip;
        errorData.installationAddress.error = false;
        errorData.installationAddress.message = "";
        errorData.installationCity.error = false;
        errorData.installationCity.message = "";
        errorData.installationState.error = false;
        errorData.installationState.message = "";
        errorData.installationZip.error = false;
        errorData.installationZip.message = "";
        setInstallationAddress(data);
        setFormError(errorData);
    }, [googlePlace])

    //Address verification by google
    const verifyAddress = () => {
        Axios.post('https://addressvalidation.googleapis.com/v1:validateAddress?key=AIzaSyAJvdDkwhK5W8TAXQdiPCLg3ih9SlBjcOU', {
            "address": {
                "regionCode": "US",
                "addressLines": [installationAddress.address, installationAddress.city + ', ' + installationAddress.stateAB + ', ' + installationAddress.zip]
            },
            "enableUspsCass": true
        }).then((res) => {
            if (res.data.result.verdict.hasUnconfirmedComponents || res.data.result.verdict.hasReplacedComponents) {
                let adv = { ...addressValidationBody }
                if (res.data.result.address.addressComponents) {
                    res.data.result.address.addressComponents.map((i) => {
                        if (i.confirmationLevel !== 'CONFIRMED' || i.spellCorrected || i.replaced) {
                            const change = () => {
                                return new Promise(resolve => {
                                    if (i.componentType === 'street_number') {
                                        adv.number = "Number of the house";
                                    }
                                    if (i.componentType === 'route') {
                                        adv.street = "Street";
                                    }
                                    if (i.componentType === 'locality') {
                                        adv.city = "City";
                                    }
                                    if (i.componentType === 'administrative_area_level_1') {
                                        adv.state = "State";
                                    }
                                    if (i.componentType === 'postal_code') {
                                        adv.zip = "Zip Code";
                                    }
                                    setAddressValidation(adv);
                                    resolve(true);
                                })
                            }

                            async function proceed() {
                                const result = await change();
                                setOpenWrongAddressAlert(true);
                            }
                            proceed();

                        }
                    });
                }

            } else {
                sendForm();
            }

        });
    }
    //Validate App is Filled
    const validateAppIsFilled = () => {
        setOnloadLoading(true);
        Axios.get(
            `${props.host}/application/validateApplicantIsFilled?app_id=${props.match.params.app_id}`,
            { headers: { Authorization: `Bearer ${props.token}` } })
            .then(res => getApplicants())
            .catch(error => error && setLoadError(error.response.data), setOnloadLoading(false));
    }
    //Get Applicants and check if coapplicant exists
    const getApplicants = () => {
        setOnloadLoading(true);
        Axios.get(
            `${props.host}/application/getApplicants?app_id=${props.match.params.app_id}`,
            { headers: { Authorization: `Bearer ${props.token}` } })
            .then((res) => {
                setApplicant(res.data.applicant);
                setCoApplicant(res.data.co_applicant);
                loadAppData();
            })
            .catch((error) => {
                if (error) {
                    setOnloadLoading(false);
                    setLoadError(error.response.data);
                    let alertData = { ...alert };
                    alertData.show = true;
                    alertData.severity = 'error';
                    alertData.message = error.response.data;
                    setAlert(alertData);

                }
            });
    }

    //Load application data
    const loadAppData = () => {
        setOnloadLoading(true);
        Axios.get(
            `${props.host}/application/view?app_id=${props.match.params.app_id}`,
            { headers: { Authorization: `Bearer ${props.token}` } })
            .then((res) => {
                setApplicantInfo(res.data);
                setOnloadLoading(false);
            }).catch((error) => {
                if (error) {
                    setOnloadLoading(false);
                    setLoadError(error.response.data);
                    let alertData = { ...alert };
                    alertData.show = true;
                    alertData.severity = 'error';
                    alertData.message = error.response.data;
                    setAlert(alertData);
                }
            });
        setOpenWrongAddressAlert(false);
    }
    //Confirm application and send form
    const sendForm = () => {
        const appInfo = {
            app_id: applicantInfo.app_id,
            customer_id: applicantInfo.customer_id,
            firstname: applicantInfo.firstname,
            lastname: applicantInfo.lastname,
            // ssn: applicantInfo.ssn,
            dob: applicantInfo.dob
        }
        const data = {
            appInfo, idInformation, installationDetails, installationAddress, contactInfo, residenceInfo, employmentInfo, financedAmount, sameInstallationAddress
        };
        setLoading(true);
        Axios.post(
            `${props.host}/application/fill`,
            { ...data },
            { headers: { Authorization: `Bearer ${props.token}` } })
            .then((res) => {
                coApplicant !== null && setFillCoapplicantconfirmation(true);
                coApplicant === null && setConfirmation({ show: true, message: res.data });
                setLoading(false);
            })
            .catch((error) => {
                if (error) {
                    let alertData = { ...alert };
                    alertData.show = true;
                    alertData.severity = 'error';
                    alertData.message = error.response.data;
                    setLoading(false);
                    setAlert(alertData);
                }
            });
        setOpenWrongAddressAlert(false);
    }

    //Set same installation address
    useEffect(() => {
        if (sameInstallationAddress) {
            let errorData = { ...formError };
            let data = {
                address: applicantInfo.contactAddress.address,
                city: applicantInfo.contactAddress.city,
                state: applicantInfo.contactAddress.state,
                zip: String(applicantInfo.contactAddress.zip)
            };
            errorData.installationAddress.error = false;
            errorData.installationAddress.message = "";
            errorData.installationCity.error = false;
            errorData.installationCity.message = "";
            errorData.installationState.error = false;
            errorData.installationState.message = "";
            errorData.installationZip.error = false;
            errorData.installationZip.message = "";
            setFormError(errorData);
            setInstallationAddress(data);
        }
        else {
            setInstallationAddress(installationAddressBody);
        }
    }, [sameInstallationAddress]);

    //Load application data on load
    useEffect(() => {
        validateAppIsFilled();
    }, []);

    //Installation details selectors
    useEffect(() => {
        if (installationDetails.productId !== undefined || installationDetails.productId !== "") {
            formError.productType.error = false;
        }
        if (installationDetails.needsShed !== undefined || installationDetails.needsShed !== "") {
            formError.shedNeeded.error = false;
        }

    }, [installationDetails]);
    //Update mortgage to 0 when paid
    useEffect(() => {
        if (residenceInfo.type === '2') {
            setResidenceInfo({ ...residenceInfo, mortgage: 0 });
        } else {
            setResidenceInfo({ ...residenceInfo, mortgage: "" });
        }
    }, [residenceInfo.type]);
    //Update retirement info
    useEffect(() => {
        if (employmentInfo.type === '2') {
            setEmploymentInfo({ ...employmentInfo, employer: 'Retired', position: 'Retired' });
        } else {
            setEmploymentInfo({ ...employmentInfo, employer: '', position: '' });
        }
    }, [employmentInfo.type]);

    return (
        <>
            <CreateAppBar title={`Fill Applicant`} back={() => history.goBack()} />
            {loadError && !onLoadLoading &&
                <NotFoundGeneral message={loadError} />
            }

            {!loadError &&
                <div style={{ margin: '1rem' }}>
                    {onLoadLoading &&
                        <AppFillSkeleton />
                    }

                    {loading &&
                        <LoadingFullScreen color="white" type="bars" />
                    }
                    {!loadError && !onLoadLoading && applicant &&
                        <>
                            {coApplicant !== null && mainApplicantId === null &&
                                <DialogNoButtons title="Co-applicant found">
                                    <p>This application has 2 applicants, please choose wich of the following will be the main applicant</p>
                                    <Button onClick={() => setMainApplicant(applicant.customer_id, coApplicant.customer_id)} color="primary">
                                        {`${applicant.firstname} ${applicant.lastname}`}
                                        {applicant.score !== null &&
                                            <> (Score {applicant.score})</>
                                        }
                                    </Button><br />
                                    <Button onClick={() => setMainApplicant(coApplicant.customer_id, applicant.customer_id)} color="primary">
                                        {`${coApplicant.firstname} ${coApplicant.lastname}`}
                                        {coApplicant.score !== null &&
                                            <> (Score {coApplicant.score})</>
                                        }
                                    </Button><br /><br />
                                    <Button onClick={() => history.goBack()} color="secondary">Go Back</Button>
                                </DialogNoButtons>
                            }
                            <p className="mt-3">Fill up the required information and click Continue to submit it to the Credit Department for verification</p>
                            <HorizontalStepper activeStep={1} steps={['Request credit Inquiry', 'Send Application Details', 'Request Paperwork']} />

                            <h4 className="mt-5" ref={applicantInfoRef}>Applicant Information</h4>
                            <Paper className="pt-3 pb-3 pl-3 pr-3 mb-3">
                                <form>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={4} md={3}><TextField fullWidth={true} error={formError.firstname.error} helperText={formError.firstname.message} name="firstname" value={applicantInfo.firstname} onChange={handleApplicantInfo} id="standard-basic" label="First Name" /></Grid>
                                        <Grid item xs={12} sm={4} md={3}> <TextField fullWidth={true} error={formError.lastname.error} helperText={formError.lastname.message} name="lastname" value={applicantInfo.lastname} onChange={handleApplicantInfo} id="standard-basic" label="Last Name" /></Grid>
                                        {/* <Grid item xs={12} sm={4} md={3}>
                                            <ReactInputMask
                                                mask="999/99/9999"
                                                value={applicantInfo.ssn}
                                                maskChar="_"
                                                onChange={handleApplicantInfo}
                                            >
                                                {() => <TextField fullWidth={true} error={formError.ssn.error} helperText={formError.ssn.message} inputProps={{ type: 'tel' }} name="ssn" id="standard-basic" label="Social Security Number" />}
                                            </ReactInputMask>
                                        </Grid> */}
                                        <Grid item xs={12} sm={4} md={3}>
                                            <ReactInputMask
                                                mask="99/99/9999"
                                                value={applicantInfo.dob}
                                                disabled={false}
                                                maskChar="-"
                                                onChange={handleApplicantInfo}
                                            >
                                                {() => <TextField fullWidth={true} error={formError.dob.error} helperText={formError.dob.message} inputProps={{ type: 'tel' }} value={applicantInfo.dob} name="dob" onChange={handleApplicantInfo} id="standard-basic" label="Date of Birth" />}
                                            </ReactInputMask>
                                        </Grid>
                                        <Grid item xs={12} sm={4} md={3}><TextField fullWidth={true} disabled name="address" onChange={handleApplicantInfo} value={applicantInfo.contactAddress.address} id="standard-basic" label="License or ID Address" /></Grid>
                                        <Grid item xs={12} sm={4} md={3}><TextField fullWidth={true} disabled name="city" value={applicantInfo.contactAddress.city} onChange={handleApplicantInfo} id="standard-basic" label="City" /></Grid>
                                        <Grid item xs={12} sm={4} md={3}>
                                            <FormControl disabled fullWidth={true}>
                                                <InputLabel id="demo-simple-select-label">State</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    value={applicantInfo.contactAddress.state}
                                                    onChange={handleApplicantInfo}
                                                    name="state"
                                                    id="demo-simple-select"

                                                >
                                                    <MenuItem value="Alabama">Alabama</MenuItem>
                                                    <MenuItem value="Alaska">Alaska</MenuItem>
                                                    <MenuItem value="Arizona">Arizona</MenuItem>
                                                    <MenuItem value="Arkansas">Arkansas</MenuItem>
                                                    <MenuItem value="California">California</MenuItem>
                                                    <MenuItem value="Colorado">Colorado</MenuItem>
                                                    <MenuItem value="Connecticut">Connecticut</MenuItem>
                                                    <MenuItem value="Delaware">Delaware</MenuItem>
                                                    <MenuItem value="District of Columbia">District of Columbia</MenuItem>
                                                    <MenuItem value="Florida">Florida</MenuItem>
                                                    <MenuItem value="Georgia">Georgia</MenuItem>
                                                    <MenuItem value="Guam">Guam</MenuItem>
                                                    <MenuItem value="Hawaii">Hawaii</MenuItem>
                                                    <MenuItem value="Idaho">Idaho</MenuItem>
                                                    <MenuItem value="Illinois">Illinois</MenuItem>
                                                    <MenuItem value="Indiana">Indiana</MenuItem>
                                                    <MenuItem value="Iowa">Iowa</MenuItem>
                                                    <MenuItem value="Kansas">Kansas</MenuItem>
                                                    <MenuItem value="Kentucky">Kentucky</MenuItem>
                                                    <MenuItem value="Louisiana">Louisiana</MenuItem>
                                                    <MenuItem value="Maine">Maine</MenuItem>
                                                    <MenuItem value="Maryland">Maryland</MenuItem>
                                                    <MenuItem value="Massachusetts">Massachusetts</MenuItem>
                                                    <MenuItem value="Michigan">Michigan</MenuItem>
                                                    <MenuItem value="Minnesota">Minnesota</MenuItem>
                                                    <MenuItem value="Mississippi">Mississippi</MenuItem>
                                                    <MenuItem value="Missouri">Missouri</MenuItem>
                                                    <MenuItem value="Montana">Montana</MenuItem>
                                                    <MenuItem value="Nebraska">Nebraska</MenuItem>
                                                    <MenuItem value="Nevada">Nevada</MenuItem>
                                                    <MenuItem value="New Hampshire">New Hampshire</MenuItem>
                                                    <MenuItem value="New Jersey">New Jersey</MenuItem>
                                                    <MenuItem value="New Mexico">New Mexico</MenuItem>
                                                    <MenuItem value="New York">New York</MenuItem>
                                                    <MenuItem value="North Carolina">North Carolina</MenuItem>
                                                    <MenuItem value="North Dakota">North Dakota</MenuItem>
                                                    <MenuItem value="Ohio">Ohio</MenuItem>
                                                    <MenuItem value="Oklahoma">Oklahoma</MenuItem>
                                                    <MenuItem value="Oregon">Oregon</MenuItem>
                                                    <MenuItem value="Pennsylvania">Pennsylvania</MenuItem>
                                                    <MenuItem value="Puerto Rico">Puerto Rico</MenuItem>
                                                    <MenuItem value="Rhode Island">Rhode Island</MenuItem>
                                                    <MenuItem value="South Carolina">South Carolina</MenuItem>
                                                    <MenuItem value="South Dakota">South Dakota</MenuItem>
                                                    <MenuItem value="Tennessee">Tennessee</MenuItem>
                                                    <MenuItem value="Texas">Texas</MenuItem>
                                                    <MenuItem value="Utah">Utah</MenuItem>
                                                    <MenuItem value="Vermont">Vermont</MenuItem>
                                                    <MenuItem value="Virginia">Virginia</MenuItem>
                                                    <MenuItem value="U.S. Virgin Islands">U.S. Virgin Islands</MenuItem>
                                                    <MenuItem value="Washington">Washington</MenuItem>
                                                    <MenuItem value="West Virginia">West Virginia</MenuItem>
                                                    <MenuItem value="Wisconsin">Wisconsin</MenuItem>
                                                    <MenuItem value="Wyoming">Wyoming</MenuItem>
                                                </Select>
                                                <FormHelperText>{formError.state.message}</FormHelperText>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={4} md={3}>
                                            <TextField fullWidth={true} disabled inputProps={{ type: 'number' }} value={applicantInfo.contactAddress.zip} name="zip" onChange={handleApplicantInfo} id="standard-basic" label="Zip Code" />
                                        </Grid>
                                    </Grid>
                                </form>
                            </Paper>


                            <h4 className="mt-5" ref={idInfoRef}>ID Information</h4>
                            <Paper className="pt-3 pb-3 pl-3 pr-3 mb-3">
                                <form>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={4} md={3}>
                                            <FormControl className={classes.formControl} fullWidth={true}>
                                                <InputLabel id="demo-simple-select-label">Primary ID type</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={idInformation.idType}
                                                    onChange={handleIdInformation}
                                                    name="idType"
                                                    error={formError.idType.error}

                                                >
                                                    {formOptions &&

                                                        formOptions.config_id_types.map((type) => (
                                                            <MenuItem key={type.id_type_name} value={type.id_type_id}>{type.id_type_name}</MenuItem>
                                                        )
                                                        )
                                                    }
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={4} md={3}>
                                            <FormControl className={classes.formControl} fullWidth={true}>
                                                <InputLabel id="demo-simple-select-label">Issuing State</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={idInformation.stateId}
                                                    error={formError.stateId.error}
                                                    onChange={handleIdInformation}
                                                    name="stateId"
                                                    fullWidth={true}
                                                >
                                                    <MenuItem value="TX">Texas</MenuItem>
                                                    <MenuItem value="NM">New Mexico</MenuItem>
                                                    <Divider light />
                                                    {usaStates.map((i) => (
                                                        <MenuItem key={i.abbreviation} value={i.abbreviation}>{i.name}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={4} md={3}>
                                            <ReactInputMask
                                                mask="99/99/9999"
                                                value={idInformation.expDate}
                                                disabled={false}
                                                maskChar="-"
                                                onChange={handleIdInformation}
                                            >
                                                {() => <TextField fullWidth={true} error={formError.expDate.error} helperText={formError.expDate.message} inputProps={{ type: 'tel' }} value={idInformation.expDate} name="expDate" onChange={handleIdInformation} id="standard-basic" label="Expiration Date" />}

                                            </ReactInputMask>
                                        </Grid>
                                    </Grid>



                                </form>
                            </Paper>

                            <h4 className="mt-5" ref={installationRef}>Installation</h4>
                            <BlueTitleBox text="What kind of system are we going to install?" />
                            {formError.productType.error && <hr style={{ border: 'solid 1px red' }} />}
                            <RadioGroup row aria-label="position" name="position" defaultValue="top">
                                <FormControlLabel
                                    //value={true}
                                    checked={installationDetails.productId === 1 && true}
                                    control={<Radio color="primary" />}
                                    label="Wholehouse + RO"
                                    labelPlacement="start"
                                    onClick={() => setInstallationDetails({ ...installationDetails, productId: 1 })}
                                />
                                <FormControlLabel
                                    //value="no"
                                    checked={installationDetails.productId === 2 && true}
                                    control={<Radio color="primary" />}
                                    label="RO only"
                                    labelPlacement="start"
                                    onClick={() => setInstallationDetails({ ...installationDetails, productId: 2 })}
                                />
                            </RadioGroup>
                            {formError.productType.error && <hr style={{ border: 'solid 1px red' }} />}
                            <BlueTitleBox text="Will the installation need a shed? (if you are unsure, click Yes)" />
                            {formError.shedNeeded.error && <hr style={{ border: 'solid 1px red' }} />}
                            <RadioGroup row aria-label="position" name="position" defaultValue="top">
                                <FormControlLabel
                                    //value="yes"
                                    checked={installationDetails.needsShed === true && true}
                                    control={<Radio color="primary" />}
                                    label="Yes"
                                    labelPlacement="start"
                                    onClick={() => setInstallationDetails({ ...installationDetails, needsShed: true })}
                                />
                                <FormControlLabel
                                    //value="no"
                                    checked={installationDetails.needsShed === false && true}
                                    control={<Radio color="primary" />}
                                    label="No"
                                    labelPlacement="start"
                                    onClick={() => setInstallationDetails({ ...installationDetails, needsShed: false })}
                                />
                            </RadioGroup>
                            {formError.shedNeeded.error && <hr style={{ border: 'solid 1px red' }} />}
                            <BlueTitleBox text="Are ID address and Installation the same?" />
                            <RadioGroup row aria-label="position" name="position" defaultValue="top">
                                <FormControlLabel
                                    value="yes"
                                    checked={sameInstallationAddress === true && true}
                                    control={<Radio color="primary" />}
                                    label="Yes"
                                    labelPlacement="start"
                                    onClick={() => setSameInstallationAddress(true)}
                                />
                                <FormControlLabel
                                    value="no"
                                    checked={sameInstallationAddress === false && true}
                                    control={<Radio color="primary" />}
                                    label="No"
                                    labelPlacement="start"
                                    onClick={() => setSameInstallationAddress(false)}
                                />
                            </RadioGroup>
                            <Paper ref={installationAddressRef} className="pt-3 pb-3 pl-3 pr-3 mb-3">
                                <form>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={4} md={3}><TextField fullWidth={true} disabled={sameInstallationAddress === true && true} error={formError.installationAddress.error} helperText={formError.installationAddress.message} inputRef={installAddressForm} onClick={enableGmapsAutocomplete} name="installationAddress" onChange={handleInstallationAddress} value={installationAddress.address} id="standard-basic" label="Installation Address" /></Grid>
                                        <Grid item xs={12} sm={4} md={3}><TextField fullWidth={true} disabled={sameInstallationAddress === true && true} error={formError.installationCity.error} helperText={formError.installationCity.message} name="installationCity" value={installationAddress.city} onChange={handleInstallationAddress} id="standard-basic" label="City" /></Grid>
                                        <Grid item xs={12} sm={4} md={3}><FormControl error={formError.installationState.error} fullWidth={true} >
                                            <InputLabel id="demo-simple-select-label">State</InputLabel>
                                            <Select
                                                disabled={sameInstallationAddress === true && true}
                                                labelId="demo-simple-select-label"
                                                value={installationAddress.state}
                                                onChange={handleInstallationAddress}
                                                name="installationState"
                                                id="demo-simple-select"
                                            >
                                                <MenuItem data-attr="AL" value="Alabama">Alabama</MenuItem>
                                                <MenuItem data-attr="AK" value="Alaska">Alaska</MenuItem>
                                                <MenuItem data-attr="AZ" value="Arizona">Arizona</MenuItem>
                                                <MenuItem data-attr="AR" value="Arkansas">Arkansas</MenuItem>
                                                <MenuItem data-attr="CA" value="California">California</MenuItem>
                                                <MenuItem data-attr="CO" value="Colorado">Colorado</MenuItem>
                                                <MenuItem data-attr="CT" value="Connecticut">Connecticut</MenuItem>
                                                <MenuItem data-attr="DE" value="Delaware">Delaware</MenuItem>
                                                <MenuItem data-attr="DC" value="District Of Columbia">District Of Columbia</MenuItem>
                                                <MenuItem data-attr="FL" value="Florida">Florida</MenuItem>
                                                <MenuItem data-attr="GA" value="Georgia">Georgia</MenuItem>
                                                <MenuItem data-attr="HI" value="Hawaii">Hawaii</MenuItem>
                                                <MenuItem data-attr="ID" value="Idaho">Idaho</MenuItem>
                                                <MenuItem data-attr="IL" value="Illinois">Illinois</MenuItem>
                                                <MenuItem data-attr="IN" value="Indiana">Indiana</MenuItem>
                                                <MenuItem data-attr="IA" value="Iowa">Iowa</MenuItem>
                                                <MenuItem data-attr="KS" value="Kansas">Kansas</MenuItem>
                                                <MenuItem data-attr="KY" value="Kentucky">Kentucky</MenuItem>
                                                <MenuItem data-attr="LA" value="Louisiana">Louisiana</MenuItem>
                                                <MenuItem data-attr="ME" value="Maine">Maine</MenuItem>
                                                <MenuItem data-attr="MD" value="Maryland">Maryland</MenuItem>
                                                <MenuItem data-attr="MA" value="Massachusetts">Massachusetts</MenuItem>
                                                <MenuItem data-attr="MI" value="Michigan">Michigan</MenuItem>
                                                <MenuItem data-attr="MN" value="Minnesota">Minnesota</MenuItem>
                                                <MenuItem data-attr="MS" value="Mississippi">Mississippi</MenuItem>
                                                <MenuItem data-attr="MO" value="Missouri">Missouri</MenuItem>
                                                <MenuItem data-attr="MT" value="Montana">Montana</MenuItem>
                                                <MenuItem data-attr="NE" value="Nebraska">Nebraska</MenuItem>
                                                <MenuItem data-attr="NV" value="Nevada">Nevada</MenuItem>
                                                <MenuItem data-attr="NH" value="New Hampshire">New Hampshire</MenuItem>
                                                <MenuItem data-attr="NJ" value="New Jersey">New Jersey</MenuItem>
                                                <MenuItem data-attr="NM" value="New Mexico">New Mexico</MenuItem>
                                                <MenuItem data-attr="NY" value="New York">New York</MenuItem>
                                                <MenuItem data-attr="NC" value="North Carolina">North Carolina</MenuItem>
                                                <MenuItem data-attr="ND" value="North Dakota">North Dakota</MenuItem>
                                                <MenuItem data-attr="OH" value="Ohio">Ohio</MenuItem>
                                                <MenuItem data-attr="OK" value="Oklahoma">Oklahoma</MenuItem>
                                                <MenuItem data-attr="OR" value="Oregon">Oregon</MenuItem>
                                                <MenuItem data-attr="PA" value="Pennsylvania">Pennsylvania</MenuItem>
                                                <MenuItem data-attr="RI" value="Rhode Island">Rhode Island</MenuItem>
                                                <MenuItem data-attr="SC" value="South Carolina">South Carolina</MenuItem>
                                                <MenuItem data-attr="SD" value="South Dakota">South Dakota</MenuItem>
                                                <MenuItem data-attr="TN" value="Tennessee">Tennessee</MenuItem>
                                                <MenuItem data-attr="TX" value="Texas">Texas</MenuItem>
                                                <MenuItem data-attr="UT" value="Utah">Utah</MenuItem>
                                                <MenuItem data-attr="VT" value="Vermont">Vermont</MenuItem>
                                                <MenuItem data-attr="VA" value="Virginia">Virginia</MenuItem>
                                                <MenuItem data-attr="WA" value="Washington">Washington</MenuItem>
                                                <MenuItem data-attr="WV" value="West Virginia">West Virginia</MenuItem>
                                                <MenuItem data-attr="WI" value="Wisconsin">Wisconsin</MenuItem>
                                                <MenuItem data-attr="WY" value="Wyoming">Wyoming</MenuItem>
                                            </Select>
                                            <FormHelperText>{formError.installationState.error}</FormHelperText>
                                        </FormControl></Grid>
                                        <Grid item xs={12} sm={4} md={3}>
                                            <TextField fullWidth={true} disabled={sameInstallationAddress === true && true} error={formError.installationZip.error} helperText={formError.installationZip.message} inputProps={{ type: 'number' }} value={installationAddress.zip} name="installationZip" onChange={handleInstallationAddress} id="standard-basic" label="Zip Code" />
                                        </Grid>
                                    </Grid>
                                </form>
                            </Paper>

                            <h4 className="mt-5" >Contact Info</h4>
                            <Paper ref={contactInformationRef} className="pt-3 pb-3 pl-3 pr-3 mb-3">
                                <form>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={4} md={3}>
                                            <ReactInputMask
                                                mask="(999)999-9999"
                                                maskChar="-"
                                                onChange={handleContactInfo}
                                            >
                                                {() => <TextField fullWidth={true} error={formError.phone.error} helperText={formError.phone.message} inputProps={{ type: 'tel' }} name="phone" id="standard-basic" label="Phone Number" />}
                                            </ReactInputMask>
                                        </Grid>
                                        <Grid item xs={12} sm={4} md={3}>
                                            <FormControl className={classes.formControl} fullWidth={true}>
                                                <InputLabel id="demo-simple-select-label">Phone Type</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={contactInfo.phoneType}
                                                    onChange={handleContactInfo}
                                                    name="phoneType"
                                                    error={formError.phoneType.error}
                                                >
                                                    {formOptions &&
                                                        formOptions.config_phone_types.map((type) => (
                                                            <MenuItem key={type.phone_type_name} value={type.phone_type_id}>{type.phone_type_name}</MenuItem>
                                                        ))
                                                    }
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    {/* <TextField style={{ display: 'none' }} error={formError.email.error} helperText={formError.email.message} inputProps={{ type: 'email' }} value={contactInfo.email} name="email" onChange={handleContactInfo} id="standard-basic" label="Email" /> */}
                                </form>
                            </Paper>

                            <h4 className="mt-5" ref={residenceRef}>Residence</h4>
                            <Paper className="pt-3 pb-3 pl-3 pr-3 mb-3">
                                <form>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={4} md={3}>
                                            <FormControl className={classes.formControl} fullWidth={true}>
                                                <InputLabel id="demo-simple-select-label">Residence Type</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={residenceInfo.type}
                                                    onChange={handleResidenceInfo}
                                                    name="residenceType"
                                                    error={formError.residenceType.error}
                                                >
                                                    {formOptions &&
                                                        formOptions.config_residence_types.map((type) => (
                                                            <MenuItem key={type.residence_type_name} value={type.residence_type_id}>{type.residence_type_name}</MenuItem>
                                                        ))
                                                    }
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={4} md={3}>
                                            <FormControl className={classes.formControl} fullWidth={true}>
                                                <InputLabel htmlFor="standard-adornment-amount">Mortgage</InputLabel>
                                                <Input
                                                    id="standard-adornment-amount"
                                                    value={residenceInfo.mortgage}
                                                    onChange={handleResidenceInfo}
                                                    type="number"
                                                    onWheel={(e) => e.target.blur()}
                                                    name="residenceMortgage"
                                                    error={formError.residenceMortgage.error}
                                                    inputProps={{ type: 'number' }}
                                                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={4} md={3}><TextField onWheel={(e) => e.target.blur()} fullWidth={true} error={formError.residenceYearsOfResidence.error} helperText={formError.residenceYearsOfResidence.message} inputProps={{ type: 'number' }} value={residenceInfo.yearsOfResidence} name="residenceYearsOfResidence" onChange={handleResidenceInfo} id="standard-basic" label="Years in Residence" /></Grid>
                                        <Grid item xs={12} sm={4} md={3}><TextField onWheel={(e) => e.target.blur()} fullWidth={true} error={formError.residenceMonthsOfResidence.error} helperText={formError.residenceMonthsOfResidence.message} inputProps={{ type: 'number' }} value={residenceInfo.monthsOfResidence} name="residenceMonthsOfResidence" onChange={handleResidenceInfo} id="standard-basic" label="Months in Residence" /></Grid>
                                    </Grid>
                                </form>
                            </Paper>

                            <h4 className="mt-5" ref={employmentRef}>Employment</h4>
                            <Paper className="pt-3 pb-3 pl-3 pr-3 mb-3">
                                <form >
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={4} md={3}>
                                            <FormControl className={classes.formControl} fullWidth={true}>
                                                <InputLabel id="demo-simple-select-label">Employment Status</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={employmentInfo.type}
                                                    onChange={handleEmploymentInfo}
                                                    name="employmentType"
                                                    error={formError.employmentType.error}
                                                >
                                                    {formOptions &&
                                                        formOptions.config_employment_types.map((type) => (
                                                            <MenuItem key={type.employment_type_name} value={type.employment_type_id}>{type.employment_type_name}</MenuItem>
                                                        ))
                                                    }
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={4} md={3}><TextField fullWidth={true} error={formError.employmentEmployer.error} helperText={formError.employmentEmployer.message} inputProps={{ type: 'text' }} value={employmentInfo.employer} name="employmentEmployer" onChange={handleEmploymentInfo} id="standard-basic" label="Employer Name" /></Grid>
                                        <Grid item xs={12} sm={4} md={3}><TextField fullWidth={true} error={formError.employmentPosition.error} helperText={formError.employmentPosition.message} inputProps={{ type: 'text' }} value={employmentInfo.position} name="employmentPosition" onChange={handleEmploymentInfo} id="standard-basic" label="Position" /></Grid>
                                        <Grid item xs={12} sm={4} md={3}><TextField onWheel={(e) => e.target.blur()} fullWidth={true} error={formError.employmentYears.error} helperText={formError.employmentYears.message} inputProps={{ type: 'number' }} value={employmentInfo.years} name="employmentYears" onChange={handleEmploymentInfo} id="standard-basic" label="Years in Employment" /></Grid>
                                        <Grid item xs={12} sm={4} md={3}><TextField onWheel={(e) => e.target.blur()} fullWidth={true} error={formError.employmentMonths.error} helperText={formError.employmentMonths.message} inputProps={{ type: 'number' }} value={employmentInfo.months} name="employmentMonths" onChange={handleEmploymentInfo} id="standard-basic" label="Months in Employment" /></Grid>
                                        <Grid item xs={12} sm={4} md={3}>
                                            <FormControl className={classes.formControl} fullWidth={true}>
                                                <InputLabel htmlFor="standard-adornment-amount">Gross Monthly Income</InputLabel>
                                                <Input
                                                    id="standard-adornment-amount"
                                                    value={employmentInfo.income}
                                                    onChange={handleEmploymentInfo}
                                                    type="number"
                                                    onWheel={(e) => e.target.blur()}
                                                    error={formError.employmentIncome.error}
                                                    name="employmentIncome"
                                                    inputProps={{ type: 'number' }}
                                                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </form>
                            </Paper>

                            <h4 className="mt-5" ref={contactInformationRef}>Finance</h4>
                            <Paper className="pt-3 pb-3 pl-3 pr-3 mb-3">
                                <form>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={3}><FormControl fullWidth={true} className={classes.formControl}>
                                            <InputLabel htmlFor="standard-adornment-amount">Amount Financed</InputLabel>
                                            <Input
                                                id="standard-adornment-amount"
                                                value={financedAmount === null ? '' : financedAmount}
                                                onChange={handleFinanceAmount}
                                                type="number"
                                                onWheel={(e) => e.target.blur()}
                                                name="financedAmount"
                                                error={formError.financedAmount.error}
                                                inputProps={{ type: 'number' }}
                                                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                            />
                                        </FormControl></Grid>
                                    </Grid>

                                </form>
                            </Paper>

                            <Button onClick={() => validateForm()} className="mt-3 mb-3" variant="contained" color="primary">Continue</Button>
                            {fillCoapplicantConfirmation &&
                                <DialogNoButtons title="Co-applicant Found">
                                    <p>Would you like to fill up the Co-applicant's information now?</p>
                                    <Button onClick={() => history.push(`/application/${props.match.params.app_id}/fill_coap`)} color="primary">
                                        Yes
                                    </Button>
                                    <Button onClick={() => setConfirmation({ show: true })} color="primary">
                                        No
                                    </Button>
                                </DialogNoButtons>
                            }
                            <Dialog
                                open={confirmation.show}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        <h2 style={{ color: 'green' }}>Application Sent</h2>
                                        The Application was sent to the Applications department. They will now submit it to our finance agencies.<br /><br />
                                        Please contact Apps department to let them know you have completed this step.<br /><br />
                                        For future reference the App assigned will be <b>{applicantInfo.app_id}</b>.<br /><br />
                                        {/* You will be redirected to the paperwork request step, or you can request it later if you don't need it right now.<br /><br />
                                        Please click Continue */}
                                    </DialogContentText>
                                    <DialogActions>
                                        <Button onClick={() => history.push('/app_details/' + applicantInfo.app_id)} color="primary">
                                            Continue
                                        </Button>
                                    </DialogActions>
                                </DialogContent>
                            </Dialog>

                            <Dialog
                                open={openWrongAddressAlert}
                                openWrongAddressAlert={openWrongAddressAlert}
                                onClose={() => setOpenWrongAddressAlert(false)}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogTitle id="alert-dialog-title">{"This address is uknown"}</DialogTitle>
                                <DialogContent>
                                    <DialogContentText>{installationAddress.address + ', ' + installationAddress.city + ', ' + installationAddress.state + ', ' + installationAddress.zip}</DialogContentText>
                                </DialogContent>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        This address appears to be invalid, please check the following fields:
                                        {
                                            <ul className="mt-3 mb-3">
                                                <li className="danger">{addressValidation.number}</li>
                                                <li className="danger">{addressValidation.street}</li>
                                                <li className="danger">{addressValidation.city}</li>
                                                <li className="danger">{addressValidation.state}</li>
                                                <li className="danger">{addressValidation.zip}</li>
                                            </ul>
                                        }
                                        Or click continue if you are shure that the address is correct
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={() => setOpenWrongAddressAlert(false)} color="primary">
                                        Close
                                    </Button>
                                    <Button onClick={() => sendForm()} color="primary" autoFocus>
                                        Continue
                                    </Button>
                                </DialogActions>
                            </Dialog>
                            {
                                alert.show &&
                                <CustomizedSnackbars severity={alert.severity} message={alert.message} hideAlert={hideAlert} />
                            }
                        </>
                    }

                </div >
            }
            <BottomMenu showOnDesktop={true} />
        </>
    )
}

export default AppFill;