import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import RestoreIcon from '@material-ui/icons/Restore';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { AddCircle } from '@material-ui/icons';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useMediaQuery } from 'react-responsive';
import Cookies from 'universal-cookie';

const useStyles = makeStyles({
    root: {
        width: '100%',
        position: 'fixed',
        bottom: '0',
        zIndex: '1'
    },
});
const addAppButton = {
    position: 'absolute',
    fontSize: '50px !important',
    top: '-15px'
}

const BottomMenu = ({ showOnMobile = false, showOnDesktop = false }) => {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const [show, setShow] = useState(false);
    const history = useHistory();
    const isMobile = useMediaQuery({ maxWidth: 768 });
    const isDesktop = useMediaQuery({ minWidth: 769 });
    const cookies = new Cookies();
    const userLevelId = cookies.get('level')

    useEffect(() => {
        !showOnMobile && !showOnDesktop && setShow(true);
        showOnMobile && setShow(isMobile);
        showOnDesktop && setShow(isDesktop);
    }, [isMobile, isDesktop]);

    useEffect(() => {
        switch (history.location.pathname) {
            case '/':
                if (userLevelId === '2') {
                    setValue(1);
                } else {
                    setValue(0);
                }
                break;
            case '/applications':
                setValue(0);
                break;
            case '/installations':
                setValue(1);
                break;
            case '/inquiry/new':
                setValue(2);
                break;
            case '/my_info':
                setValue(3);
                break;
            default:
                break;
        }
    }, [history])

    return (
        <>
            {show &&
                <>
                    <div style={{ height: 56 }}></div>
                    <BottomNavigation
                        value={value}
                        onChange={(event, newValue) => {
                            setValue(newValue);
                            newValue === 0 && history.push('/applications');
                            newValue === 1 && history.push('/installations');
                            newValue === 2 && history.push('/inquiry/new');
                            newValue === 3 && history.push('/my_info');
                        }}
                        showLabels
                        className={classes.root}
                    >
                        {(userLevelId === '1' || userLevelId > 10) &&
                            <BottomNavigationAction label="Apps" icon={<RestoreIcon />} />
                        }

                        <BottomNavigationAction label="Installations" icon={<RestoreIcon />} />
                        {userLevelId === '12' &&
                            < BottomNavigationAction label="New App" icon={<AddCircle className={addAppButton} />} />
                        }

                        <BottomNavigationAction label="My info" icon={<LocationOnIcon />} />
                    </BottomNavigation>
                </>
            }

        </>
    );
}

export default BottomMenu;
