//Field Validations
export function validateField(e) {
    //firstname, lastname and city
    if (e.target.name === 'salesperson') {
        if (e.target.value === undefined) {
            return { ok: false, message: "This field cannot be empty" };
        }
        else if (e.target.value.length < 1) {
            return { ok: false, message: "This field cannot be empty and must have more than 1 characters" };
        }
        else {
            return { ok: true, message: "" };
        }
    }
    //firstname, lastname and city
    if (e.target.name === 'firstname' || e.target.name === 'lastname' || e.target.name === 'city' || e.target.name === 'installationCity' || e.target.name === 'employmentEmployer' || e.target.name === 'employmentPosition') {
        if (e.target.value === undefined) {
            return { ok: false, message: "This field cannot be empty and must have more than 1 characters" };
        }
        if (! /^[a-zA-Z\s]*$/.test(e.target.value)) {
            return { ok: false, message: "This field must contain only letters" };
        }
        else if (e.target.value.length < 2) {
            return { ok: false, message: "This field cannot be empty and must have more than 1 characters" };
        } else {
            return { ok: true, message: "" };
        }
    }
    //Social Security Number
    if (e.target.name === 'ssn') {
        let value = e.target.value;
        let clean;
        clean = value.replace(/_/g, "");
        // if (clean.startsWith('0')) {
        //     return { ok: false, message: "Social Security Number can't start with  0" };
        // }
        if (clean.length !== 11) {
            return { ok: false, message: "This field must contain 9 digits" };
        } else {
            return { ok: true, message: "" };
        }
    }
    //Date of birth
    if (e.target.name === 'dob' || e.target.name === 'expDate') {
        const regex = "^(0[1-9]|1[012])[-/.](0[1-9]|[12][0-9]|3[01])[-/.](19|20)\\d\\d$";
        if (e.target.value === undefined) {
            return { ok: false, message: "mm/dd/yyyy Date can't be empty" };
        }
        if (!e.target.value.match(regex)) {
            return { ok: false, message: "mm/dd/yyyy Invalid or incomplete date" };
        }
        else {
            return { ok: true, message: "mm/dd/yyyy" };
        }
    }
    //Address
    if (e.target.name === 'address' || e.target.name === 'installationAddress') {
        if (e.target.value === undefined || e.target.value.length < 9) {
            return { ok: false, message: "This field cannot be empty and must have more than 8 characters" };
        } else {
            return { ok: true, message: "" };
        }
    }
    //State
    if (e.target.name === 'state' || e.target.name === 'installationState') {
        if (e.target.value === undefined || e.target.value.length < 4) {
            return { ok: false, message: "This field cannot be empty and must have more than 4 characters" };
        } else {
            return { ok: true, message: "" };
        }
    }
    //ZIP code
    if (e.target.name === 'zip' || e.target.name === 'installationZip') {

        if (e.target.value.length !== 5) {
            return { ok: false, message: "This field must contain 5 digits" };
        } else {
            return { ok: true, message: "" };
        }
    }
    //ID type, Issuing state and Phone type
    if (e.target.name === 'idType' || e.target.name === 'stateId' || e.target.name === 'residenceType' || e.target.name === 'employmentType' || e.target.name === 'relationship') {
        if (e.target.value === undefined || e.target.value === "") {
            return { ok: false, message: "You need to select an option" };
        }
        else {
            return { ok: true, message: "" };
        }
    }
    //Product type
    if (e.target.name === 'productType') {
        if (e.target.value === undefined || e.target.value === "") {
            return { ok: false, message: "You need to choose the system to install" };
        }
        else {
            return { ok: true, message: "" };
        }
    }
    //Product type
    if (e.target.name === 'shedNeeded') {
        if (e.target.value === undefined || e.target.value === "") {
            return { ok: false, message: "You need to choose if the installation will need a shed or not" };
        }
        else {
            return { ok: true, message: "" };
        }
    }
    //Phone
    if (e.target.name === 'phone') {
        if (e.target.value === undefined) {
            return { ok: false, message: "Phone can't be empty" };
        }
        let length = e.target.value.split("-").length - 1;
        if (length !== 1) {
            return { ok: false, message: "The phone number is invalid" };
        }
        else {
            return { ok: true, message: "" };
        }
    }
    //Phone Type
    if (e.target.name === 'phoneType') {
        if (e.target.value === undefined || e.target.value === "") {
            return { ok: false, message: "You need to choose a phone type" };
        }
        else {
            return { ok: true, message: "" };
        }
    }
    //Email
    if (e.target.name === 'email') {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(e.target.value)) {
            return { ok: true, message: "" };
        } else {
            return { ok: false, message: "Invalid Email" };
        }
    }
    //Mortgage
    if (e.target.name === 'residenceMortgage') {
        if (e.target.value === "" || e.target.value === undefined) {
            return { ok: false, message: "This field can not be empty" };
        }
        else {
            return { ok: true, message: "" };
        }
    }
    //Years 
    if (e.target.name === 'residenceYearsOfResidence' || e.target.name === 'employmentYears') {
        const regex = '^[0-9]*$';
        if (e.target.value === "" || e.target.value === undefined) {
            return { ok: false, message: "This field can not be empty" };
        }
        if (e.target.value >= 99) {
            return { ok: false, message: "This value is out of range" };
        }
        else if (!e.target.value.match(regex)) {
            return { ok: false, message: "Only Numbers are allowed in this field" };
        }
        else {
            return { ok: true, message: "" };
        }
    }
    //Months
    if (e.target.name === 'residenceMonthsOfResidence' || e.target.name === 'employmentMonths') {
        const regex = '^[0-9]*$';
        if (e.target.value === "" || e.target.value === undefined) {
            return { ok: false, message: "This field can not be empty" };
        }
        else if (!e.target.value.match(regex)) {
            return { ok: false, message: "Only Numbers are allowed in this field" };
        }
        else if (e.target.value > 11) {
            return { ok: false, message: "This value is out of range, months can't be higher than 11" };
        }
        else {
            return { ok: true, message: "" };
        }
    }
    //Income
    if (e.target.name === 'employmentIncome') {
        const regex = '^[0-9]*$';
        if (e.target.value === "" || e.target.value === undefined) {
            return { ok: false, message: "This field can not be empty" };
        }
        else if (!e.target.value.match(regex)) {
            return { ok: false, message: "Only Numbers are allowed in this field" };
        }
        else {
            return { ok: true, message: "" };
        }
    }
    //Financed
    if (e.target.name === 'financedAmount') {
        if (e.target.value === "" || e.target.value === undefined) {
            return { ok: false, message: "This field can not be empty" };
        }
        if (e.target.value < 1000 || e.target.value > 20000) {
            return { ok: false, message: "This value is out of price range" };
        }
        else {
            return { ok: true, message: "" };
        }
    }
}