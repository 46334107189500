import React from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import BottomMenu from '../footer/bottomMenu';
import HeaderTitle from '../assets/headerTitle';
import AvatarLetter from '../assets/avatarLetter';
import Cookies from 'universal-cookie';
import { Button, Divider } from '@material-ui/core';
const mainContainerStyle = {
    margin: '16px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
}
const secondContainerStyle = {
    margin: '16px',
}
export default function MyInfo() {
    const cookies = new Cookies();
    const name = cookies.get('name');
    const email = cookies.get('email');
    const level = cookies.get('level');
    const history = useHistory();

    const logOut = () => {
        cookies.remove('name');
        cookies.remove('email');
        cookies.remove('level');
        cookies.remove('token');
        history.push('/');
    }

    const getLevel = (levelCode) => {
        switch (levelCode) {
            case '12':
                return 'Developer'
                break;
            case '11':
                return 'Admin'
                break;
            case '2':
                return 'Installer'
                break;
            case '1':
                return 'Seller'
                break;
            default:
                break;
        }
    }
    return (
        <>
            <div style={mainContainerStyle}>
                <AvatarLetter name={name} />
                <p>{name}</p>
            </div>
            <div style={secondContainerStyle}>
                <p>Username: {email}</p>
                <p>User Level: {getLevel(level)}</p>
            </div>
            <Button onClick={logOut} style={{ position: 'absolute', margin: '16px', top: '0' }} color='primary'>Log out</Button>
            <BottomMenu />
        </>

    )
}
