import React, { useState, useContext } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const useStyles = makeStyles({
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
});

export default function ApplicationsFilters({ showApplicationsFilters, setShowApplicationsFilters, agencyApproved, handleAgencyApproved }) {
    const classes = useStyles();

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setShowApplicationsFilters(open);
    };

    return (
        <div>
            <React.Fragment key={showApplicationsFilters}>
                <SwipeableDrawer anchor='right' open={showApplicationsFilters} onClose={toggleDrawer(false)}>
                    <List>
                        <ListItem>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={agencyApproved}
                                        onChange={handleAgencyApproved}
                                        name="checkedB"
                                        color="primary"
                                    />
                                }
                                label="Show Agency Approved"
                            />
                        </ListItem>
                        <Divider />
                    </List>

                </SwipeableDrawer>
            </React.Fragment>
        </div>
    );
}
