import React from "react";
import { Grid, Avatar } from "@material-ui/core";

const GridItem = ({ avatar, children }) => {
    return (
        <Grid className="mb-3" container>
            <Grid item xs={2}>
                <Avatar>
                    {avatar}
                </Avatar>
            </Grid>
            <Grid item xs={10}>
                {children}
            </Grid>
        </Grid>
    )
}
export default GridItem;